const startTimerCartOut = () => {
    return setInterval(function() {
        let timestamp = new Date();
        let time = timestamp.toISOString().replace('T', ' ').replace('Z', '');
        console.log(">>>> Tiempo actual: " + time);
    }, 3000);
};

const stopTimerCartOut = (timer) => {
    clearInterval(timer);
};

const setTimerCartOut = (tmr) => {
    localStorage.setItem("timer", tmr);
};

const getTimerCartOut = () => {
    let t = Number(localStorage.getItem("timer"));
    localStorage.removeItem("timer");
    return t;
};

const startCountCartOut = (secs) => {
    let ms = secs * 1000
    return setTimeout(function() {
        alert("Tiempo fuera, aqui se deberia borrar el carro de compras");
    }, ms);
};

const stopCountCartOut = (timer) => {
    clearTimeout(timer);
};

export { startTimerCartOut, stopTimerCartOut, setTimerCartOut, getTimerCartOut, startCountCartOut, stopCountCartOut }

