//{{URL_entorno_beneficiarios}}/api/eatcloud/eatc_sale_order_headers?eatc-user_code={{eatc_sale_users.eatc-code}}
//{URL_entorno_donantes}}/get/eatcloud/getpuntos?table=eatc_sale_order_headers&fieldname=eatc-pod_lat,eatc-pod_lon&fieldvalue={{actual_lat}},{{actual_lon}}&showfield=eatc-code&km={{actual_sale_radius_km}}&filterfield_1=eatc-code&filtervalue_1={{(eatc_sale_order_headers.eatc-code}}
//{{URL_entorno_beneficiarios}}/crd/eatcloud/?_tabla=eatc_sale_order_headers&_operación=update&eatc-picking_checkin_datetime={{timestamp}}&WHEREeatc-code={{eatc_sale_order_header.eatc-code}}
// Encabezado: {{URL_entorno_beneficiarios}}/api/eatcloud/eatc_sale_order_headers?eatc-code={{eatc-code}}
// Detalle: {{URL_entorno_beneficiarios}}/api/eatcloud/eatc_sale_order?eatc-sale_order_header_code={{eatc-code}}

import axios from "axios";
import globales from "../config/config";

const BASE_URL_DONA = globales._dona;
const BASE_URL_BENF = globales._benf;

const API_BENF = axios.create({
  baseURL: BASE_URL_BENF,
})

const API_DONA = axios.create({
  baseURL: BASE_URL_DONA,
});

const ORDERS_ENDPOINTS = {
  GET_PUNTOS: "getpuntos",
  TABLE: "table",
  TABLA: "_tabla",
  OPERACION: "_operación",
  FIELDNAME: "fieldname",
  FIELDVALUE: "fieldvalue",
  SHOWFIELD: "showfield",
  KM: "km",
  FILTERFIELD_1: "filterfield_1",
  FILTERVALUE_1: "filtervalue_1",
  EATC_SALE_ORDER: "eatc_sale_order",
  EATC_SALE_ORDER_HEADERS: "eatc_sale_order_headers",
  EATC_CODE: "eatc-code",
  EATC_USER_CODE: "eatc-user_code",
  EATC_SALE_ORDER_HEADER_CODE: "eatc-sale_order_header_code",
  EATC_PICKING_CHEKIN_DATETIME: "eatc-picking_checkin_datetime",
  WHERE: "WHERE",
  QUESTION: "?",
  CONCAT: "&",
  EQUAL: "=",
  COMA: ",",
};

const YourOrdersService = {
  getOrders: (code) =>
    new Promise((resolve, reject) => {
      console.log("%c Entrando a YourOrdersService.getOrders()", globales.consoleStyle);
      console.log("YourOrdersService.getOrders(code :", code);
      console.log("%c YourOrdersService.getOrders() URL : " + BASE_URL_BENF + globales._api + 
      globales._user + ORDERS_ENDPOINTS.EATC_SALE_ORDER_HEADERS + ORDERS_ENDPOINTS.QUESTION + 
      ORDERS_ENDPOINTS.EATC_USER_CODE + ORDERS_ENDPOINTS.EQUAL + code, globales.consoleStyle);

      API_BENF.get(BASE_URL_BENF + globales._api + globales._user + ORDERS_ENDPOINTS.EATC_SALE_ORDER_HEADERS + 
        ORDERS_ENDPOINTS.QUESTION + ORDERS_ENDPOINTS.EATC_USER_CODE + ORDERS_ENDPOINTS.EQUAL + code)
        .then((res) => {
          console.log("res : ", res);
          console.log("res.data : ", res.data);
          console.log("res.status : ", res.status);
          console.log("res.statusText : ", res.statusText);
          return res.data.res;
        })
        .then((data) => {
          console.log(
            "%c YourOrdersService.getOrders() SUCCESS", globales.consoleStyleSuccess);
          resolve(data);
          console.log("data : ", data);
          console.log("data[0] : ", data[0]);
        })
        .catch((err) => {
          console.log(
            "%c YourOrdersService.getOrders() ERROR", globales.consoleStyleError);
          reject(err);
        });
    }),
  getInProgress: (table, fieldname, actual_lat, actual_lon, showfield, actual_sale_radius_km, 
    filterfield_1, filtervalue_1) =>
    new Promise((resolve, reject) => {
      console.log("%c Entrando a YourOrdersService.getInProgress()", globales.consoleStyle);
      console.log("YourOrdersService.getInProgress(fieldname :", fieldname, "actual_lat",
        actual_lat, "actual_lon", actual_lon, "showfield", showfield, "actual_sale_radius_km",
        actual_sale_radius_km, "filterfield_1", filterfield_1, "filtervalue_1", filtervalue_1);
      console.log("%c YourOrdersService.getInProgress() URL : " + BASE_URL_DONA + globales._get +
        globales._user + ORDERS_ENDPOINTS.GET_PUNTOS + ORDERS_ENDPOINTS.QUESTION + ORDERS_ENDPOINTS.TABLE +
        ORDERS_ENDPOINTS.EQUAL + table + ORDERS_ENDPOINTS.CONCAT + ORDERS_ENDPOINTS.FIELDNAME +
        ORDERS_ENDPOINTS.EQUAL + fieldname + ORDERS_ENDPOINTS.CONCAT + ORDERS_ENDPOINTS.FIELDVALUE +
        ORDERS_ENDPOINTS.EQUAL + actual_lat + ORDERS_ENDPOINTS.COMA + actual_lon + ORDERS_ENDPOINTS.CONCAT +
        ORDERS_ENDPOINTS.SHOWFIELD + ORDERS_ENDPOINTS.EQUAL + showfield + ORDERS_ENDPOINTS.CONCAT +
        ORDERS_ENDPOINTS.KM + ORDERS_ENDPOINTS.EQUAL + actual_sale_radius_km + ORDERS_ENDPOINTS.CONCAT +
        ORDERS_ENDPOINTS.FILTERFIELD_1 + ORDERS_ENDPOINTS.EQUAL + filterfield_1 + ORDERS_ENDPOINTS.CONCAT +
        ORDERS_ENDPOINTS.FILTERVALUE_1 + ORDERS_ENDPOINTS.EQUAL + filtervalue_1, globales.consoleStyle);

      API_DONA.get(BASE_URL_DONA + globales._get + globales._user + ORDERS_ENDPOINTS.GET_PUNTOS +
        ORDERS_ENDPOINTS.QUESTION + ORDERS_ENDPOINTS.TABLE + ORDERS_ENDPOINTS.EQUAL + table +
        ORDERS_ENDPOINTS.CONCAT + ORDERS_ENDPOINTS.FIELDNAME + ORDERS_ENDPOINTS.EQUAL + fieldname +
        ORDERS_ENDPOINTS.CONCAT + ORDERS_ENDPOINTS.FIELDVALUE + ORDERS_ENDPOINTS.EQUAL + actual_lat + ORDERS_ENDPOINTS.COMA + actual_lon +
        ORDERS_ENDPOINTS.CONCAT + ORDERS_ENDPOINTS.SHOWFIELD + ORDERS_ENDPOINTS.EQUAL + showfield +
        ORDERS_ENDPOINTS.CONCAT + ORDERS_ENDPOINTS.KM + ORDERS_ENDPOINTS.EQUAL + actual_sale_radius_km +
        ORDERS_ENDPOINTS.CONCAT + ORDERS_ENDPOINTS.FILTERFIELD_1 + ORDERS_ENDPOINTS.EQUAL + filterfield_1 +
        ORDERS_ENDPOINTS.CONCAT + ORDERS_ENDPOINTS.FILTERVALUE_1 + ORDERS_ENDPOINTS.EQUAL + filtervalue_1)
        .then((res) => {
          console.log("res : ", res);
          console.log("res.data : ", res.data);
          console.log("res.status : ", res.status);
          console.log("res.statusText : ", res.statusText);
          return res.data.res;
        })
        .then((data) => {
          console.log(
            "%c YourOrdersService.getInProgress() SUCCESS", globales.consoleStyleSuccess);
          resolve(data);
          console.log("data : ", data);
          console.log("data[0] : ", data[0]);
        })
        .catch((err) => {
          console.log(
            "%c YourOrdersService.getInProgress() ERROR", globales.consoleStyleError);
          reject(err);
        });
    }),
  updatePicking: (tabla, operacion, timestamp, code) =>
    new Promise((resolve, reject) => {
      console.log("%c Entrando a YourOrdersService.updatePicking()", globales.consoleStyle);
      console.log("YourOrdersService.updatePicking(tabla : ", tabla, "\noperacion : ", operacion, "\ntimestamp : ", timestamp, "\ncode : ", code);
      console.log("%c YourOrdersService.updatePicking() URL : " + BASE_URL_BENF + globales._crd +
        globales._user + ORDERS_ENDPOINTS.QUESTION + ORDERS_ENDPOINTS.TABLA + ORDERS_ENDPOINTS.EQUAL +
        tabla + ORDERS_ENDPOINTS.CONCAT + ORDERS_ENDPOINTS.OPERACION + ORDERS_ENDPOINTS.EQUAL + operacion +
        ORDERS_ENDPOINTS.CONCAT + ORDERS_ENDPOINTS.EATC_PICKING_CHEKIN_DATETIME + ORDERS_ENDPOINTS.EQUAL +
        timestamp + ORDERS_ENDPOINTS.CONCAT + ORDERS_ENDPOINTS.WHERE + ORDERS_ENDPOINTS.EATC_CODE +
        ORDERS_ENDPOINTS.EQUAL + code, globales.consoleStyle);

      API_BENF.get(BASE_URL_BENF + globales._crd + globales._user + ORDERS_ENDPOINTS.QUESTION +
        ORDERS_ENDPOINTS.TABLA + ORDERS_ENDPOINTS.EQUAL + tabla + ORDERS_ENDPOINTS.CONCAT +
        ORDERS_ENDPOINTS.OPERACION + ORDERS_ENDPOINTS.EQUAL + operacion + ORDERS_ENDPOINTS.CONCAT +
        ORDERS_ENDPOINTS.EATC_PICKING_CHEKIN_DATETIME + ORDERS_ENDPOINTS.EQUAL + timestamp +
        ORDERS_ENDPOINTS.CONCAT + ORDERS_ENDPOINTS.WHERE + ORDERS_ENDPOINTS.EATC_CODE + ORDERS_ENDPOINTS.EQUAL + code)
        .then((res) => {
          console.log("res : ", res);
          console.log("res.data : ", res.data);
          console.log("res.status : ", res.status);
          console.log("res.statusText : ", res.statusText);
          return res.data.res;
        })
        .then((data) => {
          console.log(
            "%c YourOrdersService.updatePicking() SUCCESS", globales.consoleStyleSuccess);
          resolve(data);
          console.log("data : ", data);
          console.log("data[0] : ", data[0]);
        })
        .catch((err) => {
          console.log(
            "%c YourOrdersService.updatePicking() ERROR", globales.consoleStyleError);
          reject(err);
        });
    }),
  getHeaders: (code) =>
    new Promise((resolve, reject) => {
      console.log("%c Entrando a YourOrdersService.getHeaders()", globales.consoleStyle);
      console.log("YourOrdersService.getHeaders(code :", code);
      console.log("%c YourOrdersService.getHeaders() URL : " + BASE_URL_BENF + globales._api + 
      globales._user + ORDERS_ENDPOINTS.EATC_SALE_ORDER_HEADERS + ORDERS_ENDPOINTS.QUESTION + 
      ORDERS_ENDPOINTS.EATC_CODE + ORDERS_ENDPOINTS.EQUAL + code, globales.consoleStyle);

      API_BENF.get(BASE_URL_BENF + globales._api + globales._user + ORDERS_ENDPOINTS.EATC_SALE_ORDER_HEADERS + 
        ORDERS_ENDPOINTS.QUESTION + ORDERS_ENDPOINTS.EATC_CODE + ORDERS_ENDPOINTS.EQUAL + code)
        .then((res) => {
          console.log("res : ", res);
          console.log("res.data : ", res.data);
          console.log("res.status : ", res.status);
          console.log("res.statusText : ", res.statusText);
          return res.data.res;
        })
        .then((data) => {
          console.log(
            "%c YourOrdersService.getHeaders() SUCCESS", globales.consoleStyleSuccess);
          resolve(data);
          console.log("data : ", data);
          console.log("data[0] : ", data[0]);
        })
        .catch((err) => {
          console.log(
            "%c YourOrdersService.getHeaders() ERROR", globales.consoleStyleError);
          reject(err);
        });
    }),
  getDetails: (code) =>
    new Promise((resolve, reject) => {
      console.log("%c Entrando a YourOrdersService.getDetails()", globales.consoleStyle);
      console.log("YourOrdersService.getDetails(code :", code);
      console.log("%c YourOrdersService.getDetails() URL : " + BASE_URL_BENF + globales._api + 
      globales._user + ORDERS_ENDPOINTS.EATC_SALE_ORDER + ORDERS_ENDPOINTS.QUESTION + 
      ORDERS_ENDPOINTS.EATC_SALE_ORDER_HEADER_CODE + ORDERS_ENDPOINTS.EQUAL + code, globales.consoleStyle);

      API_BENF.get(BASE_URL_BENF + globales._api + globales._user + ORDERS_ENDPOINTS.EATC_SALE_ORDER + 
        ORDERS_ENDPOINTS.QUESTION + ORDERS_ENDPOINTS.EATC_SALE_ORDER_HEADER_CODE + 
        ORDERS_ENDPOINTS.EQUAL + code)
        .then((res) => {
          console.log("res : ", res);
          console.log("res.data : ", res.data);
          console.log("res.status : ", res.status);
          console.log("res.statusText : ", res.statusText);
          return res.data.res;
        })
        .then((data) => {
          console.log(
            "%c YourOrdersService.getDetails() SUCCESS", globales.consoleStyleSuccess);
          resolve(data);
          console.log("data : ", data);
          console.log("data[0] : ", data[0]);
        })
        .catch((err) => {
          console.log(
            "%c YourOrdersService.getDetails() ERROR", globales.consoleStyleError);
          reject(err);
        });
    }),
}

export default YourOrdersService;