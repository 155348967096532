import React from "react";
import { Link, withRouter } from "react-router-dom";
import { auth } from "../../config/firebase";
import glb from '../../config/config';
import M from 'materialize-css';

import { useDispatch, useSelector } from 'react-redux';
import { fn_getCurrentTransaction, fn_resetCurrentTransactionStatus, fn_getWompiFinancialEntities, fn_getWompiAcceptanceToken, fn_getShippingTypes, fn_showShippingNextButton, fn_showCheckoutStep, fn_getCities, /*fn_createTransaction,*/ fn_processTransaction, fn_getTransactionRedirect } from './checkoutDuck';
import { fn_strip_quotes, fn_formato_numero, fn_format_total_with_cent, fn_set_cart, fn_get_cart, fn_create_unique_token, fn_genera_token } from '../../misc/sc_misc';
import { divStyleZ, divStyleMBn24, divStyleMTp19MBnN10, divStyleMTp16, divStyleTitle } from '../../config/config';

const Checkout = (props) => {
    const dispatch = useDispatch();

    const {user} = JSON.parse(localStorage.getItem('USER')) || {};

    const terms_link = useSelector(store => store.checkout_state.terms_link);

    const cont_shipping_types = useSelector(store => store.checkout_state.cont_shipping_types);
    const shipping_types = useSelector(store => store.checkout_state.shipping_types);

    const showShippingTypeStep = useSelector(store => store.checkout_state.showShippingTypeStep);
    const showDeliveryStep = useSelector(store => store.checkout_state.showDeliveryStep);
    const showPickerStep = useSelector(store => store.checkout_state.showPickerStep);
    const showPaymentStep = useSelector(store => store.checkout_state.showPaymentStep);
    const showDeliveryButton = useSelector(store => store.checkout_state.showDeliveryButton);
    const showPickerButton = useSelector(store => store.checkout_state.showPickerButton);
    const showPaymentButton = useSelector(store => store.checkout_state.showPaymentButton);

    const cont_cities = useSelector(store => store.checkout_state.cont_cities);
    const cities = useSelector(store => store.checkout_state.cities);

    const transaction = useSelector(store => store.checkout_state.transaction);

    let method_info = {};

    let cron = null;
    const cronInterval = 3000;

    //const cart_info = useSelector(store => store.checkout_state.cart_info);

    // trayendo los metodos de despacho
    React.useEffect(() => {
        if(user){
            const initCheckout = async () => {
                try {
                    await dispatch(fn_getCurrentTransaction());
                    if(transaction && transaction.transaction_status=='APPROVED'){
                        fn_clearCart();
                    }
                    if(!transaction || transaction.transaction_status=='APPROVED'){
                        let reference = fn_create_unique_token();
                        fn_set_cart('_id', reference);
                        //initWompiWidget(reference);
                    }
                    await dispatch(fn_getWompiFinancialEntities());
                    await dispatch(fn_getWompiAcceptanceToken());
                    await dispatch(fn_getShippingTypes());
                    fn_set_cart('verification_code', fn_genera_token(6));
                } catch (error) {
                    console.error("Error en funcion initCheckout " + error);
                }
            }
            initCheckout();
        } else{
            goToSignIn();
        }
    }, [dispatch]);

    const goToSignIn = async() => {
        try {
            fn_set_cart('user', {});
            props.history.push('/signin');
        } catch (error) {
            console.error("Error redireccionando a signup");
        }
    };

    const initWompiWidget = (reference) => {
        let wompiWidget = document.getElementById('wompi-pay-button');
        if(wompiWidget){
            wompiWidget.parentNode.removeChild(wompiWidget);
        }
        
        let form = document.createElement('form');
        form.id='wompi-pay-button';
        form.style.display = 'none';
        let widget = document.createElement('script');
        widget.src = 'https://checkout.wompi.co/widget.js';
        widget.dataset.render = 'button';
        widget.dataset.publicKey = glb._wompiPub;
        widget.dataset.currency = 'COP';
        widget.dataset.amountInCents = fn_format_total_with_cent(fn_getFromCart('total'));
        widget.dataset.reference = reference;
        form.appendChild(widget);
        document.body.appendChild(form);
    }
    
    const goToCart = async() => {
        try {
            props.history.push('/carrocompras');
        } catch (error) {
            console.error("Error redireccionando al carrito");
        }
    };

    const goToProducts = async() => {
        try {
            props.history.push('/listaprods');
        } catch (error) {
            console.error("Error redireccionando a productos");
        }
    };

    const fn_setInCart = (evt) => {
        /*try {
            await dispatch(fn_setInCartInfo(evt.target.name, evt.target.value));
        } catch (error) {
            console.error("Error guardando dato en el carrito " + error);
        }*/
        fn_set_cart(evt.target.name, evt.target.value);
    }

    const fn_checkInCart = (evt) => {
        if(evt.target.checked){
            fn_set_cart(evt.target.name, 1);
        } else{
            fn_set_cart(evt.target.name, 0);
        }
    }

    const fn_setInMethodInfo = (evt) => {
        method_info[evt.target.name] = evt.target.value;
    }

    const fn_getFromCart = (key) => {
        /*if(cart_info.hasOwnProperty(key)){
            return cart_info[key];
        }
        return "";*/
        return fn_get_cart(key);
    }
   
    const fn_checkShippingType = async (type) => {
        /*if(type["_id"]==fn_getFromCart("shipping_type")){
            fn_resolveCheckoutNextStep(type);
            return "checked";
        }*/
        return "";
    }

    const fn_getInputValue = (key) => {
        //return fn_get_cart(key); 
        return "";
    }

    const fn_resolveCheckoutNextStep = async (type) => {
        try {
            fn_set_cart("shipping_type", type["_id"]);
            fn_set_cart("shipping_type_name", fn_strip_quotes(type["eatc-name"]));
            await dispatch(fn_showShippingNextButton(fn_strip_quotes(type["eatc-require_delivery_address"]), fn_strip_quotes(type["eatc-require_picker_data"])));
        } catch (error) {
            console.error("Error obteniendo el paso siguiente a despachos " + error);
        }
    };

    const fn_resolveCheckoutStep = async (step) => {
        try {
            await dispatch(fn_showCheckoutStep(step));
            if(step=="delivery_address"){
                try {
                    await dispatch(fn_getCities());
                } catch (error) {
                    console.error("Error trayendo ciudades " + error);
                }
            }
            M.updateTextFields();
            fn_set_cart("terms", 0);
            fn_togglePaymentWrapper(fn_get_cart('payment_method'));
        } catch (error) {
            console.error("Error cambiando paso del checkout " + error);
        }
    };

    const fn_togglePaymentWrapper = (payment) => {
        document.getElementById('tarjeta').style.display = 'none';
        document.getElementById('transferencia').style.display = 'none';
        document.getElementById('nequi').style.display = 'none';
        document.getElementById('pse').style.display = 'none';
        if(payment){
            document.getElementById(payment).style.display = 'block';
            fn_set_cart("payment_method", payment);
        }
    };

    const fn_saveTransaction = async () => {
        try {
            if(fn_get_cart("terms")==1){
                document.getElementById('pay-order-button').innerHTML = 'Procesando el Pago...';
                
                await dispatch(fn_processTransaction(method_info));
                /*await dispatch(fn_createTransaction());
                //$('#payment-form').submit();
                document.getElementById('wompi-pay-button').getElementsByTagName('button')[0].click();
                document.getElementById('pay-order-button').innerHTML = 'Procesando el Pago...';
                document.getElementById('pay-order-button').disabled = true;

                cron = setInterval(fn_verifyPaymentFinish, cronInterval);*/
                if(fn_get_cart("transaction_status")!="PENDING"){
                    window.location.reload();
                } else{
                    cron = setInterval(fn_verifyTransactionRedirect, cronInterval);
                    document.getElementById('pay-order-button').innerHTML = 'Abriendo la entidad bancaria...';
                }
            } else{
                alert("Debes aceptar los términos y condiciones");
            }
        } catch (error) {
            console.error("Error intentando cambiar la cantidad " + error);
        }
    };

    const fn_verifyTransactionRedirect = async () => {
        await dispatch(fn_getTransactionRedirect());
        let transaction_status = fn_get_cart('transaction_status');
        if(transaction_status=='PENDING'){
            let transaction_redirect = fn_get_cart('transaction_redirect');
            if(transaction_redirect){
                fn_set_cart('transaction_redirect', null);
                clearInterval(cron);
                document.getElementById('pay-order-button').style.display = 'none';
                document.getElementById('verify-payment-button').style.display = 'block';
                window.open(transaction_redirect);
            }
        } else{
            clearInterval(cron);
            window.location.reload();
        }
    };

    const fn_verifyTransaction = async () => {
        await dispatch(fn_getCurrentTransaction());
        if(transaction){
            if(transaction.transaction_status=='PENDING'){
                alert('La transacción está en curso. Por favor complete el proceso desde la página del banco abierta en el navegado.');
            } else if(transaction.transaction_status=='APPROVED'){
                fn_clearCart();
            }
        }
    };

    const fn_verifyPaymentFinish = () => {
        let wompiClosedModal = document.getElementsByClassName('waybox-modal-final-close')[0];
        if(wompiClosedModal){
            clearInterval(cron);
            window.location.reload();
        }
    }

    const fn_logout = async () => {
        await auth.signOut();
        fn_set_cart('user', {});
        localStorage.setItem('USER', null);
        props.history.push('/signin');
    }

    const fn_clearCart = () => {
        localStorage.setItem('CART_INFO', '{}');
        fn_set_cart('user', {
            uid: user.uid, 
            photoURL: user.photoURL, 
            displayName: user.displayName, 
            email: user.email
        });
    }

    const fn_goToMyOrders = () => {
        props.history.push('/tuspedidos');
    }

    const fn_retryPayment = async () => {
        await dispatch(fn_resetCurrentTransactionStatus());
        window.location.reload();
    }

    // renderizando el checkout.
    return (
        <div className="wrapper checkout-wrapper">
            { transaction && transaction.transaction_status=='APPROVED' &&
                <div className="transaction-wrapper transaction-approved center">
                    <div className="blanco-bg shadow-8dp" style={divStyleZ}>
                        <div className="row" style={divStyleMBn24}>
                            <div className="col s1 m1 l1">
                                <div style={divStyleMTp16}>
                                    <Link
                                        to="#"
                                        className=""
                                        onClick={() => {
                                            fn_goToMyOrders();
                                        }}
                                    >
                                        <i className="material-icons">close</i>
                                    </Link>
                                </div>
                            </div>
                            <div className="col s11 m11 l11">
                                {/* style={divStyleMTn19} */}
                                <div className="input-field" style={divStyleMTp19MBnN10}>
                                    <h4 className="brand-logo blue-text text-darken-2" style={divStyleTitle}>Gracias por tu compra!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <h3>Tu pedido está listo para ser regocido</h3>
                        {/* <p className="transaction-message">Transacción exitosa!!</p> */}
                        <button className="btn primario-btn" onClick = {() => {fn_goToMyOrders()}}>Ver mi pedido</button>
                    </div>
                </div>
            }
            { transaction && transaction.transaction_status=='REJECTED' &&
                <div className="transaction-wrapper transaction-rejected center">
                    <div className="blanco-bg shadow-8dp" style={divStyleZ}>
                        <div className="row" style={divStyleMBn24}>
                            <div className="col s1 m1 l1">
                                <div style={divStyleMTp16}>
                                    <Link
                                        to="#"
                                        className=""
                                        onClick={() => {
                                            fn_retryPayment();
                                        }}
                                    >
                                        <i className="material-icons">close</i>
                                    </Link>
                                </div>
                            </div>
                            <div className="col s11 m11 l11">
                                {/* style={divStyleMTn19} */}
                                <div className="input-field" style={divStyleMTp19MBnN10}>
                                    <h4 className="brand-logo blue-text text-darken-2" style={divStyleTitle}>Transacción rechazada!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <h3>Tu pago ha sido rechazado</h3>
                        {/* <p className="transaction-message">Transacción rechazada!!</p> */}
                        <button className="btn primario-btn" onClick = {() => {fn_retryPayment()}}>Reintentar pago</button>
                    </div>
                </div> 
            }
            { (!transaction || (transaction.transaction_status!='APPROVED' && transaction.transaction_status!='REJECTED'))  &&
                <div className="checkout-wrapper">
                    <div className="blanco-bg shadow-8dp" style={divStyleZ}>
                        <div className="row" style={divStyleMBn24}>
                            <div className="col s1 m1 l1">
                                <div style={divStyleMTp16}>
                                    <Link
                                        to="#"
                                        className=""
                                        onClick={() => {
                                            goToCart();
                                        }}
                                    >
                                        <i className="material-icons">close</i>
                                    </Link>
                                </div>
                            </div>
                            <div className="col s11 m11 l11">
                                {/* style={divStyleMTn19} */}
                                <div className="input-field" style={divStyleMTp19MBnN10}>
                                    <h4 className="brand-logo blue-text text-darken-2" style={divStyleTitle}>Finalizar el pedido</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container checkout-wizard">
                        { /*user && user.uid && user.uid!=-1 && 
                            <div className = "mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8">
                                <div className="flex border flex-col items-center md:flex-row md:items-start border-blue-400 px-3 py-4">
                                    <div
                                        style={{
                                        background: `url(${user.photoURL || 'https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png'})  no-repeat center center`,
                                        backgroundSize: "cover",
                                        height: "200px",
                                        width: "200px"
                                        }}
                                        className="border border-blue-300"
                                    ></div>
                                    <div className = "md:pl-4">
                                        <h2 className = "text-2xl font-semibold">{user.displayName}</h2>
                                        <h3 className = "italic">{user.email}</h3>
                                    </div>
                                </div>
                                <button className = "w-full py-3 bg-red-600 mt-4 text-white" onClick = {() => {fn_logout()}}>Cerrar Sesión</button>
                            </div>
                        */}
                        { /*user && user.uid && user.uid!=-1 && <button className = "btn link-btn" onClick = {() => {fn_logout()}}>Cerrar Sesión</button>*/ }
                        <div className="row">
                            <div className="col s12 m12 l12">
                                <div id="wizard-content">
                                    <div id="wizard" className="wizard">
                                        { showShippingTypeStep && <h2></h2> }
                                        { showShippingTypeStep && 
                                        <section>
                                            <h3 className="center">Tipo de recogida</h3>
                                            <p>Por favor seleccion un método de entrega para la oferta realizada</p>
                                            <div className="esp-top-grande">
                                                <form action="#">
                                                    { cont_shipping_types > 0 && shipping_types.map((type, index) => (
                                                            <p key={type["_id"]}>
                                                            <label>
                                                                <input onClick={() => fn_resolveCheckoutNextStep(type)} name="shipping_type" id={type["_id"]} type="radio" className="filled-in" />
                                                                <span>{fn_strip_quotes(type["eatc-description"])}</span>
                                                            </label>
                                                            </p>
                                                        ))
                                                    }
                                                </form>
                                            </div>
                                        </section>
                                        }
                                        { showShippingTypeStep && 
                                        <div className="actions">
                                            <ul role="menu" className="width-100">
                                                <li className="disabled"><a onClick = {() => {goToProducts()}}>&larr; Continuar comprando</a></li>
                                                <li>
                                                    { showDeliveryButton && <a onClick={() => fn_resolveCheckoutStep("delivery_address")}>Datos de envío</a> }
                                                    { showPickerButton &&  <a onClick={() => fn_resolveCheckoutStep("picker_data")}>Datos de quien recoge</a> }
                                                    { showPaymentButton && !showDeliveryButton && !showPickerButton && <a onClick={() => fn_resolveCheckoutStep("payment")}>Método de pago</a> }
                                                </li>
                                            </ul>
                                        </div>
                                        } 
                                        { showDeliveryStep && <h2></h2> }
                                        { showDeliveryStep && 
                                        <section>
                                            <h3 className="center">Datos de envío</h3>
                                            <div className="esp-top-base">
                                                {/*
                                                <form action="#">
                                                    <p>
                                                    <label>
                                                        <input type="checkbox" className="filled-in" />
                                                        <span>Casa: Cra. 35 No. 19-620</span>
                                                    </label>
                                                    </p>
                                                    <p>
                                                    <label>
                                                        <input type="checkbox" className="filled-in" checked="checked" />
                                                        <span>Otra: Cll 32EE No.76-65</span>
                                                    </label>
                                                    </p>
                                                </form>
                                                */}
                                                {/*<h4>Crear nueva dirección</h4>*/}
                                                <form>
                                                    <div className="input-field col s12 m12 l12">
                                                    <input placeholder="Dirección" type="text" className="validate" name="address" onBlur={(e) => fn_setInCart(e)} defaultValue={fn_getFromCart('address')} />
                                                    </div>
                                                    <div className="input-field col s12 m12 l12">
                                                    <input placeholder="Detalles dirección" type="text" className="validate" name="address_detail" onBlur={(e) => fn_setInCart(e)} defaultValue={fn_getFromCart('address_detail')} />
                                                    </div>
                                                    <div className="input-field col s12">
                                                        <select className="width-100" defaultValue={fn_getFromCart('city')} name="city" onChange={(e) => fn_setInCart(e)}>
                                                            <option value="" disabled>Ciudad</option>
                                                            { cont_cities > 0 && cities.map((city, index) => (
                                                                    <option key={city["eatc-city"]} value={city["eatc-city"]}>{city["eatc-city"]}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </form>
                                                {/*
                                                <button className="btn primario-btn shadow-2dp waves-effect waves-light">Guardar Dirección</button>
                                                */}
                                            </div>
                                        </section>
                                        }
                                        { showDeliveryStep && 
                                            <div className="actions">
                                                <ul role="menu" className="width-100">
                                                    <li className="disabled"><a onClick={() => fn_resolveCheckoutStep("shipping_type")}>Tipo recogida</a></li>
                                                    <li><a onClick={() => fn_resolveCheckoutStep("payment")}>Método de pago</a></li>
                                                </ul>
                                            </div>
                                        }
                                        { showPickerStep && <h2></h2> }
                                        { showPickerStep && 
                                        <section>
                                            <h3 className="center">Datos de recogida</h3>
                                            <div className="esp-top-base">
                                                <form>
                                                    <div className="input-field col s12 m12 l12">
                                                        <input placeholder="Nombre de quién recoge" type="text" className="validate" name="picker_name" onBlur={(e) => fn_setInCart(e)} defaultValue={fn_getFromCart('picker_name')} />
                                                        <label>Nombre de quién recoge</label>
                                                    </div>
                                                    <div className="input-field col s12 m12 l12">
                                                        <input placeholder="Número de documento" type="text" className="validate" name="picker_document" onBlur={(e) => fn_setInCart(e)} defaultValue={fn_getFromCart('picker_document')} />
                                                        <label>Número de documento</label>
                                                    </div>
                                                    <div className="input-field col s12 m12 l12">
                                                        <p><b>Código de recogida:</b> {fn_getFromCart('verification_code')}</p>
                                                    </div>
                                                    <p>Recuerde que debe presentar este código para recoger el producto.</p>
                                                </form>
                                            </div>
                        
                                        </section>
                                        }
                                        { showPickerStep && 
                                            <div className="actions">
                                                <ul role="menu" className="width-100">
                                                    <li className="disabled"><a onClick={() => fn_resolveCheckoutStep("shipping_type")}>Tipo recogida</a></li>
                                                    <li><a onClick={() => fn_resolveCheckoutStep("payment")}>Método de pago</a></li>
                                                </ul>
                                            </div>
                                        }
                                        { showPaymentStep && <h2></h2> }
                                        { showPaymentStep && 
                                        <section>
                                            <h3 className="center">Método de pago</h3>
                                            <div className="esp-top-base">
                                                <h4>Resumen</h4>
                                                <p>Tipo de regogida: {fn_getFromCart("shipping_type_name")}</p>
                                                { showDeliveryButton && <p>Dirección: {fn_getFromCart("address")}</p> }
                                                { showDeliveryButton && <p>Detalles de dirección: {fn_getFromCart("address_detail")}</p> }
                                                { showDeliveryButton && <p>Ciudad: {fn_getFromCart("city")}</p> }
                                                { showPickerButton && <p>Nombre de quién recoge: {fn_getFromCart("picker_name")}</p> }
                                                { showPickerButton && <p>Documento de quién recoge: {fn_getFromCart("picker_document")}</p> }
                                                { showPickerButton && <p>Código de recogida: {fn_getFromCart('verification_code')}</p> }
                                                <p><b>Total a pagar: ${fn_formato_numero(fn_getFromCart("total"), 0, '.')}</b></p>
                                                <h4>Selecciona el medio de pago:</h4>
                                                <p>
                                                    <label>
                                                        <input type="radio" name="payment_method" className="filled-in" defaultChecked={fn_getFromCart('payment_method')=='tarjeta'} onChange={(e) => fn_togglePaymentWrapper('tarjeta')} />
                                                        <span>Tarjeta de Crédito</span>
                                                    </label>
                                                </p>
                                                <p>
                                                    <label>
                                                        <input type="radio" name="payment_method" className="filled-in" defaultChecked={fn_getFromCart('payment_method')=='transferencia'} onChange={(e) => fn_togglePaymentWrapper('transferencia')} />
                                                        <span>Transferencia Bancolombia</span>
                                                    </label>
                                                </p>
                                                <p>
                                                    <label>
                                                        <input type="radio" name="payment_method" className="filled-in" defaultChecked={fn_getFromCart('payment_method')=='nequi'} onChange={(e) => fn_togglePaymentWrapper('nequi')} />
                                                        <span>Nequi</span>
                                                    </label>
                                                </p>
                                                <p>
                                                    <label>
                                                        <input type="radio" name="payment_method" className="filled-in" defaultChecked={fn_getFromCart('payment_method')=='pse'} onChange={(e) => fn_togglePaymentWrapper('pse')} />
                                                        <span>PSE</span>
                                                    </label>
                                                </p>
                                                <div id="tarjeta" className="payment-wrapper">
                                                    <div className="input-field col s12 m12 l12">
                                                        <input placeholder="Nombre en la tarjeta" name="card_name" type="text" required onBlur={(e) => fn_setInMethodInfo(e)} />
                                                        <label>Nombre en la tarjeta</label>
                                                    </div>
                                                    <div className="input-field col s12 m12 l12">
                                                        <input placeholder="XXXX XXXX XXXX XXXX" name="card_number" type="number" required onBlur={(e) => fn_setInMethodInfo(e)} />
                                                        <label>Número en la tarjeta</label>
                                                    </div>
                                                    <div className="input-field col s3 m3 l3">
                                                        <input placeholder="mm" name="card_month" type="number" min="1" max="12" required onBlur={(e) => fn_setInMethodInfo(e)} />
                                                        <label>Mes</label>
                                                    </div>
                                                    <div className="input-field col s3 m3 l3">
                                                        <input placeholder="yy" name="card_year" type="number" min="2020" max="2050" required onBlur={(e) => fn_setInMethodInfo(e)} />
                                                        <label>Año</label>
                                                    </div>
                                                    <div className="input-field col s4 m4 l4">
                                                        <input placeholder="***" name="card_cvc" type="number" required onBlur={(e) => fn_setInMethodInfo(e)} />
                                                        <label>CVC</label>
                                                    </div>
                                                </div>
                                                <div id="transferencia" className="payment-wrapper"></div>
                                                <div id="nequi" className="payment-wrapper">
                                                    <div className="input-field col s12 m12 l12">
                                                        <input placeholder="ej. 3107654321" name="mobile_number" type="number" required onBlur={(e) => fn_setInMethodInfo(e)} />
                                                        <label>Celular</label>
                                                    </div>
                                                </div>
                                                <div id="pse" className="payment-wrapper">
                                                    <div className="input-field col s12 m12 l12">
                                                        <span className="input-label">Tipo de persona</span>
                                                        <div className="input-options">
                                                            <p className="col s6 m6 l6">
                                                                <label>
                                                                    <input type="radio" name="person_type" defaultValue="0" className="filled-in" onChange={(e) => fn_setInMethodInfo(e)} />
                                                                    <span>Natural</span>
                                                                </label>
                                                            </p>
                                                            <p className="col s6 m6 l6">
                                                                <label>
                                                                    <input type="radio" name="person_type" defaultValue="1" className="filled-in" onChange={(e) => fn_setInMethodInfo(e)} />
                                                                    <span>Jurídica</span>
                                                                </label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="input-field col s12 m12 l12">
                                                        <span className="input-label">Tipo de documento</span>
                                                        <div className="input-options">
                                                            <p className="col s6 m6 l6">
                                                                <label>
                                                                    <input type="radio" name="document_type" defaultValue="CC" className="filled-in" onChange={(e) => fn_setInMethodInfo(e)} />
                                                                    <span>CC</span>
                                                                </label>
                                                            </p>
                                                            <p className="col s6 m6 l6">
                                                                <label>
                                                                    <input type="radio" name="document_type" defaultValue="NIT" className="filled-in" onChange={(e) => fn_setInMethodInfo(e)} />
                                                                    <span>NIT</span>
                                                                </label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="input-field col s12 m12 l12">
                                                        <input placeholder="123456" name="document_number" type="number" required onBlur={(e) => fn_setInMethodInfo(e)} />
                                                        <label>Número de documento</label>
                                                    </div>
                                                    <div className="input-field col s12 m12 l12">
                                                        <span className="input-label">Banco</span>
                                                        <select className="width-100" name="bank" defaultValue="1" onChange={(e) => fn_setInMethodInfo(e)}>
                                                            { JSON.parse(localStorage.getItem('FINANCIAL_ENTITIES')).map((entity, index) => (
                                                                    <option key={entity["financial_institution_code"]} value={entity["financial_institution_code"]}>{entity["financial_institution_name"]}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                { fn_getFromCart('terms_link') &&
                                                <p className="terms">
                                                    <label>
                                                        <input type="checkbox" className="filled-in" name="terms" onChange={(e) => fn_checkInCart(e)} />
                                                        <span>Acepto haber leído los <a href={fn_getFromCart('terms_link')} target="_blank">términos y condiciones, y politicas de privacidad</a> para hacer esta compra</span>
                                                    </label>
                                                </p>
                                                }
                                                {/*
                                                <form id="payment-form" action="https://checkout.wompi.co/p/" method="GET">
                                                    <input type="hidden" name="public-key" value={glb._wompiPub} />
                                                    <input type="hidden" name="currency" value="COP" />
                                                    <input type="hidden" name="amount-in-cents" value={fn_format_total_with_cent(fn_getFromCart("total"))} />
                                                    <input type="hidden" name="reference" value={reference} />
                                            
                                                </form>
                                                
                                                
                                                <button className="btn primario-btn shadow-2dp waves-effect waves-light width-100">Guardar medio de pago</button>
                                                */}
                                            </div>
                                        </section>
                                        }
                                        { showPaymentStep && 
                                            <div id="pay-order-button" className="actions">
                                                <ul role="menu" className="width-100">
                                                    <li className="disabled">
                                                        { !showDeliveryButton && !showPickerButton && <a onClick={() => fn_resolveCheckoutStep("shipping_type")}>Tipo recogida</a> }
                                                        { showDeliveryButton && <a onClick={() => fn_resolveCheckoutStep("delivery_address")}>Datos envío</a> }
                                                        { showPickerButton && <a onClick={() => fn_resolveCheckoutStep("picker_data")}>Datos recogida</a> }
                                                    </li>
                                                    <li><a onClick={() => {fn_saveTransaction();}}>Pagar</a></li>
                                                </ul>
                                            </div>
                                        }
                                        { showPaymentStep && 
                                            <div id="verify-payment-button" className="actions" style={{display: 'none'}}>
                                                <ul role="menu" className="width-100">
                                                    <li><a onClick={() => {fn_verifyTransaction();}}>Continuar</a></li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            }
            
        </div>
    );    
};

export default withRouter(Checkout)
