import React, { Component } from "react";
//import { Link } from "react-router-dom";
import globales from "../../config/config";
import ImportantService from "../../services/ImportantService";
import { fn_convert_am_pm } from "../../misc/sc_misc";
import YourOrdersService from "../../services/YourOrdersService";
//import nomap from "../../img/nomap.jpg";
//import noimage from "../../img/noimage.jpg";

export default class CardOrderVer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      point: "",
    };
  }

  componentDidMount() {
    console.log(
      "%c CardOrderVer...Estas en puntos de venta",
      globales.consoleStyle
    );
    console.log("CardOrderVer...this.props.order", this.props.order);
    this.getSomeOfferSchedule(this.props.order);
    this.getInProgressByVarious(this.props.order["eatc-pod_lat"], this.props.order["eatc-pod_lon"], 5, this.props.order["eatc-user_code"])
  }

  getSomeOfferSchedule(new_nearby_point) {
    console.log("CardOrderVer... getSomeOfferSchedule : ", new_nearby_point);
    if (new_nearby_point["eatc-cua_origin"] !== "") {
      if (new_nearby_point["eatc-pod_id"] !== "") {
        ImportantService.getSchedule(
          new_nearby_point["eatc-cua_origin"],
          new_nearby_point["eatc-pod_id"]
        )
          .then((schedule) => {
            console.log(
              "CardOrderVer... getSomeOfferSchedule :  -> Ocurrió un exito->schedule",
              schedule
            );

            var weekday = new Array(7);
            weekday[0] = "do";
            weekday[1] = "lu";
            weekday[2] = "ma";
            weekday[3] = "mi";
            weekday[4] = "ju";
            weekday[5] = "vi";
            weekday[6] = "sa";

            var fecha_actual = new Date();
            var dia_actual = weekday[fecha_actual.getDay()];
            console.log("dia_actual ", dia_actual);
            console.log("dia_actual key", weekday.indexOf(dia_actual));
            var dia_siguiente = weekday[fecha_actual.getDay() + 1];
            console.log("dia_actual siguiente ", dia_siguiente);
            console.log(
              "dia_actual siguiente key",
              weekday.indexOf(dia_siguiente)
            );

            const ordenamiento = {
              do: 0,
              lu: 1,
              ma: 2,
              mi: 3,
              ju: 4,
              vi: 5,
              sa: 6,
            };

            schedule.sort(
              (a, b) =>
                ordenamiento[a["eatc-day"]] - ordenamiento[b["eatc-day"]]
            );

            console.table("dia_actual table", schedule);

            /* console.log(
              "objectsArrayExistValueReturn",
              this.objectsArrayExistValue(schedule, dia_uno)
            ); */

            /*  console.log(
              "CardInfoImageHor... getSomeOfferSchedule : -> Ocurrió un exito 1-> ",
              schedule[0]
            );
            console.log(
              "CardInfoImageHor... getSomeOfferSchedule : -> Ocurrió un exito 2-> ",
              schedule[1]
            );
 */
            //RECORRER EL SCHEDULE Y PREGUNTAR SI EXISTE UN eatc-day = dia_uno

            /*  schedule.forEach((day) => {
              console.log("dia_uno -", day["eatc-day"]);
              console.log("dia_uno --", schedule.indexOf(day));
              console.log("dia_uno --", day.indexOf(dia_actual));
              if (day.indexOf(dia_actual) !== -1) {
                console.log("dia_uno", "HAY UN MARTES");
              }
            }); */

            /*  if (schedule.indexOf(dia_uno)) {
              console.log("dia_uno ", "HAY UN MARTES");
            } else {
              console.log("dia_uno ", "NO HAY UN MARTES");
            }
 */

            /* new_nearby_point["eatc-day_initial"] = schedule[0]["eatc-day"];
            new_nearby_point["eatc-start_hour_initial"] =
              schedule[0]["eatc-start_hour"];
            new_nearby_point["eatc-start_hour_final"] =
              schedule[0]["eatc-final_hour"];
            new_nearby_point["eatc-day_final"] = schedule[1]["eatc-day"];
            new_nearby_point["eatc-final_hour_initial"] =
              schedule[1]["eatc-start_hour"];
            new_nearby_point["eatc-final_hour_final"] =
              schedule[1]["eatc-final_hour"];
            console.log(
              "CardInfoImageHor... getSomeOfferSchedule : -> Ocurrió un exito TOTAL -> new_nearby_point ",
              new_nearby_point
            ); */

            var diaActualFromSchedule = this.objectsArrayExistValue(
              schedule,
              dia_actual
            );

            if (diaActualFromSchedule !== -1) {
              // OBTENER EL KEY DEL DIA y SU DIA SIGUENTE
              console.log(
                "dia_actual diaActualFromSchedule",
                "EXISTE UNA COINCIDENCIA"
              );
              console.log(
                "dia_actual diaActualFromSchedule",
                diaActualFromSchedule
              );
              new_nearby_point["eatc-day_initial"] =
                schedule[diaActualFromSchedule]["eatc-day"];
              new_nearby_point["eatc-start_hour_initial"] =
                schedule[diaActualFromSchedule]["eatc-start_hour"];
              new_nearby_point["eatc-start_hour_final"] =
                schedule[diaActualFromSchedule]["eatc-final_hour"];

              console.log(
                "dia_actual diaActualFromSchedule CardInfoImageHor... getSomeOfferSchedule : -> Ocurrió un exito TOTAL -> new_nearby_point ",
                new_nearby_point
              );
            }

            var diaSiguienteFromSchedule = this.objectsArrayExistValue(
              schedule,
              dia_siguiente
            );

            if (diaSiguienteFromSchedule !== -1) {
              // OBTENER EL KEY DEL DIA y SU DIA SIGUENTE
              console.log(
                "dia_actual dia_siguiente diaSiguienteFromSchedule",
                "EXISTE UNA COINCIDENCIA"
              );
              console.log(
                "dia_actual dia_siguiente diaSiguienteFromSchedule",
                diaSiguienteFromSchedule
              );
              new_nearby_point["eatc-day_final"] =
                schedule[diaSiguienteFromSchedule]["eatc-day"];
              new_nearby_point["eatc-final_hour_initial"] =
                schedule[diaSiguienteFromSchedule]["eatc-start_hour"];
              new_nearby_point["eatc-final_hour_final"] =
                schedule[diaSiguienteFromSchedule]["eatc-final_hour"];
              console.log(
                "dia_actual dia_siguiente CardInfoImageHor... getSomeOfferSchedule : -> Ocurrió un exito TOTAL -> new_nearby_point ",
                new_nearby_point
              );
            }

            this.setState({ point: new_nearby_point });
          })
          .catch((err) => {
            console.log(
              "CardInfoImageHor... getSomeOfferSchedule : -> Ocurrió un error -> ",
              err
            );
          });
      }
    } else {
      console.log(
        "%c CardInfoImageHor... getSomeOfferSchedule : -> nearbyOffer con eatc_cua_origin vacia",
        globales.consoleStyleError
      );
    }
  }

  getInProgressByVarious(actual_lat, actual_lon, actual_sale_radius_km, code) {
    YourOrdersService.getInProgress("eatc_sale_order_headers", "eatc-pod_lat,eatc-pod_lon", actual_lat, actual_lon, "eatc-code", actual_sale_radius_km, 
    "eatc-code", code)
      .then((orders) => {
        console.log("Ocurrió un exito -> ", orders);
      })
      .catch((err) => {
        console.log("Ocurrió un error -> ", err);
      });
  }

  objectsArrayExistValue(arrobj, value) {
    var result = -1;
    arrobj.forEach((day) => {
      console.log("dia_actual obj", day);
      console.log("dia_actual obj val", day["eatc-day"]);
      console.log("dia_actual value", value);
      console.log("dia_actual key", arrobj.indexOf(day));
      if (day["eatc-day"].toLowerCase() === value.toLowerCase())
        result = arrobj.indexOf(day);
    });
    return result;
  }

  render() {
    const color = "#00B3FE";
    const colorRed = "#FF0000";
    return (
      <div className="col s12 m12 l12 div-perd-ped">
        {/* <Link
          to={{
            pathname: `/detalleprod/${this.props.order_verification_code}`,
            state: this.props.order,
          }}
        > */}
        <div className="card horizontal">
          {/* <div className="card-image">
              <img
                src={
                  this.props.odd_image === "" ? noimage : this.props.odd_image
                }
                alt="noimage"
              />
            </div> */}
          <div className="card-stacked">
            <div className="card-content">
              <span className="card-title-med">
                {this.props.order_verification_code}
              </span>
              <span className="card-title-med">{this.props.eatc_pod_name}</span>
              <span className="txt-meta">{this.props.eatc_pod_address}</span>
              <span className="txt-meta txt-accent">
                {this.props.km === undefined ? (
                  <div></div>
                ) : (
                  <div>a {this.props.km}KM</div>
                )}
              </span>
              {/*  <span className="txt-meta txt-primary">
                {this.props.horario === undefined ? (
                  <div>NO EXISTEN HORARIOS DEFINIDOS</div>
                ) : (
                  <div>{this.props.horario}</div>
                )}
              </span> */}
              <span className="txt-meta txt-primary">
                {this.state.point === "" ? (
                  <div>NO EXISTEN HORARIOS DEFINIDOS</div>
                ) : (
                  <div></div>
                )}
                {this.state.point["eatc-day_initial"] === undefined ? (
                  <div></div>
                ) : (
                  <div>
                    {this.state.point["eatc-day_initial"].toUpperCase()}{" "}
                    {fn_convert_am_pm(
                      this.state.point["eatc-start_hour_initial"]
                    )}
                    -{" "}
                    {fn_convert_am_pm(
                      this.state.point["eatc-start_hour_final"]
                    )}
                    {/* <br />

                      {this.state.point["eatc-day_final"].toUpperCase()}{" "}
                      {fn_convert_am_pm(
                        this.state.point["eatc-final_hour_initial"]
                      )}
                      -{" "}
                      {fn_convert_am_pm(
                        this.state.point["eatc-final_hour_final"]
                      )} */}
                  </div>
                )}
                {this.state.point["eatc-day_final"] === undefined ? (
                  <div></div>
                ) : (
                  <div>
                    {this.state.point["eatc-day_final"].toUpperCase()}{" "}
                    {fn_convert_am_pm(
                      this.state.point["eatc-final_hour_initial"]
                    )}
                    -{" "}
                    {fn_convert_am_pm(
                      this.state.point["eatc-final_hour_final"]
                    )}
                  </div>
                )}
              </span>
              <span className="txt-meta">{this.props.eatc_datetime}</span>
              <span className="precio-oficial">
                ${this.props.eatc_total_price}
              </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "http://maps.google.com/maps?q=loc:" +
                  this.state.point["eatc-pod_lat"] +
                  "," +
                  this.state.point["eatc-pod_lon"] +
                  "&navigate=yes"
                }
                className="btn-small primario-btn shadow-2dp waves-effect waves-light"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "background-color",
                      color,
                      "important"
                    );
                  }
                }}
              >
                ¿Cómo llegar?
              </a>
              <button
                className="btn-small primario-btn shadow-2dp waves-effect waves-light"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "background-color",
                      colorRed,
                      "important"
                    );
                  }
                }}
              >
                Ayuda
              </button>
              <button
                className="btn-small primario-btn shadow-2dp waves-effect waves-light"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "background-color",
                      colorRed,
                      "important"
                    );
                  }
                }}
              >
                AVISO DE ALERTA
              </button>
            </div>
          </div>
        </div>
        {/* </Link> */}
      </div>
    );
  }
}
