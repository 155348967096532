//https://devdonantes.eatcloud.info/api/colombia/eatc_sale_schedule?eatc-pod_id=u4bdtz0ie85xBZPKp1gaY
import axios from "axios";
import globales from "../config/config";

const BASE_URL_DONA = globales._dona;
const BASE_URL_BENF = globales._benf

const API_DONA = axios.create({
  baseURL: BASE_URL_DONA,
});

const API_BENF = axios.create({
  baseURL: BASE_URL_BENF,
});

const IMPORTANT_ENDPOINTS = {
  API: globales._api,
  EATC_SALE_SCHEDULE: "eatc_sale_schedule?",
  EATC_POD_ID: "eatc-pod_id",
  SLASH: "/",
  CONCAT: "&",
  EQUAL: "=",
  COMA: ",",
};

const ImportantService = {
  getSchedule: (eatc_cua_origin, eatc_pod_id) =>
    new Promise((resolve, reject) => {
      console.log("%c Entrando a ImportantService.getSchedule()", globales.consoleStyle);
      console.log(
        "ImportantService.getSchedule(eatc_cua_origin :",
        eatc_cua_origin,
        "eatc_pod_id :",
        eatc_pod_id
      );

      console.log(
        "%c ImportantService.getSchedule() URL : " +
          BASE_URL_DONA +
          IMPORTANT_ENDPOINTS.API +
          eatc_cua_origin +
          IMPORTANT_ENDPOINTS.SLASH +
          IMPORTANT_ENDPOINTS.EATC_SALE_SCHEDULE +
          IMPORTANT_ENDPOINTS.EATC_POD_ID +
          IMPORTANT_ENDPOINTS.EQUAL +
          eatc_pod_id,
        globales.consoleStyle
      );

      API_DONA.get(
        IMPORTANT_ENDPOINTS.API +
          eatc_cua_origin +
          IMPORTANT_ENDPOINTS.SLASH +
          IMPORTANT_ENDPOINTS.EATC_SALE_SCHEDULE +
          IMPORTANT_ENDPOINTS.EATC_POD_ID +
          IMPORTANT_ENDPOINTS.EQUAL +
          eatc_pod_id
      )
        .then((res) => {
          console.log("res : ", res);
          console.log("res.data : ", res.data);
          console.log("res.status : ", res.status);
          console.log("res.statusText : ", res.statusText);
          return res.data.res;
        })
        .then((data) => {
          console.log(
            "%c ImportantService.getSchedule() SUCCESS",
            globales.consoleStyleSuccess
          );
          resolve(data);
          console.log("data : ", data);
          console.log("data[0] : ", data[0]);
        })
        .catch((err) => {
          console.log(
            "%c ImportantService.getSchedule() ERROR",
            globales.consoleStyleError
          );
          console.log("$...ERROR -> ", err);
          console.log("$...ERROR.RESPONSE -> ", err.response);
          // Error 😨
          if (err.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            /*
             * The request was made but no response was received, `err.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(err.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", err.message);
          }
          console.log(err.config);
          reject(err);
        });
    }),
  getScheduleBenf: (eatc_cua_origin, eatc_pod_id) =>
    new Promise((resolve, reject) => {
      console.log("%c Entrando a ImportantService.getScheduleBenf()", globales.consoleStyle);
      console.log(
        "ImportantService.getScheduleBenf(eatc_cua_origin :",
        eatc_cua_origin,
        "eatc_pod_id :",
        eatc_pod_id
      );

      console.log(
        "%c ImportantService.getScheduleBenf() URL : " +
          BASE_URL_BENF +
          IMPORTANT_ENDPOINTS.API +
          eatc_cua_origin +
          IMPORTANT_ENDPOINTS.SLASH +
          IMPORTANT_ENDPOINTS.EATC_SALE_SCHEDULE +
          IMPORTANT_ENDPOINTS.EATC_POD_ID +
          IMPORTANT_ENDPOINTS.EQUAL +
          eatc_pod_id,
        globales.consoleStyle
      );

      API_BENF.get(
        IMPORTANT_ENDPOINTS.API +
          eatc_cua_origin +
          IMPORTANT_ENDPOINTS.SLASH +
          IMPORTANT_ENDPOINTS.EATC_SALE_SCHEDULE +
          IMPORTANT_ENDPOINTS.EATC_POD_ID +
          IMPORTANT_ENDPOINTS.EQUAL +
          eatc_pod_id
      )
        .then((res) => {
          console.log("res : ", res);
          console.log("res.data : ", res.data);
          console.log("res.status : ", res.status);
          console.log("res.statusText : ", res.statusText);
          return res.data.res;
        })
        .then((data) => {
          console.log(
            "%c ImportantService.getScheduleBenf() SUCCESS",
            globales.consoleStyleSuccess
          );
          resolve(data);
          console.log("data : ", data);
          console.log("data[0] : ", data[0]);
        })
        .catch((err) => {
          console.log(
            "%c ImportantService.getScheduleBenf() ERROR",
            globales.consoleStyleError
          );
          console.log("$...ERROR -> ", err);
          console.log("$...ERROR.RESPONSE -> ", err.response);
          // Error 😨
          if (err.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            /*
             * The request was made but no response was received, `err.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(err.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", err.message);
          }
          console.log(err.config);
          reject(err);
        });
    }),
};

export default ImportantService;
