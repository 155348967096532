//export const CORREO_ELECTRONICO = "juan@eatcloud.com";
//export const NUMERO_CEDULA = "8161174";

import globales from "../config/config";

/* #region  CIUDAD_LOCATION ****************************************************/
/*******************************************************************************/
/*******************************************************************************/

/*************CIUDAD_LOCATION************/

export const CIUDAD_LOCATION = "CIUDAD_LOCATION";
export const isCitied = () => {
  console.log("%c#...[auth.js] SI TIENE CITY?", globales.consoleStyleStorageIs);
  return localStorage.getItem(CIUDAD_LOCATION) !== null;
};
export const getCitied = () => {
  //console.log("%c#...[auth.js] OBTENIENDO CITY", globales.consoleStyleStorageGet);
  return localStorage.getItem(CIUDAD_LOCATION);}
export const setCitied = (city) => {
  console.log("%c#...[auth.js] GUARDANDO CITY", globales.consoleStyleStorageSave);
  console.log("#...[auth.js] >> CITY : " , city);
  localStorage.setItem(CIUDAD_LOCATION, JSON.stringify(city));
};
export const remCitied = () => {
  console.log("%c#...[auth.js] ELIMINANDO CITY ", globales.consoleStyleStorageDelete);
  localStorage.removeItem(CIUDAD_LOCATION);
};

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  CIUDAD_LOCATION *************************************************/

/* #region  STATE_LOCATION *****************************************************/
/*******************************************************************************/
/*******************************************************************************/

/*************STATE_LOCATION************/

export const STATE_LOCATION = "STATE_LOCATION";
export const isStated = () => {
  console.log("%c#...[auth.js] SI TIENE ESTADO?", globales.consoleStyleStorageIs);
  return localStorage.getItem(STATE_LOCATION) !== null;
};
export const getStated = () => {
  //console.log("%c#...[auth.js] OBTENIENDO ESTADO", globales.consoleStyleStorageGet);
  return localStorage.getItem(STATE_LOCATION);}
export const setStated = (state) => {
  console.log("%c#...[auth.js] GUARDANDO ESTADO", globales.consoleStyleStorageSave);
  console.log("#...[auth.js] >> ESTADO : " , state);
  localStorage.setItem(STATE_LOCATION, String(state));
};
export const remStated = () => {
  console.log("%c#...[auth.js] ELIMINANDO ESTADO ", globales.consoleStyleStorageDelete);
  localStorage.removeItem(STATE_LOCATION);
};

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  STATE_LOCATION **************************************************/

/* #region  INFO_USER **********************************************************/
/*******************************************************************************/
/*******************************************************************************/

/*************INFO_USER************/

export const INFO_USER = "INFO_USER";
export const isUser = () => localStorage.getItem(INFO_USER) !== null;
export const getUser = () => localStorage.getItem(INFO_USER);
export const setUser = (info) =>
  localStorage.setItem(INFO_USER, JSON.stringify(info));
export const remUser = () => localStorage.removeItem(INFO_USER);

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  INFO_USER *******************************************************/

/* #region  POINTS_CITY ********************************************************/
/*******************************************************************************/
/*******************************************************************************/

/*************POINTS_CITY************/

export const POINTS_CITY = "POINTS_CITY";
export const isPoints = () => localStorage.getItem(POINTS_CITY) !== null;
export const getPoints = () => localStorage.getItem(POINTS_CITY);
export const setPoints = (points) => {
  console.log("%c#...[auth.js] GUARDANDO POINTS", globales.consoleStyleStorageSave);
  console.log("#...[auth.js] >> POINTS : " , points);
  localStorage.setItem(POINTS_CITY, JSON.stringify(points));
};
export const remPoints = () => localStorage.removeItem(POINTS_CITY);

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  POINTS_CITY *****************************************************/

/* #region  NEARBY_OFFERS ******************************************************/
/*******************************************************************************/
/*******************************************************************************/

/*************NEARBY_OFFERS************/

export const NEARBY_OFFERS = "NEARBY_OFFERS";
export const isNearbyOffers = () => {
  console.log("%c#...[auth.js] SI TIENE NEARBY OFFERS?", globales.consoleStyleStorageIs);
  return localStorage.getItem(NEARBY_OFFERS) !== null;}
export const getNearbyOffers = () => {
  //console.log("%c#...[auth.js] OBTENIENDO NEARBY OFFERS", globales.consoleStyleStorageGet);
  return localStorage.getItem(NEARBY_OFFERS);}
export const setNearbyOffers = (offers) => {
  console.log("%c#...[auth.js] GUARDANDO NEARBY OFFERS", globales.consoleStyleStorageSave);
  console.log("#...[auth.js] >> NEARBY OFFERS : " , offers);
  localStorage.setItem(NEARBY_OFFERS, JSON.stringify(offers));
};
export const remNearbyOffers = () => {
  console.log("%c#...[auth.js] ELIMINANDO NEARBY OFFERS", globales.consoleStyleStorageDelete);
  return localStorage.removeItem(NEARBY_OFFERS);}

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  NEARBY_OFFERS ***************************************************/

/* #region  NEARBY_MESSAGES ****************************************************/
/*******************************************************************************/
/*******************************************************************************/

/*************NEARBY_MESSAGES************/

export const NEARBY_MESSAGES = "NEARBY_MESSAGES";
export const isNearbyMessages = () => {
  console.log("%c#...[auth.js] SI TIENE NEARBY MESSAGES?", globales.consoleStyleStorageIs);
  return localStorage.getItem(NEARBY_MESSAGES) !== null;}
export const getNearbyMessages = () => {
  //console.log("%c#...[auth.js] OBTENIENDO NEARBY MESSAGES", globales.consoleStyleStorageGet);
  return localStorage.getItem(NEARBY_MESSAGES);}
export const setNearbyMessages = (messages) => {
  console.log("%c#...[auth.js] GUARDANDO NEARBY MESSAGES", globales.consoleStyleStorageSave);
  console.log("#...[auth.js] >> NEARBY MESSAGES : " , messages);
  localStorage.setItem(NEARBY_MESSAGES, JSON.stringify(messages));
};
export const remNearbyMessages = () => {
  console.log("%c#...[auth.js] ELIMINANDO NEARBY MESSAGES", globales.consoleStyleStorageDelete);
  return localStorage.removeItem(NEARBY_MESSAGES);}

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  NEARBY_MESSAGES *************************************************/

/* #region  GEO_LOCATION *******************************************************/
/*******************************************************************************/
/*******************************************************************************/

/*************GEO_LOCATION************/

export const GEO_LOCATION = "GEO_LOCATION";
export const GEO_LOCATION_LAT = GEO_LOCATION + "_LAT";
export const GEO_LOCATION_LNG = GEO_LOCATION + "_LNG";
export const isGeoLocated = () => {
  console.log("%c#...[auth.js] SI TIENE GEOLOCATION?", globales.consoleStyleStorageIs);
  return localStorage.getItem(GEO_LOCATION_LAT) !== null ||
  localStorage.getItem(GEO_LOCATION_LNG) !== null;}
export const getLat = () => {
  //console.log("%c#...[auth.js] OBTENIENDO LATITUD", globales.consoleStyleStorageGet);
  return localStorage.getItem(GEO_LOCATION_LAT);}
export const getLng = () => {
  //console.log("%c#...[auth.js] OBTENIENDO LONGITTUD", globales.consoleStyleStorageGet);
  return localStorage.getItem(GEO_LOCATION_LNG);}
export const setLocation = (lat, lng) => {
  console.log("%c#...[auth.js] GUARDANDO GEOLOCATION", globales.consoleStyleStorageSave);
  console.log("#...[auth.js] >> LATITUD : " , lat , "\n>> LONGITUD : " , lng);
  localStorage.setItem(GEO_LOCATION_LAT, lat);
  localStorage.setItem(GEO_LOCATION_LNG, lng);
};
export const remLocation = () => {
  console.log("%c#...[auth.js] ELIMINANDO GEOLOCATION", globales.consoleStyleStorageDelete);
  localStorage.removeItem(GEO_LOCATION_LAT);
  localStorage.removeItem(GEO_LOCATION_LNG);
};

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  GEO_LOCATION ****************************************************/

/* #region  TOKEN **************************************************************/
/*******************************************************************************/
/*******************************************************************************/

/*************TOKEN************/

export const TOKEN = "TOKEN";
export const isToken = () => {
  console.log("%c#...[auth.js] SI TIENE TOKEN?", globales.consoleStyleStorageIs);
  return localStorage.getItem(TOKEN) !== null;}
export const getToken = () => {
  //console.log("%c#...[auth.js] OBTENIENDO TOKEN", globales.consoleStyleStorageGet);
  return localStorage.getItem(TOKEN);}
export const setToken = (token) =>{
  console.log("%c#...[auth.js] GUARDANDO TOKEN", globales.consoleStyleStorageSave);
  console.log("#...[auth.js] >> TOKEN : " + token);
  localStorage.setItem(TOKEN, token);}
export const remToken = () => {
  console.log("%c#...[auth.js] ELIMINANDO TOKEN", globales.consoleStyleStorageDelete);
  return localStorage.removeItem(TOKEN);}

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  TOKEN ***********************************************************/

/* #region  RADIUS *************************************************************/
/*******************************************************************************/
/*******************************************************************************/

/*************RADIUS************/

export const RADIUS = "RADIUS";
export const isActualRadius = () => localStorage.getItem(RADIUS) !== null;
export const getActualRadius = () => localStorage.getItem(RADIUS);
export const setActualRadius = (radius) => localStorage.setItem(RADIUS, radius);
export const remActualRadius = () => localStorage.removeItem(RADIUS);

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  RADIUS **********************************************************/

/* #region  METODOS IMPORTANTES ************************************************/
/*******************************************************************************/
/*******************************************************************************/

export const remAll = () => {
  console.log("%c#...[auth.js] ELIMINANDO TODA LA INFORMACION GUARDADA", globales.consoleStyleStorageDelete);
  localStorage.removeItem(CIUDAD_LOCATION);
  localStorage.removeItem(STATE_LOCATION);
  localStorage.removeItem(INFO_USER);
  localStorage.removeItem(POINTS_CITY);
  localStorage.removeItem(NEARBY_OFFERS);
  localStorage.removeItem(NEARBY_MESSAGES);
  localStorage.removeItem(GEO_LOCATION_LAT);
  localStorage.removeItem(GEO_LOCATION_LNG);
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(RADIUS);
};

export const getAll = () => {
  console.log("%c#...[auth.js] OBTENIENDO TODA LA INFORMACION GUARDADA", globales.consoleStyleStorageGet);
  localStorage.getItem(CIUDAD_LOCATION);
  localStorage.getItem(STATE_LOCATION);
  localStorage.getItem(INFO_USER);
  localStorage.getItem(POINTS_CITY);
  localStorage.getItem(NEARBY_OFFERS);
  localStorage.getItem(NEARBY_MESSAGES);
  localStorage.getItem(GEO_LOCATION_LAT);
  localStorage.getItem(GEO_LOCATION_LNG);
  localStorage.getItem(TOKEN);
  localStorage.getItem(RADIUS);
};

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  METODOS IMPORTANTES *********************************************/