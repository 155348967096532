import React, { Component } from "react";
// Import Materialize
import M from "materialize-css";
import globales from "../../config/config";
import ProfileService from "../../services/ProfileService";
import { Link } from "react-router-dom";
import CircularSmall from "../preloader/CircularSmall";
//import CardOrderVer from "../cards/CardOrderVer";
import { auth } from "../../config/firebase";
import { fn_strip_quotes, fn_set_cart } from '../../misc/sc_misc';

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgressBarOnProgress: true,
      info_profile: {},
    };
  }

  componentDidMount() {
    // Auto initialize all the things!
    M.AutoInit();
    console.log("%c...[Profile.js] ESTAS EN /tuperfil", globales.consoleStyle);
    this.getProfile(
      "eatc-email,eatc-user_name,eatc-name,eatc-doc_id,eatc-address,eatc-phone",
      "eatc-code",
      "7777"
    );
  }

  redirectToTarget = (target) => {
    console.log("...[Profile.js] REDIRIGIR A ", target);
    this.props.history.push(target);
  };

  getProfile = (fieldname, filterfield_1, filtervalue_1) => {
    console.log("...[Profile.js] GET PROFILE BY FIELDNAME (",
      fieldname,
      ") Y FILTERFIELD (",
      filterfield_1,
      ")  Y FILTERVALUE (",
      filtervalue_1,
      "(ASINCRONO)");

    let that = this;

    ProfileService.getProfile(fieldname, filterfield_1, filtervalue_1)
      .then((state) => {
        console.log("@...[Profile.js] ESTADO OBTENIDA (EXITO)",
          "\n>> state : ", state,
          "\n>> TIPO : ", typeof state);
        this.setState({
          info_profile: state,
          showProgressBarOnProgress: !this.state.showProgressBarOnProgress,
        });

        console.log("@...[Profile.js] state", state);
        console.log("@...[Profile.js] this.state.info_profile", this.state.info_profile);
      })
      .catch((err) => {
        console.log("@...[Profile.js] CITY (ERROR) -> ", err);
        console.log("@...[Profile.js] CITY (ERROR.RESPONSE) -> ", err.response);
        console.log("@...[Profile.js] CITY (ERROR.RESPONSE.STATUS) -> ", err.response.status);
        console.log("@...[Profile.js] CITY (ERROR.RESPONSE.STATUSTEXT) -> ", err.response.statusText);

        this.setState({
          showProgressBarOnProgress: !this.state.showProgressBarOnProgress,
          info_profile: {},
        });

        if (err.response.statusText.toLowerCase() === "timeout" || err.response.status === 403) {
          console.log("%c@...[Profile.js] CITY ( TOAST ) -> ", globales.consoleStyle);
          M.toast({
            html:
              "Tiempo de respuesta agotado para obtener la localización, Reintentando ...",
            displayLength: 3000,
            completeCallback: function () {
              that.getProfile(fieldname, filterfield_1, filtervalue_1);
            },
          });
        }
      });
  };

  fn_logout = async () => {
      await auth.signOut();
      fn_set_cart('user', {});
      localStorage.setItem('USER', null);
      this.props.history.push('/signin');
  }

  renderInfoProfile() {
    if(Object.entries(this.state.info_profile).length !== 0) {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <i className="large material-icons circle responsive-img">
                account_circle
              </i>
              <span className="precio-oficial">
                {fn_strip_quotes(this.state.info_profile["eatc-name"])}
              </span>
              <span className="card-title-med">
                {this.state.info_profile["eatc-phone"]}
              </span>
              <span className="card-title-med">
                {fn_strip_quotes(this.state.info_profile["eatc-email"])}
              </span>
            </div>
          </div>
        </div>
      );
    }else{
      return (<div className="card horizontal">
                <div className="card-stacked">
                  <div className="card-content">
                    <div>Información no disponible</div>
                  </div>
                </div>
              </div>);
    }
    /* return this.state.infoProfile.map((orders, index) => (
      <CardOrderVer
        //<CardInfoOrderVer
        key={index}
        order={orders}
        order_verification_code={orders["eatc-verification_code"]}
        eatc_pod_name={orders["eatc-pod_name"]}
        eatc_pod_address={orders["eatc-pod_address"]}
        km={orders["km"]}
        eatc_datetime={orders["eatc-datetime"]}
        horario={orders["horario"]}
        eatc_total_price={orders["eatc-total_price"]}
      />
    )); */
  }

  render() {
    //const color = "#00B3FE";
    //const colorWhite = "#FFFFFF";
    const divStyleZ = { zIndex: "1000" };
    const divStyleMTpN24 = { marginTop: "-24px" };
    //const divStyleColorBlack = { color: "#000000", marginLeft: "-5px" };
    const divStyleMBn24 = { marginBottom: "-24px" };
    const divStyleMBn100 = { marginBottom: "100px" };
    //const divStyleWidth50MarginCero = { width: "50%", margin: "0" };
    //const divStyleMTp18 = { marginTop: "18px" };
    const divStyleMTp19MBnN10 = { marginTop: "-19px", marginBottom: "-10px" };
    const divStyleMTp16 = { marginTop: "16px" };
    const divStyleTitle = {
      height: "3rem",
      lineHeight: "3rem",
      width: "90%",
      fontSize: "16px",
      margin: "24px 0 16px 0",
      padding: "0 16px",
      textAlign: "center",
    };
    const divStyleSubTitle = {
      height: "3rem",
      lineHeight: "3rem",
      width: "90%",
      fontSize: "16px",
      margin: "24px 0 16px 0",
      padding: "0 16px",
    };
    const leftCenter = {
      position: "absolute",
      left: "0px",
      width: "100%",
      border: "3px solid #73AD21",
      padding: "10px",
      textAlign: "center"
    };

    return (
      <div className="tinta-bg">
        <div className="blanco-bg shadow-8dp" style={divStyleZ}>
          <div className="row" style={divStyleMBn24}>
            <div className="col s1 m1 l1">
              <div style={divStyleMTp16}>
                <Link
                  to="#"
                  className=""
                  onClick={() => {
                    this.redirectToTargetBack();
                  }}
                >
                  <i className="material-icons">close</i>
                </Link>
              </div>
            </div>
            <div className="col s10 m10 l10">
              {/* style={divStyleMTn19} */}
              <div className="input-field" style={divStyleMTp19MBnN10}>
                <h4
                  className="brand-logo blue-text text-darken-2"
                  style={divStyleTitle}
                >
                  Perfil de usuario
                </h4>
              </div>
            </div>
            <div className="col s1 m1 l1">
              <div style={divStyleMTp16}>
                <Link to="#" className="right">
                  <i className="material-icons">help</i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row" style={divStyleMTpN24}>
            <div className="col s12 m12 l12">
              {this.state.showProgressBarOnProgress && <CircularSmall />}
              {!this.state.showProgressBarOnProgress &&
                this.renderInfoProfile()}
            </div>
          </div>
        </div>
        <div className="blanco-bg shadow-8dp" style={divStyleZ}>
          <div className="row" style={divStyleMBn100}>
            <div className="col s12 m12 l12">
              {/* <div className="input-field" style={divStyleMTp19MBnN10}>
                <h4
                  className="brand-logo blue-text text-darken-2"
                  style={divStyleSubTitle}
                >
                  ENTREGADOS
                </h4>
              </div> */}
              <ul>
                <li className="row brand-logo blue-text text-darken-2"><i className="col material-icons">account_circle</i>Editar tu perfil</li>
                <li className="row brand-logo blue-text text-darken-2"><i className="col material-icons">near_me</i>Dirección para envíos</li>
                <li className="row brand-logo blue-text text-darken-2"><i className="col material-icons">favorite</i>Tus favoritos</li>
                <li className="row brand-logo blue-text text-darken-2"><i className="col material-icons">list</i>Lista de pedidos</li>
                <li className="row brand-logo blue-text text-darken-2"><i className="col material-icons">notifications</i>Configuración de notificaciones</li>
                <li className="row brand-logo blue-text text-darken-2"><i className="col material-icons">payment</i>Métodos de pago</li>
                <li className="row brand-logo blue-text text-darken-2"><i className="col material-icons">gavel</i>Términos y condiciones</li>
                <li className="row brand-logo blue-text text-darken-2"><i className="col material-icons">help</i>Ayuda</li>
                <li className="row brand-logo blue-text text-darken-2" onClick={() => {this.fn_logout();}}><i className="col material-icons">power_settings_new</i>Salir</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
