import axios from 'axios';
import glb from '../../config/config';
import { fn_genera_token, fn_trae_fecha_hora } from '../../misc/sc_misc';
import M from 'materialize-css';

// estado inicial
const detprod_dataini = {
    accion: null,
    verbtnagr: false,
    id_order: null,
    cantadded: null,
    cant: null,
    cantorig: null,
    stock: null,
    carro_pod_id: null
};

// types
const TRAER_CARRO_PRODS = 'TRAER_CARRO_PRODS';
const TRAER_DATOS_PRODUCTO = 'TRAER_DATOS_PRODUCTO';
const TRAER_DATOS_ORDEN = 'TRAER_DATOS_ORDEN';
const TRAER_DATOS_STOCK = 'TRAER_DATOS_STOCK';
const CAMBIA_VER_BTNAGR = 'CAMBIA_VER_BTNAGR';
const CAMBIA_VALOR_CANT = 'CAMBIA_VALOR_CANT';
const INSERTA_VALOR_ORDEN = 'INSERTA_VALOR_ORDEN';
const EDITA_VALOR_ORDEN = 'EDITA_VALOR_ORDEN';
const BORRA_VALOR_ORDEN = 'BORRA_VALOR_ORDEN';
// const VERF_CARRO_ACTIVO = 'VERF_CARRO_ACTIVO';

// reducer 
export default function detprodReducer(state = detprod_dataini, action) {
    switch (action.type) {
        case TRAER_CARRO_PRODS:
            return { ...state, carro_pod_id: action.payload };
        case TRAER_DATOS_PRODUCTO:
            return { ...state, prod: action.payload };
        case TRAER_DATOS_ORDEN:
        case TRAER_DATOS_STOCK:
        case CAMBIA_VER_BTNAGR:
        case CAMBIA_VALOR_CANT:
            return { ...state, ...action.payload };
        case INSERTA_VALOR_ORDEN:
        case EDITA_VALOR_ORDEN:
        default:
            return state;
    }
}


/**************** ACCIONES ******************/

// accion para traer el actual estado del carro de compras (si lo hay)
export const fn_traeCarroProds = () => async (dispatch) => {
    console.log(">>> Trayendo los datos del carro de compras actual");
    let pod_id = null;
    try {
        const rs = await axios.get(`${glb._benf}${glb._api}${glb._user}eatc_sale_order?eatc-user_code=${ localStorage.getItem("TOKEN") }&eatc-odd_state=ordered,partially_ordered,declined`);
        console.log(">>> datos carro comp: ", rs.data.res);        
        rs.data.res.forEach(item => {            
            pod_id = item["eatc-pod_id"];
        });
        dispatch({
            type: TRAER_CARRO_PRODS,
            payload: pod_id
        });
    } catch (error) {
        console.error("Error fn_traeCarroProds: ", error.response.data);
        dispatch({
            type: TRAER_CARRO_PRODS,
            payload: pod_id
        });
    }
};


// accion para traer los datos de un producto
export const fn_traeDatosProd = (idprod) => async (dispatch) => {
    console.log(">>> trayendo datos ppales prod " + idprod);
    // localStorage.setItem("datoprod", JSON.stringify(location.state));
    try {
        const rs = await axios.get(`${glb._dona}${glb._api}${glb._user}eatc_sale?eatc-code=${idprod}`);
        console.log(rs.data)
        // set_Prod(producto.res[0]);
        dispatch({
            type: TRAER_DATOS_PRODUCTO,
            payload: rs.data.res[0]
        });
    } catch (error) {
        console.error("Error fn_traeDatosProd: ", error.response.data);
    }
};

// accion para traer los datos de la orden si existe y si tiene o no header code.
export const fn_traeDatosOrden = (idprod) => async (dispatch, getState) => {
    const { prod, carro_pod_id } = getState().detprod_state;
    console.log(">>> trayendo datos orden codigo 7777 id " + idprod);
    console.log("estado: ", getState().detprod_state);
    try {
        let token = localStorage.getItem('TOKEN');
        const rs = await axios.get(`${glb._benf}${glb._api}${glb._user}eatc_sale_order?eatc-sale_code=${idprod}&eatc-user_code=${token}`);
        console.log(rs.data);
        // averiguamos si existe header en la lista de ordenes
        let stord_noheader = rs.data.res.filter(stonh => stonh["eatc-sale_order_header_code"] === "");
        console.log("stord_noheader: ", stord_noheader);
        // si no hay orden abierta se debe proceder a insertar el valor
        if (stord_noheader.length === 0) {
            dispatch({
                type: TRAER_DATOS_ORDEN,
                payload: {
                    accion: 'insert',
                    verbtnagr: true,
                    id_order: null,
                    cantadded: null,
                    cant: null,
                    cantorig: null,
                    stock: null,
                    prod,
                    carro_pod_id
                }
            });
        } else {
            // si hay orden abierta se trae la cantidad para ponerla en el campo de cantidad.
            dispatch({
                type: TRAER_DATOS_ORDEN,
                payload: {
                    accion: 'update',
                    verbtnagr: false,
                    id_order: stord_noheader[0]["_id"],
                    cantadded: Number(stord_noheader[0]["eatc-odd_quantity"]),
                    cant: Number(stord_noheader[0]["eatc-odd_quantity"]),
                    cantorig: null,
                    stock: null,
                    prod,
                    carro_pod_id
                }
            });
        }
    } catch (error) {
        console.error("error fn_traeDatosOrden: ", error.response.data);
        dispatch({
            type: TRAER_DATOS_ORDEN,
            payload: {
                accion: 'insert',
                verbtnagr: true,
                id_order: null,
                cantadded: null,
                cant: null,
                cantorig: null,
                stock: null,
                prod,
                carro_pod_id
            }
        });
    }
};

// Accion para traer el stock actual disponible para el producto 
export const fn_traeDatoStock = (idprod) => async (dispatch, getState) => {
    const { accion, verbtnagr, id_order, cantadded, cant, prod, carro_pod_id } = getState().detprod_state;
    console.log("prod: " + prod);
    console.log(">>> trayendo dato del stock prod " + idprod);
    try {
        const rs = await axios.get(`${glb._dona}${glb._api}${glb._user}eatc_sale?eatc-code=${idprod}`);
        console.log(rs.data);
        if (rs.data.cont >= 1) {
            dispatch({
                type: TRAER_DATOS_STOCK,
                payload: {
                    accion,
                    verbtnagr,
                    id_order,
                    cantadded,
                    cant,
                    cantorig: Number(rs.data.res[0]["eatc-odd_original_quantity"]),
                    stock: Number(rs.data.res[0]["eatc-odd_quantity"]),
                    prod,
                    carro_pod_id
                }
            });
        }
    } catch (error) {
        console.error("Error fn_traeDatoStock: ", error.response.data);
    }
};

// accion para cambiar el valor de visualizacion del boton "agregar el carrito"
export const fn_cambiaVerBtnAgr = () => (dispatch, getState) => {
    const { accion, verbtnagr, id_order, cantadded, cantorig, stock, prod, carro_pod_id } = getState().detprod_state;
    const cambia_verbtnagr = !verbtnagr;
    dispatch({
        type: CAMBIA_VER_BTNAGR,
        payload: {
            accion,
            verbtnagr: cambia_verbtnagr,
            id_order,
            cantadded,
            cant: 1,
            cantorig,
            stock,
            prod,
            carro_pod_id
        }
    });
};

// accion para cambiar el valor de la cantidad del producto en eatc-sale
export const fn_cambiaOddPrdVal = (idprod, tipo) => async (dispatch, getState) => {
    const { accion, verbtnagr, id_order, cantadded, cant, cantorig, stock, prod, carro_pod_id } = getState().detprod_state;
    console.log(">>> modificando el valor de odd_quantity en eatc-sale para el idprod: " + idprod);  
    if ((stock === 0) && (cant <= 0)) {
        dispatch({
            type: CAMBIA_VALOR_CANT,
            payload: {
                accion,
                verbtnagr,
                id_order,
                cantadded,
                cant,
                cantorig,
                stock,
                prod,
                carro_pod_id
            }
        });
    } else {
        try {
            // analizamos la cantidad y el stock actual
            let odd_quantity = stock;
            let act_cant = cant;
            let eatc_sale_typupd = 'ordered';
            if (tipo === 'suma') {
                eatc_sale_typupd = (stock === 1) ? 'ordered' : 'partially_ordered';
                act_cant++;
                odd_quantity--;        
            } else if (tipo === 'resta') {
                eatc_sale_typupd = (cantorig-stock) > 1 ? 'partially_ordered' : 'sale';
                act_cant--;
                odd_quantity++;            
            } else if (tipo === 'nuevo') {
                eatc_sale_typupd = (stock === 1) ? 'ordered' : 'partially_ordered';
                act_cant = 1;
                odd_quantity--;
            }
            M.Toast.dismissAll();
            M.toast({ html: `${tipo} cantidad` });
            // llamando el api crd para modificar el odd_quantity
            let token = localStorage.getItem('TOKEN');
            const rs = await axios.get(`${glb._dona}${glb._crd}${glb._user}?_tabla=eatc_sale&_operacion=update&eatc-user_code=${token}&eatc-odd_quantity=${odd_quantity}&eatc-odd_state=${eatc_sale_typupd}&WHEREeatc-code=${idprod}`);
            console.log(rs.data);
            if (rs.data.cont === 0) {
                M.Toast.dismissAll();
                M.toast({ html: `No se pudo actualizar la cantidad al eatc-sale` });
            } else {
                let verbotonagr = (act_cant === 0) ? true : false;
                dispatch({
                    type: CAMBIA_VALOR_CANT,
                    payload: {
                        accion,
                        verbtnagr: verbotonagr,
                        id_order,
                        cantadded,
                        cant: act_cant,
                        cantorig,
                        stock: odd_quantity,
                        prod,
                        carro_pod_id
                    }
                });
            }
        } catch (error) {
            console.error("Error fn_cambiaOddPrdVal: ", error.response.data);
        }
    }  
};

// funcion para insertar un nuevo valor de producto y cantidad en la creacion de una orden 
export const fn_insertRegOrden = (idprod) => async(dispatch, getState) => {
    const { accion, verbtnagr, id_order, cantadded, cant, cantorig, stock, prod, carro_pod_id } = getState().detprod_state;
    console.log({ prd: prod['eatc-code'], cant });
    let pldata = {};
    try {
        let token = localStorage.getItem('TOKEN');
        let code_token = fn_genera_token(24);
        let fecha_hora = fn_trae_fecha_hora();
       // calculos de algunos parametros
        let odd_total_weight_kg = Number(cant) * Number(prod["eatc-odd_unit_weight_kg"]);
        let odd_original_total_price = Number(cant) * Number(prod["eatc-odd_min_sale_unit_price"]);
        let odd_total_price = Number(cant) * Number(prod["eatc-odd_min_sale_unit_price"]);        
        let odd_original_total_weight_kg = Number(cant) * Number(prod["eatc-odd_unit_weight_kg"]);
        let original_vat = ( ( Number(prod["eatc-odd_unit_price"]) * Number(cant) ) / ( 1+(Number(prod["eatc_vat_percentage"])/100) ) )*(Number(prod["eatc_vat_percentage"])/100);
        let total_vat = ( (Number(prod["eatc-odd_unit_price"]) * cant) / ( 1+(Number(prod["eatc_vat_percentage"])/100)) ) * (Number(prod["eatc_vat_percentage"]/100));
        let original_total_other_taxes = ((Number(prod["eatc-odd_unit_price"]) * Number(cant)) / (1+(prod["eatc-other_taxes_percentage"]/100)) ) * Number(prod["eatc-other_taxes_percentage"]);
        let total_other_taxes = ((Number(prod["eatc-odd_unit_price"]) * cant) / (1+(Number(prod["eatc-other_taxes_percentage"])/100))) * Number(prod["eatc-other_taxes_percentage"]);
        let original_vat_base = ( Number(prod["eatc-odd_unit_price"]) * Number(cant) ) / ( 1+(Number(prod["eatc_vat_percentage"])/100) );
        let vat_base = (Number(prod["eatc-odd_unit_price"]) * cant) / ( 1+(Number(prod["eatc_vat_percentage"])/100) );
        let original_other_taxes_base = ( Number(prod["eatc-odd_unit_price"]) * Number(cant) ) / (1+(prod["eatc-other_taxes_percentage"]/100));
        let other_taxes_base = (Number(prod["eatc-odd_unit_price"]) * cant) / (1+(Number(prod["eatc-other_taxes_percentage"])/100));
        console.log({ odd_total_price });
        // cadena de parámetros
        let qprms = `_tabla=eatc_sale_order&` 
                    + `_operacion=insert&` 
                    + `eatc-code=${code_token}&` 
                    + `eatc-sale_order_header_code=&`
                    + `eatc-doc=&`
                    + `eatc-date_time=${fecha_hora}&`
                    + `eatc-date_time_2=${fecha_hora.substr(0,10)}&`
                    + `eatc-odd_id=${encodeURIComponent(prod["eatc-odd_id"])}&`
                    + `eatc-odd_name=${encodeURIComponent(prod["eatc-odd_name"])}&`
                    + `eatc-odd_description=${encodeURIComponent(prod["eatc-odd_description"])}&`
                    + `eatc-odd_original_quantity=${cant}&`
                    + `eatc-odd_quantity=${cant}&`
                    + `eatc-pod_id=${prod["eatc-pod_id"]}&`
                    + `eatc-pod_name=${encodeURIComponent(prod["eatc-pod_name"])}&`
                    + `eatc-pod_address=${encodeURIComponent(prod["eatc-pod_address"])}&`
                    + `eatc-pod_lat=${prod["eatc-pod_lat"]}&`
                    + `eatc-pod_lon=${prod["eatc-pod_lon"]}&`
                    + `eatc-pod_city=${encodeURIComponent(prod["eatc-pod_city"])}&`
                    + `eatc-pod_country=${prod["eatc-pod_country"]}&`
                    + `eatc_donor_code=${prod["eatc_donor_code"]}&`
                    + `eatc_donor=${encodeURIComponent(prod["eatc_donor"])}&`
                    + `eatc-odd_unit_weight_kg=${prod["eatc-odd_unit_weight_kg"]}&`
                    + `eatc-odd_total_weight_kg=${odd_total_weight_kg}&`
                    + `eatc-odd_unit_full_price=${prod["eatc-odd_unit_price"]}&`
                    + `eatc-odd_sale_unit_price=${prod["eatc-odd_min_sale_unit_price"]}&`
                    + `eatc-odd_discount=${prod["eatc-odd_max_discount"]}&`
                    + `eatc-odd_original_total_price=${odd_original_total_price}&`
                    + `eatc-odd_total_price=${odd_total_price}&`
                    + `eatc_vat_percentage=${prod["eatc_vat_percentage"]}&`
                    + `eatc-original_vat=${original_vat}&`
                    + `eatc-total_vat=${total_vat}&`
                    + `eatc-other_taxes_percentage=${prod["eatc-other_taxes_percentage"]}&`
                    + `eatc-original_total_other_taxes=${original_total_other_taxes}&`
                    + `eatc-total_other_taxes=${total_other_taxes}&`
                    + `eatc-odd_state=ordered&`
                    + `eatc-odd_rejection_cause=&`
                    + `eatc-warning=${prod["eatc-warning"]}&`
                    + `eatc-contains_alergens_=${prod["eatc-contains_alergens"]}&`
                    + `eatc-closer_expiration_date=${prod["eatc-closer_expiration_date"]}&`
                    + `eatc-sale_code=${prod["eatc-code"]}&`
                    + `eatc-user_code=${token}&`
                    + `eatc-pod_province=${prod["eatc-pod_province"]}&`
                    + `eatc-odd_original_total_weight_kg=${odd_original_total_weight_kg}&`
                    + `eatc-original_vat_base=${original_vat_base}&`
                    + `eatc-vat_base=${vat_base}&`
                    + `eatc-original_other_taxes_base=${original_other_taxes_base}&`
                    + `eatc-other_taxes_base=${other_taxes_base}&`
                    + `eatc-odd_rejection_cause_code=&`
                    + `eatc-refund_quantity=&`
                    + `eatc-refund_amount=&`
                    + `eatc_cua_origin=${prod["eatc_cua_origin"]}&`
                    + `eatc-odd_image=${prod["eatc-odd_image"]}&`
                    + `eatc-offer_lifetime=${prod["eatc-offer_lifetime"]}&`
                    + `eatc-offer_lifetime_until=${prod["eatc-offer_lifetime_until"]}`;  
        // realizamos la publicación del nuevo registro via axios
        const rs = await axios.post(`${glb._benf}${glb._crd}${glb._user}?${qprms}`);       
        if (rs.data.cont === 0) {
            M.Toast.dismissAll();
            M.toast({ html: `No se pudo crear el registro de orden para el producto ${idprod}...`});
            pldata = { accion, verbtnagr, id_order, cantadded, cant, cantorig, stock, prod, carro_pod_id };
        } else {
            console.log("Registro agregado a la orden");
            pldata = { accion, verbtnagr, id_order, cantadded, cant, cantorig, stock, prod, carro_pod_id };            
        }
        // generando el dispatch
        dispatch({ type: INSERTA_VALOR_ORDEN, payload: pldata });
    } catch (error) {
        console.error("error fn_insertRegOrden: ", error.response.data);
        pldata = { accion, verbtnagr, id_order, cantadded, cant, cantorig, stock, prod, carro_pod_id };
        // generando el dispatch
        dispatch({ type: INSERTA_VALOR_ORDEN, payload: pldata });
    }    
}; 

// funcion para actualizar la orden del registro
export const fn_updateRegOrden = () => async(dispatch, getState) => {
    const { accion, verbtnagr, id_order, cantadded, cant, cantorig, stock, prod, carro_pod_id } = getState().detprod_state;
    let pldata = {};
    let token = localStorage.getItem('TOKEN');
    try {
        // parametros
        let odd_original_total_weight_kg = Number(cantorig) * Number(prod["eatc-odd_unit_weight_kg"]);
        let odd_total_weight_kg = Number(cant) * Number(prod["eatc-odd_unit_weight_kg"]);
        let odd_original_total_price = Number(cantorig) * Number(prod["eatc-odd_min_sale_unit_price"]);
        let odd_total_price = Number(cant) * Number(prod["eatc-odd_min_sale_unit_price"]);
        let vat_percentage = prod["eatc_vat_percentage"];
        let original_vat_base = (Number(prod["eatc-odd_unit_price"]) * Number(cant) ) / ( 1+(Number(prod["eatc_vat_percentage"])/100) );
        let original_vat = original_vat_base * (Number(prod["eatc_vat_percentage"])/100);
        let vat_base = (Number(prod["eatc-odd_unit_price"]) * cant) / (1+(Number(prod["eatc_vat_percentage"])/100) );
        let total_vat = vat_base * (Number(prod["eatc_vat_percentage"])/100);
        let other_taxes_percentage = prod["eatc-other_taxes_percentage"];
        let original_other_taxes_base = (Number(prod["eatc-odd_unit_price"]) * Number(cantorig)) / (1+(prod["eatc-other_taxes_percentage"]/100));
        let original_total_other_taxes = original_other_taxes_base * Number(prod["eatc-other_taxes_percentage"]);
        let other_taxes_base = (Number(prod["eatc-odd_unit_price"]) * cant) / (1+(Number(prod["eatc-other_taxes_percentage"])/100));
        let total_other_taxes = other_taxes_base * Number(prod["eatc-other_taxes_percentage"]);
        // los parámetros
        let qprms = `_tabla=eatc_sale_order&`
                    + `_operacion=update&`
                    + `eatc-odd_original_quantity=${cant}&`
                    + `eatc-odd_quantity=${cant}&`
                    + `eatc-odd_original_total_weight_kg=${odd_original_total_weight_kg}&`
                    + `eatc-odd_total_weight_kg=${odd_total_weight_kg}&`
                    + `eatc-odd_original_total_price=${odd_original_total_price}&`
                    + `eatc-odd_total_price=${odd_total_price}&`
                    + `eatc-vat_percentage=${vat_percentage}&`
                    + `eatc-original_vat_base=${original_vat_base}&`
                    + `eatc-original_vat=${original_vat}&`
                    + `eatc-vat_base=${vat_base}&`
                    + `eatc-total_vat=${total_vat}&`
                    + `eatc-other_taxes_percentage=${other_taxes_percentage}&`
                    + `eatc-original_other_taxes_base=${original_other_taxes_base}&`
                    + `eatc-original_total_other_taxes=${original_total_other_taxes}&`
                    + `eatc-other_taxes_base=${other_taxes_base}&`
                    + `eatc-total_other_taxes=${total_other_taxes}&`
                    + `eatc-user_code=${token}&`
                    + `WHERE_id=${id_order}`;
        // actualizamos el registro 
        const rs = await axios.post(`${glb._benf}${glb._crd}${glb._user}?${qprms}`); 
        if (rs.data.cont === 0) {
            M.Toast.dismissAll();
            M.toast({ html: `No se pudo actualizar el registro de orden para el registro ${id_order}...`});
            pldata = { accion, verbtnagr, id_order, cantadded, cant, cantorig, stock, prod, carro_pod_id };
        } else {
            console.log("Registro de orden actualizado");
            pldata = { accion, verbtnagr, id_order, cantadded, cant, cantorig, stock, prod, carro_pod_id };            
        }
        // generando el dispatch
        dispatch({ type: EDITA_VALOR_ORDEN, payload: pldata });
    } catch (error) {
        console.error("error fn_updateRegOrden: ", error.response.data);
        // generando el dispatch
        dispatch({ type: EDITA_VALOR_ORDEN, payload: pldata });
    }
};

// funcion para eliminar la orden del registro cuando se seleccionan cero cantidades a una registro de orden existente
export const fn_deleteRegOrden = () => async(dispatch, getState) => {
    const { accion, verbtnagr, id_order, cantadded, cant, cantorig, stock, prod, carro_pod_id } = getState().detprod_state;
    let pldata = {};
    try {
        let qprms = `_tabla=eatc_sale_order&_operacion=delete&WHERE_id=${id_order}`;
        // borramos el registro
        const rs = await axios.post(`${glb._benf}${glb._crd}${glb._user}?${qprms}`);
        if (rs.data.cont === 0) {
            M.Toast.dismissAll();
            M.toast({ html: `No se pudo actualizar el registro de orden para el registro ${id_order}...`});
            pldata = { accion, verbtnagr, id_order, cantadded, cant, cantorig, stock, prod, carro_pod_id };
        } else {
            console.log("Registro de orden eliminado");
            pldata = { accion, verbtnagr, id_order, cantadded, cant, cantorig, stock, prod, carro_pod_id };
        }
        // generando el dispatch
        dispatch({ type: BORRA_VALOR_ORDEN, payload: pldata });
    } catch (error) {
        console.error("error fn_deleteRegOrden: ", error.response.data);
        // generando el dispatch
        dispatch({ type: BORRA_VALOR_ORDEN, payload: pldata });
    }
};