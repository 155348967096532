import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import "materialize-css/dist/css/materialize.css";
import "./css/eatcloud-ui.css";
import Splashscreen from './components/splashscreen/Splashscreen';
import Detalleprod from './components/detalleprod/Detalleprod';
import Checkout from './components/checkout/Checkout';
import SignIn from './components/login/SignIn';
import SignUp from './components/login/SignUp';
import PasswordReset from './components/login/PasswordReset';
import Listaprods from './components/listaprods/Listaprods';
import Carrocompras from './components/carrocompras/Carrocompras';
import OnBoarding from './components/onboarding/OnBoarding';
//import OffBoarding from './components/onboarding/OffBoarding';
import Dashboard from './components/dashboard/Dashboard';
import ListaProductos from './components/listaprods/ListaProductos';
import CarritoCompras from './components/carrocompras/CarritoCompras';
import DashboarSalePoint from './components/dashboard/DashboarSalePoint';
import YourOrders from './components/yourorders/YourOrders';
import Profile from './components/profile/Profile';
import TestingFooter from './components/testing/TestingFooter';

const history = createBrowserHistory();    

function Application() {    

    return (
        <Router history={history}>
            <Switch>
                <Route path='/' exact component={Splashscreen} />
                {/* <Route path={`${process.env.PUBLIC_URL}/`} exact component={Splashscreen} /> */}
                <Route path='/onboarding' component={OnBoarding} />
                {/* <Route path='/offboarding' component={OffBoarding} /> */}
                <Route path='/dashboard' component={Dashboard} />
                <Route path='/dashboardsale' component={DashboarSalePoint} />
                <Route path='/listaproductos' component={ListaProductos} />                
                <Route path='/carritocompras' component={CarritoCompras} />
                <Route path='/checkout' component={Checkout} />
                <Route path='/signin' component={SignIn} />
                <Route path='/signup' component={SignUp} />
                <Route path='/passwordreset' component={PasswordReset} />
                <Route path='/tuspedidos' component={YourOrders} />
                <Route path='/tuperfil' component={Profile} />
                <Route path='/testingfooter' component={TestingFooter} />
                {/* <Route path='*' component={not_found} /> */}
                {/* <Route path='/bienvenida1' component={eatc_alim_bienv1} />
                    <Route path='/bienvenida2' component={eatc_alim_bienv2} />
                    <Route path='/bienvenida3' component={eatc_alim_bienv3} />
                    <Route path='/bienvenida4' component={eatc_alim_bienv4} />
                    <Route path='/dashboard' component={eatc_alim_dsh} />
                    <Route path='/donacion_alimentos' component={eatc_alim_dona} />
                    <Route path='/voluntarios' component={eatc_alim_vol} />
                    <Route path='/dinero' component={eatc_alim_dona_mon} />
                    <Route path='/terminos' component={eatc_alim_terms} />
                    <Route path='/login' component={eatc_alim_aut} />
                    <Route path='/registro' component={eatc_alim_reg} />
                    <Route path='/codigo' component={eatc_alim_dona_cod} />
                    <Route path='*' component={not_found} /> */}
                <Route path="/listaprods">
                    <Listaprods />
                </Route>
                <Route path="/carrocompras">
                    <Carrocompras />
                </Route>
                <Route path="/detalleprod/:idprod">
                    <Detalleprod />
                </Route>           
           </Switch>
        </Router>
    )
}

export default Application;