import axios from 'axios';
import glb from '../../config/config';
import M from 'materialize-css';
import { fn_set_cart, fn_get_cart } from '../../misc/sc_misc';

// estado inicial
const carrocomp_dataini = {
    cont: 0,
    act_id_reg: null,
    act_cant_prod: null,
    act_stock_prod: null,   
    lstprods: [],
    totales: {}
};

// types
const TRAER_PRODS_ORDEN = 'TRAER_PRODS_ORDEN';
const TRAER_PROD_STOCK = 'TRAER_PROD_STOCK';
const ACTUALIZA_CANT_SALE = 'ACTUALIZA_CANT_SALE';
const ACTUALIZA_CANT_ORDEN = 'ACTUALIZA_CANT_ORDEN';
const BORRA_PROD_DE_ORDEN = 'BORRA_PROD_DE_ORDEN';

// reducer 
export default function carrocompReducer(state = carrocomp_dataini, action) {
    switch (action.type) {
        case TRAER_PRODS_ORDEN:
        case TRAER_PROD_STOCK:
        case ACTUALIZA_CANT_SALE:
        case ACTUALIZA_CANT_ORDEN:
        case BORRA_PROD_DE_ORDEN:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

/**************** ACCIONES ***************    Prueba   ***/

// accion para traer los datos de un producto
export const fn_traeProdsOrden = () => async (dispatch, getState) => {    
    console.log(">>> trayendo los productos de la orden ");
    const { act_id_reg, act_cant_prod, act_stock_prod, lstprods, totales } = getState().carrocomp_state;
    // localStorage.setItem("datoprod", JSON.stringify(location.state));
    try {
        let token = localStorage.getItem('TOKEN');
        console.log({ "url_sale_order": `${glb._benf}${glb._api}${glb._user}eatc_sale_order?eatc-user_code=${token}&eatc-odd_state=ordered,partially_ordered,declined` });
        const rs = await axios.get(`${glb._benf}${glb._api}${glb._user}eatc_sale_order?eatc-user_code=${token}&eatc-odd_state=ordered,partially_ordered,declined`);
        console.log(rs.data);
        // ahora traemos los totales de la lista de los productos
        let subtotal = 0;
        let totdcto = 0;
        let totiva = 0;
        let total = 0;
        rs.data.res.forEach(item => {            
            subtotal += Number(item["eatc-odd_original_total_price"]);
            totdcto += Number(item["eatc-odd_discount"]);
            totiva += Number(item["eatc-total_vat"]);
            total += (Number(item["eatc-odd_original_total_price"]) - Number(item["eatc-odd_discount"]) + Number(item["eatc-total_vat"]))                           
        });

        fn_set_cart("total", total);
        fn_set_cart("detail", rs.data.res);
        
        dispatch({
            type: TRAER_PRODS_ORDEN,
            payload: {
                cont: rs.data.cont,
                act_id_reg: null,
                act_cant_prod: null,
                act_stock_prod: null,
                lstprods: rs.data.res,
                totales: { subtotal, totdcto, totiva, total }
            }
        });
    } catch (error) {
        console.error("Error fn_traeProdsOrden: ", error.response.data);
        dispatch({
            type: TRAER_PRODS_ORDEN,
            payload: { 
                cont: 0,
                act_id_reg,
                act_cant_prod,
                act_stock_prod,
                lstprods, 
                totales
            }
        });
    }
};

// Accion para traer el stock actual disponible para el producto 
export const fn_traeProdStock = (id_reg) => async (dispatch, getState) => {
    const { cont, lstprods, totales } = getState().carrocomp_state;
    console.log("idprod: " + id_reg);
    // traemos los datos del actual producto de la lista de productos para capturar los datos de ventas e impuestos
    let prod = lstprods.filter(item => item["_id"] === id_reg);
    let eatc_code = prod[0]["eatc-sale_code"];
    console.log(">>> trayendo dato del stock prod " + id_reg);
    try {
        const rs = await axios.get(`${glb._dona}${glb._api}${glb._user}eatc_sale?eatc-code=${eatc_code}`);
        console.log(rs.data);
        if (rs.data.cont >= 1) {
            dispatch({
                type: TRAER_PROD_STOCK,
                payload: {
                    cont,
                    act_id_reg: id_reg,
                    act_cant_prod: Number(prod[0]["eatc-odd_quantity"]),
                    act_stock_prod: Number(rs.data.res[0]["eatc-odd_quantity"]),
                    lstprods,
                    totales
                }
            });
        }
    } catch (error) {
        console.error("Error fn_traeProdStock: ", error.response.data);
    }
};

// accion para actualizar el valor del stock del producto en el inventario general
export const fn_cambiaInvPrd = (id_reg, tipo) => async (dispatch, getState) => {
    console.log("Actualizando el stock en el eatc-sale " + id_reg + ", tipo: " + tipo);
    const { cont, act_id_reg, act_cant_prod, act_stock_prod, lstprods, totales } = getState().carrocomp_state;
    let prod = lstprods.filter(item => item["_id"] === id_reg);
    let eatc_code = prod[0]["eatc-sale_code"];
    try {
        // analizamos la cantidad y el stock actual
        let newStock = act_stock_prod;
        let actCant = act_cant_prod; 
        let oddState = '';     
        // si el stock actual es mayor a cero procedemos a cambiar el stock en eatc-sale
        if (act_stock_prod > 0) {   
            if (tipo === 'suma') {                
                actCant++;
                newStock--;
                oddState = (newStock === 0) ? 'ordered' : 'partially_ordered';
            } else if (tipo === 'resta') {
                actCant--;
                newStock++; 
                oddState = 'partially_ordered';          
            }
            M.Toast.dismissAll();
            M.toast({ html: `${tipo} cantidad` });
            // llamando el api crd para modificar el odd_quantity
            const rs = await axios.get(`${glb._dona}${glb._crd}${glb._user}?_tabla=eatc_sale&_operacion=update&eatc-odd_quantity=${newStock}&eatc-odd_state=${oddState}&WHEREeatc-code=${eatc_code}`);
            console.log(rs.data);
            if (rs.data.cont === 0) {
                M.Toast.dismissAll();
                M.toast({ html: `No se pudo actualizar la cantidad al eatc-sale` });
            } else {
                dispatch({
                    type: ACTUALIZA_CANT_SALE,
                    payload: { 
                        cont, 
                        act_id_reg, 
                        act_cant_prod: actCant, 
                        act_stock_prod: newStock, 
                        lstprods,
                        totales 
                    }
                });
            }
        } else if (tipo === 'resta') {   // si el stock es cero se puede disminuir la cantidad 
            actCant--;
            newStock++;
            oddState = 'partially_ordered';
            M.Toast.dismissAll();
            M.toast({ html: `${tipo} cantidad` });
            // llamando el api crd para modificar el odd_quantity
            const rs = await axios.get(`${glb._dona}${glb._crd}${glb._user}?_tabla=eatc_sale&_operacion=update&eatc-odd_quantity=${newStock}&eatc-odd_state=${oddState}&WHEREeatc-code=${eatc_code}`);
            console.log(rs.data);
            if (rs.data.cont === 0) {
                M.Toast.dismissAll();
                M.toast({ html: `No se pudo actualizar la cantidad al eatc-sale` });
            } else {
                dispatch({
                    type: ACTUALIZA_CANT_SALE,
                    payload: { 
                        cont, 
                        act_id_reg, 
                        act_cant_prod: actCant, 
                        act_stock_prod: newStock, 
                        lstprods,
                        totales
                    }
                });
            }
        } else {// si el stock actualmente es cero se debe avisar que no se puede agregar mas unidades.
            M.Toast.dismissAll();
            M.toast({ html: `No se puede agregar mas unidades de este producto...`});
            dispatch({
                type: ACTUALIZA_CANT_SALE,
                payload: { 
                    cont, 
                    act_id_reg, 
                    act_cant_prod, 
                    act_stock_prod,
                    lstprods,
                    totales 
                }
            });
        }       
    } catch (error) {
        console.error("Error en fn_cambiaInvPrd: ", error.response.data);
        dispatch({
            type: ACTUALIZA_CANT_SALE,
            payload: { 
                cont, 
                act_id_reg, 
                act_cant_prod, 
                act_stock_prod,
                lstprods, 
                totales
            }
        });
    } 
};

// funcion para actualizar el valor de la cantidad del producto en la orden
export const fn_cambiaCantProdOrd = (id_reg) => async (dispatch, getState) => {
    console.log("Actualizando la cantidad en la orden: " + id_reg);
    const { cont, act_id_reg, act_cant_prod, act_stock_prod, lstprods, totales } = getState().carrocomp_state;
    // traemos los datos del actual producto de la lista de productos para capturar los datos de ventas e impuestos
    let prod = lstprods.filter(item => item["_id"] === id_reg);
    let pldata = {};
    try {
        let qprms;
        // parametros
        if (act_cant_prod > 0) {
            console.log({ act_cant_prod, unit_price: prod[0]["eatc-odd_sale_unit_price"], precio_total: Number(act_cant_prod) * Number(prod[0]["eatc-odd_sale_unit_price"]) });
            let odd_total_weight_kg = Number(act_cant_prod) * Number(prod[0]["eatc-odd_unit_weight_kg"]);
            let odd_original_total_price = Number(act_cant_prod) * Number(prod[0]["eatc-odd_sale_unit_price"]);
            let odd_total_price = Number(act_cant_prod) * Number(prod[0]["eatc-odd_sale_unit_price"]); 
            let vat_percentage = prod[0]["eatc_vat_percentage"];
            let original_vat_base = (Number(prod[0]["eatc-odd_unit_full_price"]) * Number(act_cant_prod) ) / ( 1+(Number(prod[0]["eatc_vat_percentage"])/100) );
            let original_vat = original_vat_base * (Number(prod[0]["eatc_vat_percentage"])/100);
            let vat_base = (Number(prod[0]["eatc-odd_unit_full_price"]) * act_cant_prod) / (1+(Number(prod[0]["eatc_vat_percentage"])/100) );
            let total_vat = vat_base * (Number(prod[0]["eatc_vat_percentage"])/100);
            let other_taxes_percentage = prod[0]["eatc-other_taxes_percentage"];
            let other_taxes_base = (Number(prod[0]["eatc-odd_unit_full_price"]) * act_cant_prod) / (1+(Number(prod[0]["eatc-other_taxes_percentage"])/100));
            let total_other_taxes = other_taxes_base * Number(prod[0]["eatc-other_taxes_percentage"]);
            // los parámetros
            qprms = `_tabla=eatc_sale_order&`
                    + `_operacion=update&`
                    + `eatc-odd_original_quantity=${act_cant_prod}&`
                    + `eatc-odd_quantity=${act_cant_prod}&`
                    + `eatc-odd_total_weight_kg=${odd_total_weight_kg}&`
                    + `eatc-vat_percentage=${vat_percentage}&`
                    + `eatc-odd_original_total_price=${odd_original_total_price}&`
                    + `eatc-odd_total_price=${odd_total_price}&`
                    + `eatc-original_vat_base=${original_vat_base}&`
                    + `eatc-original_vat=${original_vat}&`
                    + `eatc-vat_base=${vat_base}&`
                    + `eatc-total_vat=${total_vat}&`
                    + `eatc-other_taxes_percentage=${other_taxes_percentage}&`
                    + `eatc-other_taxes_base=${other_taxes_base}&`
                    + `eatc-total_other_taxes=${total_other_taxes}&`
                    + `WHERE_id=${id_reg}`
        } else {
            console.log("Eliminando el registro por que la cantidad se puso en cero");
            qprms = `_tabla=eatc_sale_order&_operacion=delete&WHERE_id=${id_reg}`;
        }       
        // actualizamos el registro 
        const rs = await axios.post(`${glb._benf}${glb._crd}${glb._user}?${qprms}`); 
        if (rs.data.cont === 0) {
            M.Toast.dismissAll();
            M.toast({ html: `No se pudo actualizar el registro de orden para el registro ${id_reg}...`});
            pldata = { cont, act_id_reg, act_cant_prod, act_stock_prod, lstprods, totales };
        } else {
            console.log("Cantidad del producto ha sido cambiada");
            pldata = { cont, act_id_reg, act_cant_prod, act_stock_prod, lstprods, totales };
        }
        // generando el dispatch
        dispatch({ type: ACTUALIZA_CANT_ORDEN, payload: pldata });
    } catch (error) {
        console.error("Error en fn_cambiaInvPrd: ", error.response.data);
        pldata = { cont, act_id_reg, act_cant_prod, act_stock_prod, lstprods, totales };
        dispatch({ type: ACTUALIZA_CANT_ORDEN, payload: pldata });
    }       
};