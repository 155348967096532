import globales from "../config/config";

// funcion para generar un token aleatorio de longitud 'lng'
export const fn_genera_token = (lng) => {
  console.log("%c...[sc_misc.js] fn_genera_token( "+lng+" )", globales.consoleStyle);
  var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split(
    ""
  );
  var b = [];
  for (let i = 0; i < Number(lng); i++) {
    let j = (Math.random() * (a.length - 1)).toFixed(0);
    b[i] = a[j];
  }
  return b.join("");
};

// funcion para generar un estampe de tiempo actual de formato AAAA-MM-DD HH:MM:SS
export const fn_trae_fecha_hora = () => {
  let fch = new Date();
  let tzo = fch.getTimezoneOffset();
  fch.setHours(fch.getHours() - tzo / 60);
  return fch.toISOString().substr(0, 19).replace("T", " ");
};

// funcion para formatear los valores numericos de precios, organiza el valor con los separadores de miles y el redondeo.
export const fn_formato_numero = (numero, decimales, separador_miles) => {
  numero = parseFloat(numero);
  if (isNaN(numero)) {
    // console.log("Numero mal formato");
    console.error("numero incorrecto: " + numero);
    return 0;
  }

  if (decimales !== undefined) {
    numero = numero.toFixed(decimales);
  }

  if (separador_miles) {
    var miles = new RegExp("(-?[0-9]+)([0-9]{3})");
    while (miles.test(numero)) {
      numero = numero.replace(miles, "$1" + separador_miles + "$2");
    }
  }
  return numero;
};

//Función para obtener la fecha y ahora actuales, discriminados
export const fn_get_datetime = (dt) => {
  if (dt === undefined) {
    dt = new Date();
  }

  //var AmOrPm = dt.getHours() >= 12 ? 'PM' : 'AM';
  //var hours = (dt.getHours() % 12) || 12;

  var fecha = {
    Y: dt.getFullYear().toString(),
    M: (dt.getMonth() + 1).toString(),
    D: dt.getDate().toString(),
    AM: (dt.getHours() >= 12 ? "PM" : "AM").toString(),
    H: (dt.getHours() % 12 || 12).toString(),
    h: dt.getHours().toString(),
    m: dt.getMinutes().toString(),
    s: dt.getSeconds().toString(),
    ms: dt.getMilliseconds().toString(),
  };
  //Para garantizar que todo los números de la fecha y hora vengann de 2 dígitos
  for (var x in fecha) {
    if (fecha[x].length === 1) {
      fecha[x] = "0" + fecha[x];
    }
  }
  fecha.date = fecha.Y + "-" + fecha.M + "-" + fecha.D; //FECHA
  fecha.time = fecha.h + ":" + fecha.m + ":" + fecha.s; //HORA
  fecha.timeampm = fecha.H + ":" + fecha.m + ":" + fecha.s + " " + fecha.AM; //HORA AM/PM
  fecha.datetime = fecha.date + " " + fecha.time; //FECHA y HORA
  fecha.edatetime = fecha.date + " " + fecha.time + "." + fecha.ms; //FECHA y HORA y MILISEGUNDO
  fecha.timestamp = fecha.Y + fecha.M + fecha.D + fecha.h + fecha.m + fecha.s; //FECHA y HORA y SEGUNDOS
  fecha.etimestamp =
    fecha.Y + fecha.M + fecha.D + fecha.h + fecha.m + fecha.s + "." + fecha.ms; //FECHA y HORA y SEGUNDOS y MILISEGUNDOS
  return fecha;
};

export const fn_convert_am_pm = (time) => {
  // Check correct time format and split into components
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM ' : ' PM '; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join (''); // return adjusted time or original string
}

//Para eliminar las comillas dobles en strings retornados por servicios
export const fn_strip_quotes = (str) => {
  if(str.charAt(0)=='"'){
    str = str.substr(1);
  }
  if(str.charAt(str.length-1)=='"'){
    str = str.substr(0, str.length-1);
  }
  return str;
}

//Para eliminar objetos repetidos en lista retornada por servicio
export const fn_reduce_list = (list, objectKey) => {
  let reducedList = [];
  let addedKeys = [];
  for(let i=0; i<list.length; i++){
    let object = list[i];
    if(addedKeys.indexOf(object[objectKey])==-1){
      reducedList.push(object);
      addedKeys.push(object[objectKey]);
    }
  }
  return reducedList;
}

//Set data in shopping cart
export const fn_set_cart = (key, value) => {
  let cart_info_str = localStorage.getItem("CART_INFO") || "{}";
  let cart_info = JSON.parse(cart_info_str);
  cart_info[key] = value;
  localStorage.setItem("CART_INFO", JSON.stringify(cart_info));
}

//Get data from shopping cart
export const fn_get_cart = (key) => {
  let cart_info_str = localStorage.getItem("CART_INFO") || "{}";
  let cart_info = JSON.parse(cart_info_str);
  if(!key){
    return cart_info;
  }
  if(cart_info.hasOwnProperty(key)){
    return cart_info[key];
  }
  return "";
}

//Formateo de total para wompi
export const fn_format_total_with_cent = (total) => {
  return Math.round(total)+"00";
}


export const fn_create_unique_token = () => {    
    return btoa(localStorage.getItem('TOKEN')+':'+fn_genera_token(16));
}