import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
//import App from './App';
import Application from './Application';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import generateStore from './redux/store';

/* ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
); */

const store = generateStore();

ReactDOM.render(
  <Provider store={store}>
    <Application />
  </Provider>,
document.getElementById("root")
); 

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
