import React from "react";
import '../../css/custom.css';

function LinearIndeterminate() {
    return (
        <div className="centerHVFlex">
            <div className="progress">
                <div className="indeterminate"></div>
            </div>
        </div >
    )
}

export default LinearIndeterminate;