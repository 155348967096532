import * as firebase from "firebase/app";
import "firebase/auth";
//import "firebase/firestore";
import { fn_set_cart } from '../misc/sc_misc';

const firebaseConfig = {
    apiKey: "AIzaSyDApKvE7ebZhBsFe38LRXJLoaE4YFMkX60",
    authDomain: "eatc-sale-users.firebaseapp.com",
    databaseURL: "https://eatc-sale-users.firebaseio.com",
    projectId: "eatc-sale-users",
    storageBucket: "eatc-sale-users.appspot.com",
    messagingSenderId: "544351816285",
    appId: "1:544351816285:web:8e8f6d775f6baa65079bb4",
    measurementId: "G-VNHLT7MW3Y"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
//export const firestore = firebase.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = async () => {
    return auth.signInWithPopup(googleProvider);
};
const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const signInWithFacebook = async () => {
    return auth.signInWithPopup(facebookProvider);
};
const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const signInWithTwitter = async () => {
    return auth.signInWithPopup(twitterProvider);
};

export const generateUserDocument = (user, additionalData) => {
    if (!user) return;
    //const userRef = firestore.doc(`users/${user.uid}`);
    fn_set_cart('user', {
        uid: user.uid, 
        photoURL: user.photoURL, 
        displayName: user.displayName, 
        email: user.email
    });
    localStorage.setItem('USER', JSON.stringify({user: user, additionalData: additionalData}));
    return user;
    /*const snapshot = await userRef.get();
    if (!snapshot.exists) {*/
     // const { email, displayName, photoURL } = user;
    /*  try {
        await userRef.set({
          displayName,
          email,
          photoURL,
          ...additionalData
        });
      } catch (error) {
        console.error("Error creating user document", error);
      }
    }
    return getUserDocument(user.uid);*/
};

/*const getUserDocument = async uid => {
    if (!uid) return null;
    try {
        const userDocument = await firestore.doc(`users/${uid}`).get();
        return {
        uid,
        ...userDocument.data()
        };
    } catch (error) {
        console.error("Error fetching user", error);
    }
};*/