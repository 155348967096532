import React from "react";
import '../../css/custom.css';

function CircularSmall() {
    return (
        <div className="centerHVFlex" style={{ padding: "20px 0" }}>
            <div className="preloader-wrapper small active">
                <div className="spinner-layer spinner-green-only">
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div><div className="gap-patch">
                        <div className="circle"></div>
                    </div><div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CircularSmall;