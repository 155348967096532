import React from "react";
import { Map as LeafletMap, TileLayer, Marker, Popup } from "react-leaflet";
import LocateControl from "./LocateControl";
import M from "materialize-css";
//import Control from "react-leaflet-control";
//import SelectCiudades from '../ciudades/SelectCiudades';

class Mapa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: this.props.lat,
      lng: this.props.lng,
      points: this.props.points,
    };
  }

  componentDidMount() {
    // Auto initialize all the things!
    console.log("...ESTAS EN /MAPA -> this.state.points : ", this.state.points);
    /*this.updateCenter(
      parseFloat(this.state.points[0]["eatc-pod_lat"].replace(",", ".")),
      parseFloat(this.state.points[0]["eatc-pod_lon"].replace(",", "."))
    );*/
    this.changeCenter(this.state.points[0]);
  }

  changeCenter = (point) => {
    console.log("cambiar el centro a un \nPunto ", point);
    let curr_lat = parseFloat(point["eatc-pod_lat"].replace(",", "."));
    let curr_lng = parseFloat(point["eatc-pod_lon"].replace(",", "."));
    console.log("cambiar el centro a un \ncurr_lat ", curr_lat);
    console.log("cambiar el centro a un \ncurr_lng ", curr_lng);
    if (point !== null) {
      this.setState({
        lat: curr_lat,
        lng: curr_lng,
      });
    }
  };

  handleClick = (event) => {
    console.log("event : ", event, "key : ", event.target.options.slider);
    const key = event.target.options.slider;
    const { lat, lng } = event.latlng;
    console.log(`Clicked at ${lat}, ${lng}`);
    //this.updateCenter(lat, lng);
    this.changeCenter(event.target.options.point);
    var elem = document.querySelector(".carousel");
    var instance = M.Carousel.getInstance(elem);
    instance.set(key);
    //event.target._zIndex(100);
  };

  updateCenter = (lat, lng) => {
    console.log("lat : ", lat, " - lng: ", lng);
    //panTo(new L.LatLng(lat, lng));
    this.setState({ lat: lat, lng: lng });
  };

  render() {
    console.log(
      "...ESTAS EN /Dashboard - MAPA this.state.points : ",
      this.state.points
    );
    //let position = [];
    var posicion = [];
    if (
      this.state.lat !== "err-latitude" &&
      this.state.lng !== "err-longitude"
    ) {
      posicion = [this.state.lat, this.state.lng];
      console.log("...MAPA POSICION STATE", posicion);
    } else {
      posicion = [6.245255, -75.581703];
      console.log("...MAPA POSICION DEFAULT", posicion);
    }
    const locateOptions = {
      position: "topleft",
      icon: "fa fa-map-marker",
      drawCircle: true,
      strings: {
        title: "Show me where I am, yo!",
      },
      onActivate: () => {}, // callback before engine starts retrieving locations
    };
    return (
      <LeafletMap
        //id={"OpenStreetMap"}
        id={"satellite"}
        center={posicion}
        //center={[51.505, -0.09]}
        zoom={14}
        maxZoom={19}
        minZoom={1}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
      >
        <LocateControl options={locateOptions} />
        <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        {this.state.points.map((point, index) => {
          return (
            <Marker
              key={index}
              position={[
                point["eatc-pod_lat"].replace(",", "."),
                point["eatc-pod_lon"].replace(",", "."),
              ]}
              slider={index}
              point={point}
              onClick={(event) => this.handleClick(event)} // <-- call handleClick()
            >
              <Popup>{point["eatc-pod_name"]}</Popup>
            </Marker>
          );
        })}
      </LeafletMap>
    );
  }
}

export default Mapa;
