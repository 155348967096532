import React, { useState } from "react";
import { auth, signInWithGoogle, generateUserDocument } from "../../config/firebase";
import { useDispatch } from 'react-redux';
import { fn_storeUser } from './loginDuck';
import M from 'materialize-css';
import { Link } from "react-router-dom";
import { divStyleZ, divStyleMBn24, divStyleMTp19MBnN10, divStyleMTp16, divStyleTitle } from '../../config/config';

const SignUp = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [document, setDocument] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  React.useEffect(() => {
      let {user} = JSON.parse(localStorage.getItem('USER')) || {};
      if(user && user.uid){
          props.history.push('/checkout');
      }
      M.updateTextFields();
  }, [dispatch]);

  const goToSignIn = async() => {
    try {
        props.history.push('/signin');
    } catch (error) {
        console.error("Error redireccionando a signin");
    }
  };

  const googleSignIn = async () => {
    await signInWithGoogle().then(result => {
      generateUserDocument(result.user, {document: document, address: address, phone: phone});
    });
    await dispatch(fn_storeUser());
    props.history.push('/checkout');
  };

  const createUserWithEmailAndPasswordHandler = async (event, email, password) => {
    event.preventDefault();
    try{
        const {user} = await auth.createUserWithEmailAndPassword(email, password);
        generateUserDocument(user, {document: document, address: address, phone: phone});
        await dispatch(fn_storeUser());
        props.history.push('/checkout');
    }
    catch(error){
        if(error.message){
            setError('Error al realizar el registro con los datos ingresados. '+error.message);
        } else{
            setError('Error al realizar el registro con los datos ingresados.');
        }
    }
    setEmail("");
    setPassword("");
    setDisplayName("");
    setDocument("");
    setAddress("");
    setPhone("");
  };
  const onChangeHandler = event => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    } else if (name === "displayName") {
      setDisplayName(value);
    } else if (name === "document") {
      setDocument(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "phone") {
      setPhone(value);
    }
  };
  return (
    <div className="wrapper signup-wrapper">
      <div className="blanco-bg shadow-8dp" style={divStyleZ}>
          <div className="row" style={divStyleMBn24}>
              <div className="col s1 m1 l1">
                  <div style={divStyleMTp16}>
                      <Link
                          to="#"
                          className=""
                          onClick={() => {
                            goToSignIn();
                          }}
                      >
                          <i className="material-icons">close</i>
                      </Link>
                  </div>
              </div>
              <div className="col s11 m11 l11">
                  {/* style={divStyleMTn19} */}
                  <div className="input-field" style={divStyleMTp19MBnN10}>
                      <h4 className="brand-logo blue-text text-darken-2" style={divStyleTitle}>Crea tu cuenta EatCloud</h4>
                  </div>
              </div>
          </div>
      </div>
      <div className="container esp-top-grande">
        <p>¿Ya tienes una cuenta? <button className="btn link-btn" onClick={() => goToSignIn()}>Ingresa ahora</button></p>
        <div className="inner esp-top-base">
          {error !== null && (
            <div className="txt-error">
              {error}
            </div>
          )}
          <form className="signup-form">
            <div className="input-field">
              <input
                type="text"
                name="displayName"
                value={displayName}
                placeholder="usuario"
                id="displayName"
                onChange={event => onChangeHandler(event)}
              />
              <label htmlFor="displayName">Nombre</label>
            </div>
            <div className="input-field">
              <input
                type="email"
                name="userEmail"
                value={email}
                placeholder="usuario@correo.com"
                id="userEmail"
                onChange={event => onChangeHandler(event)}
              />
              <label htmlFor="userEmail">E-mail</label>
            </div>
            <div className="input-field">
              <input
                type="password"
                name="userPassword"
                value={password}
                placeholder="********"
                id="userPassword"
                onChange={event => onChangeHandler(event)}
              />
              <label htmlFor="userPassword">Contraseña</label>
            </div>
            {/* <label htmlFor="document" className="block">
              Documento:
            </label>
            <input
              type="number"
              name="document"
              value={document}
              placeholder="E.g: 1234"
              id="document"
              onChange={event => onChangeHandler(event)}
            />
            <label htmlFor="address" className="block">
              Dirección:
            </label>
            <input
              type="text"
              name="address"
              value={address}
              placeholder="E.g: Faruq"
              id="address"
              onChange={event => onChangeHandler(event)}
            />
            <label htmlFor="phone" className="block">
              Teléfono:
            </label>
            <input
              type="text"
              name="phone"
              value={phone}
              placeholder="E.g: 123-45 ext 123"
              id="phone"
              onChange={event => onChangeHandler(event)}
          /> */}
            <button
              className="btn primario-btn shadow-2dp waves-effect waves-light width-100 esp-top-base"
              onClick={event => {
                createUserWithEmailAndPasswordHandler(event, email, password);
              }}
            >
              Crear usuario
            </button>
          </form>
          {/*<p className="text-center my-3">or</p>
          <button
            className="bg-red-500 hover:bg-red-600 w-full py-2 text-white"
            onClick={event => {
              googleSignIn(event);
            }}
          >
            Iniciar Sesión con Google
          </button> */}
        </div>
      </div>
    </div>
  );
};
export default SignUp;