//eatc_sale_messages?eatc_message_type=sale_onboarding&eatc_country=co  
import API from "./config";
import globales from "../config/config";

const BASE_URL_BENF = globales._benf;
const ONBOARDING_ENDPOINTS = {
  API_EATCLOUD: "api/eatcloud/",
  EATC_SALE_MESSAGES: "eatc_sale_messages?",
  EATC_MESSAGE_TYPE: "eatc_message_type=",
  EATC_COUNTRY: "eatc_country=",
  ORDER: "order=",
  CONCAT: "&",
};

const OnBoardingService = {
  getAllInfo: (message, country) =>
    new Promise((resolve, reject) => {
      console.log("%c$...[OnBoardingService.js] Entrando a OnBoardingService.getAllInfo()",
        globales.consoleStyle);
      console.log("$...[OnBoardingService.js] OnBoardingService.getAllInfo(",
        "\n>> message :", message,
        "\n>> country :", country, ")");
      console.log("%c$...[OnBoardingService.js] OnBoardingService.getAllInfo()"+
        "\n>> URL : " + 
        BASE_URL_BENF +
        ONBOARDING_ENDPOINTS.API_EATCLOUD+ONBOARDING_ENDPOINTS.EATC_SALE_MESSAGES+
        ONBOARDING_ENDPOINTS.EATC_MESSAGE_TYPE+ message + ONBOARDING_ENDPOINTS.CONCAT+
        ONBOARDING_ENDPOINTS.EATC_COUNTRY+ country, 
        globales.consoleStyle);
      API.get(ONBOARDING_ENDPOINTS.API_EATCLOUD+ONBOARDING_ENDPOINTS.EATC_SALE_MESSAGES+
        ONBOARDING_ENDPOINTS.EATC_MESSAGE_TYPE+ message + ONBOARDING_ENDPOINTS.CONCAT+
        ONBOARDING_ENDPOINTS.EATC_COUNTRY+ country)
        .then(
          res => {
            console.log("$...[OnBoardingService.js] OnBoardingService.getAllInfo DONE",
            "\n>> res : ", res,
            "\n>> res.data : ", res.data,
            "\n>> res.data.res: ", res.data.res,
            "\n>> res.status : ", res.status,
            "\n>> res.statusText : ", res.statusText);
            return res.data.res
          }
        )
        .then(data => {
          console.log("%c$...[OnBoardingService.js] OnBoardingService.getAllInfo SUCCESS",
            globales.consoleStyleSuccess);
          console.log("$...[OnBoardingService.js] OnBoardingService.getAllInfo SUCCESS",
            "\n>> data : ", data,
            "\n>> data[0] : ", data[0]);
          resolve(data);
        },
        )
        .catch((err) => {
          // Error 😨😨😨😨😨😨
          console.log("%c$...[OnBoardingService.js] OnBoardingService.getAllInfo() ERROR",
            globales.consoleStyleError);
          console.error("$...[OnBoardingService.js] OnBoardingService.getAllInfo \n>> ERROR : ", err);
          console.error("$...[OnBoardingService.js] OnBoardingService.getAllInfo \n>> ERROR.RESPONSE : ", err.response);
          // Error 😨
          if (err.response) {
            /* The request was made and the server responded with a
            * status code that falls out of the range of 2xx*/
            console.error("$...[OnBoardingService.js] OnBoardingService.getAllInfo \n>> ERROR.RESPONSE.DATA : ", err.response.data);
            console.error("$...[OnBoardingService.js] OnBoardingService.getAllInfo \n>> ERROR.RESPONSE.STATUS : ", err.response.status);
            console.error("$...[OnBoardingService.js] OnBoardingService.getAllInfo \n>> ERROR.RESPONSE.HEADERS : ", err.response.headers);
          } else if (err.request) {
              /* The request was made but no response was received, `err.request`
              * is an instance of XMLHttpRequest in the browser and an instance
              * of http.ClientRequest in Node.js*/
              console.error("$...[OnBoardingService.js] OnBoardingService.getAllInfo \n>> ERROR.REQUEST : ", err.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.error("$...[OnBoardingService.js] OnBoardingService.getAllInfo \n>> ERROR.MESSAGE : ", err.message);
          }

          console.error("$...[OnBoardingService.js] OnBoardingService.getAllInfo \n>> ERROR.CONFIG : ", err.config);

          reject(err);

        });
    }), // Funcion que nos devolverá el valor de todas las peliculas
    getInfoByOrder: (message, country, order) =>
    new Promise((resolve, reject) => {
       console.log("%c$...[OnBoardingService.js] Entrando a OnBoardingService.getInfoByOrder()",
        globales.consoleStyle);
      console.log("$...[OnBoardingService.js] OnBoardingService.getInfoByOrder(",
        "\n>> message :", message,
        "\n>> country :", country,
        "\n>> order :", order, ")");
      console.log("%c$...[OnBoardingService.js] OnBoardingService.getInfoByOrder()"+
        "\n>> URL : " + 
        BASE_URL_BENF +
        ONBOARDING_ENDPOINTS.API_EATCLOUD+ONBOARDING_ENDPOINTS.EATC_SALE_MESSAGES+
        ONBOARDING_ENDPOINTS.EATC_MESSAGE_TYPE+ message + ONBOARDING_ENDPOINTS.CONCAT+
        ONBOARDING_ENDPOINTS.EATC_COUNTRY+ country + ONBOARDING_ENDPOINTS.CONCAT+
        ONBOARDING_ENDPOINTS.ORDER+ order, 
        globales.consoleStyle);
      API.get(ONBOARDING_ENDPOINTS.API_EATCLOUD+ONBOARDING_ENDPOINTS.EATC_SALE_MESSAGES+
        ONBOARDING_ENDPOINTS.EATC_MESSAGE_TYPE+ message + ONBOARDING_ENDPOINTS.CONCAT+
        ONBOARDING_ENDPOINTS.EATC_COUNTRY+ country + ONBOARDING_ENDPOINTS.CONCAT+
        ONBOARDING_ENDPOINTS.ORDER+ order)
        .then(
          res => {
            console.log("$...[OnBoardingService.js] OnBoardingService.getInfoByOrder DONE",
            "\n>> res : ", res,
            "\n>> res.data : ", res.data,
            "\n>> res.data.res: ", res.data.res,
            "\n>> res.status : ", res.status,
            "\n>> res.statusText : ", res.statusText);
            return res.data.res
          }
        )
        .then(data => {
          console.log("%c$...[OnBoardingService.js] OnBoardingService.getInfoByOrder SUCCESS",
            globales.consoleStyleSuccess);
          console.log("$...[OnBoardingService.js] OnBoardingService.getInfoByOrder SUCCESS",
            "\n>> data : ", data,
            "\n>> data[0] : ", data[0]);
          resolve(data);
        },
        )
       .catch((err) => {
          // Error 😨😨😨😨😨😨
          console.log("%c$...[OnBoardingService.js] OnBoardingService.getInfoByOrder() ERROR",
            globales.consoleStyleError);
          console.error("$...[OnBoardingService.js] OnBoardingService.getInfoByOrder \n>> ERROR : ", err);
          console.error("$...[OnBoardingService.js] OnBoardingService.getAllInfo \n>> ERROR.RESPONSE : ", err.response);
          // Error 😨
          if (err.response) {
            /* The request was made and the server responded with a
            * status code that falls out of the range of 2xx*/
            console.error("$...[OnBoardingService.js] OnBoardingService.getInfoByOrder \n>> ERROR.RESPONSE.DATA : ", err.response.data);
            console.error("$...[OnBoardingService.js] OnBoardingService.getInfoByOrder \n>> ERROR.RESPONSE.STATUS : ", err.response.status);
            console.error("$...[OnBoardingService.js] OnBoardingService.getInfoByOrder \n>> ERROR.RESPONSE.HEADERS : ", err.response.headers);
          } else if (err.request) {
              /* The request was made but no response was received, `err.request`
              * is an instance of XMLHttpRequest in the browser and an instance
              * of http.ClientRequest in Node.js*/
              console.error("$...[OnBoardingService.js] OnBoardingService.getInfoByOrder \n>> ERROR.REQUEST : ", err.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.error("$...[OnBoardingService.js] OnBoardingService.getInfoByOrder \n>> ERROR.MESSAGE : ", err.message);
          }

          console.error("$...[OnBoardingService.js] OnBoardingService.getInfoByOrder \n>> ERROR.CONFIG : ", err.config);

          reject(err);

        });
    })
};

export default OnBoardingService;
