import React, { Component } from "react";
import { Link } from "react-router-dom";
//import nomap from "../../img/nomap.jpg";
import noimage from "../../img/noimage.jpg";

export default class CardInfoImageVer extends Component {

  render() {
    return (
      <div className="col s12 m12 l12">
        <Link
          to={{
            pathname: `/detalleprod/${this.props.eatc_code}`,
            state: this.props.offer,
          }}
        >
          <div className="card horizontal">
            <div className="card-image">
              <img
                src={
                  this.props.odd_image === "" ? noimage : this.props.odd_image
                }
                alt="noimage"
              />
            </div>
            <div className="card-stacked">
              <div className="card-content">
                {this.props.odd_max_discount === "0" ? (
                  <div></div>
                ) : (
                  <div className="circulo-dcto shadow-9dp">
                    - {this.props.odd_max_discount}%
                  </div>
                )}
                {/* <div className="circulo-dcto shadow-9dp">- 10%</div> */}
                <span className="card-title-med">{this.props.odd_name}</span>
                {/* <span className="txt-meta txt-accent">a {this.props.km}KM</span> */} {/* REVISAR */}
                <span className="precio-tachado esp-top-peq">
                  ${this.props.odd_unit_price}
                </span>
                <span className="precio-oficial">
                  ${this.props.odd_min_sale_unit_price}
                </span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
