import React, {
    Component
} from 'react';
import M from "materialize-css";
import globales from '../../config/config';
import CircularSmall from "../preloader/CircularSmall";
import OnBoardingService from "../../services/OnBoardingService";
import { Link } from "react-router-dom";
import {
  isStated,
  getStated,
  isGeoLocated,
  getLat,
  getLng,
  isActualRadius,
  getActualRadius,
  isNearbyOffers,
  setNearbyOffers,
  getNearbyOffers,
  remNearbyOffers,
  isNearbyMessages,
  getNearbyMessages,
  remNearbyMessages,
  setNearbyMessages,
} from "../../services/auth";
import DashboardDonantesService from "../../services/DashboardDonantesService";
import ImportantService from "../../services/ImportantService";

export default class OnBoarding extends Component {

    /* #region  CONSTRUCTOR ********************************************************/
    /*******************************************************************************/
    /*******************************************************************************/

    constructor(props) {
        super(props);
        this.state = {
            latitud: "",
            longitud: "",
            messages: [],
            nearbyOffers: [],
            nearbyMessages: [],
            nearby_offers: [],
            nearby_points: [],
            country: "",
            showStartDash: false,
            showPrevSlide: false,
            showNextSlide: true,
            showProgressBarOffers: true,
        };
    }

    /*******************************************************************************/
    /*******************************************************************************/
    /* #endregion  CONSTRUCTOR *****************************************************/

    /* #region  COMPONENTDIDMOUNT **************************************************/
    /*******************************************************************************/
    /*******************************************************************************/

    componentDidMount() {
        // Auto initialize all the things!
        M.AutoInit();
        console.log("%c...[OnBoarding.js] ESTAS EN /OnBoarding", globales.consoleStyle);

        //var el = document.querySelector(".carousel");
        //var l = M.Carousel.getInstance(el);
        //l.next();

        /*const options = {
            duration: 200,
            dist: 0,
            shift: 50,
            padding: 10,
            numVisible: 1,
            noWrap: true,
            fullWidth: true,
            indicators: true,
            onCycleTo: () => {
                console.log("%c...[OnBoarding.js] New Slide!", globales.consoleStyle);
            },
        };*/

        //M.Carousel.init(this.Carousel, options);

        if (isStated()) {
            this.setState({ country: getStated(),});
            console.log("...[OnBoarding.js] \n>> this.state.country : ", getStated());
            OnBoardingService.getAllInfo("sale_onboarding", getStated())
                .then((messages) => {
                    console.log("...[OnBoarding.js] Ocurrió un exito ->",
                        "\n>> messages : ", messages);
                    /* console.log("...[OnBoarding.js] Ocurrió un exito ->",
                        "\n>> messages[0]['title'] : ", messages[0]["title"]);
                    console.log("...[OnBoarding.js] Ocurrió un exito ->",
                        "\n>> messages[1]['title'] : ", messages[1]["title"]);
                    console.log("...[OnBoarding.js] Ocurrió un exito ->",
                        "\n>> messages[2]['title'] : ", messages[2]["title"]); */

                    //var el = document.querySelector(".carousel");
                    //var l = M.Carousel.getInstance(el);
                    //l.next();
                        this.setState({
                            messages: messages,
                            showProgressBarOffers: !this.state.showProgressBarOffers,
                        });
                   
                    
                    const options = {
                        duration: 200,
                        dist: 0,
                        shift: 50,
                        padding: 10,
                        numVisible: 1,
                        noWrap: false,
                        fullWidth: true,
                        indicators: true,
                        onCycleTo: () => {
                            console.log("%c...[OnBoarding.js] New Slide!", globales.consoleStyle);
                        },
                    };

                    M.Carousel.init(this.Carousel, options);

                    setTimeout(() => {
                        console.log("%c...[OnBoarding.js] \n>> setTimeout : ", globales.consoleStyle);
                        /*this.setState({
                            showProgressBarOffers: !this.state.showProgressBarOffers,
                        });*/
                    }, 5000);
                    
                    this.recolectInfoGeolocated();
                })
                .catch((err) => {
                    console.log("...[OnBoarding.js] \n>> Ocurrió un error : ", err);
                });
        }

    }

    /*******************************************************************************/
    /*******************************************************************************/
    /* #endregion  COMPONENTDIDMOUNT ***********************************************/

    /* #region  METODOS IMPORTANTES ************************************************/
    /*******************************************************************************/
    /*******************************************************************************/

      recolectInfoGeolocated() {
    console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY GEOLOCALIZACION");
    if (isGeoLocated()) {
      console.log(
        "...TIENE GUARDADA LA GEOLOCALIZACION EN EL LOCALSTORAGE Y LA TOMO \nLAT",
        getLat(),
        "\nLNG",
        getLng()
      );
      console.log("...GUARDADO EN EL STATE LATITUD-LONGITUD");
      this.setState({
        latitud: getLat(),
        longitud: getLng(),
      });
      if (getLat() !== "err-latitude" && getLng() !== "err-longitude") {
        console.log("...TIENE GUARDADA UNA GEOLOCALIZACION VALIDA");
        console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY ACTUAL RADIUS");

        console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY ACTUAL RADIUS");
        if (isActualRadius()) {
          console.log(
            "...ACTUAL RADIUS EN EL LOCALSTORAGE",
            getActualRadius(),
            "\nTIPO",
            typeof getActualRadius()
          );
          console.log("...GUARDADO EN EL STATE LATITUD-LONGITUD");
          this.setState({
            actual_sale_radius_km: Number(getActualRadius()),
          });
          this.getNearbyOffersByRadius(
            getLat(),
            getLng(),
            Number(getActualRadius())
          );
          this.getNearbyMessagesByRadius(
            getLat(),
            getLng(),
            Number(getActualRadius())
          );
        } else {
          console.log("...SI NO HAY ACTUAL RADIUS EN EL LOCALSTORAGE AGREGO");
          this.getNearbyOffersByRadius(getLat(), getLng(), 4);
          this.getNearbyMessagesByRadius(getLat(), getLng(), 4);
        }
      } else {
        console.log("...TIENE GUARDADA UNA GEOLOCALIZACION CON ERROR");
      }
    } else {
      //LA DEBERIA PEDIR DE NUEVO?
      console.log("...NO TIENE GUARDADA LA GEOLOCALIZACION");
    }
  }

  getNearbyOffersByRadius = (lat, lng, km) => {
    console.log(
      "...[OnBoarding.js] getNearbyOffers GET OFERTAS CERCANAS en " + km + "KM y LOCATION(",
      lat,
      ",",
      lng,
      ") (ASINCRONO)"
    );
    let that = this;
    DashboardDonantesService.getNearbyOffers(
      "eatc_sale",
      "eatc-pod_lat,eatc-pod_lon",
      lat,
      lng,
      "_id,eatc-code,eatc-pod_id,eatc-pod_name,eatc-pod_address,eatc-pod_lat,eatc-pod_lon,eatc-odd_name,eatc-odd_description,eatc-odd_unit_price,eatc-odd_min_sale_unit_price,eatc-odd_max_discount,eatc-odd_image,eatc-odd_state,eatc_cua_origin,eatc-odd_quantity",
      km,
      "eatc-odd_state",
      "sale,partially_ordered",
      null
    )
      .then((offers) => {
        console.log("%c...[OnBoarding.js] getNearbyOffers DONE MENSAJES ACTUALES DONE", globales.consoleStyleSuccess);
        console.log("...[OnBoarding.js] getNearbyOffers DONE MENSAJES ACTUALES DONE \n>> offers : ", offers);
        if (isNearbyOffers()) {
          console.log(
            "...ACTUAL OFFERS EN EL LOCALSTORAGE",
            JSON.parse(getNearbyOffers()),
            "\nTIPO",
            typeof getNearbyOffers()
          );
          remNearbyOffers();
          setNearbyOffers(offers);
        } else {
          console.log("...NO TIENE GUARDADAS LAS OFERTAS");
          setNearbyOffers(offers);
        }
        this.setState({
          nearbyOffers: offers,
          showStartDash: true,
        });
      })
      .catch((err) => {
        //console.log("Ocurrió un error -> ", err);
        console.log("%c...[OnBoarding.js] getNearbyOffers ERROR MENSAJES ACTUALES", globales.consoleStyleError);
        console.log("...[OnBoarding.js] getNearbyOffers ERROR MENSAJES ACTUALES",
          "\n>> ERROR : ", err);
        console.log("...[OnBoarding.js] getNearbyOffers ERROR MENSAJES ACTUALES",
          "\n>> ERROR.response : ", err.response,
          "\n>> ERROR.response.statusText : ", err.response.statusText,
          "\n>> ERROR.response.status : ", err.response.status);
        if (err.response.statusText.toLowerCase() === "ok" && err.response.status === 400) {
          console.log("%c...[OnBoarding.js] getNearbyOffers ERROR TIMEOUT - MENSAJES ACTUALES ( TOAST ) -> ", globales.consoleStyle);
          M.toast({
            html:
              "Tiempo de respuesta agotado para obtener los mensajes, Reintentando ...",
            displayLength: 3000,
            completeCallback: function () {
              that.getNearbyOffersByRadius(lat, lng, km*=5);
            },
          });
        }
      });
  };

  getNearbyMessagesByRadius = (lat, lng, km) => {
    console.log(
      "...GET MENSAJES CERCANOS en " + km + "KM y LOCATION(",
      lat,
      ",",
      lng,
      ") (ASINCRONO)"
    );
    //let that = this;
    DashboardDonantesService.getNearbyMessages(
      "eatc_sale_messages",
      "eatc-lat,eatc-lon",
      lat,
      lng,
      "_id,code,date,title,url,url_button_legend,url_button_icon,image_url,order,display_conditions,display_time_sec,published_since,published_until,message",
      km
    )
      .then((messages) => {
        console.log("Ocurrió un exito -> ", messages);
        if (isNearbyMessages()) {
          console.log(
            "...ACTUAL MESSAGES EN EL LOCALSTORAGE",
            JSON.parse(getNearbyMessages()),
            "\nTIPO",
            typeof getNearbyMessages()
          );
          remNearbyMessages();
          setNearbyMessages(messages);
        } else {
          console.log("...NO TIENE GUARDADAS LOS MENSAJES");
          setNearbyMessages(messages);
        }
        this.setState({
          nearbyMessages: messages,
          //showStartDash: true,
        });
      })
      .catch((err) => {
        console.log("Ocurrió un error -> ", err);
      });
  };

  getScheduleVerify = (offers) => {
    offers.map((nearbyOffer, index) => {
      console.log("%c ImportantService.getSchedule -> ", globales.consoleStyle);
      if (nearbyOffer["eatc_cua_origin"] !== "") {
        if (nearbyOffer["eatc-pod_id"] !== "") {
          ImportantService.getSchedule(
            nearbyOffer["eatc_cua_origin"],
            nearbyOffer["eatc-pod_id"]
          )
            .then((offers) => {
              console.log("___Ocurrió un exito -> ", offers);
            })
            .catch((err) => {
              console.log("___Ocurrió un error -> ", err);
            });
        }
      }
      return console.log("%c VAMOS BIEN -> ", globales.consoleStyle);
    });
  };

  redirectToTarget = (target) => {
    console.log("...REDIRIGIR A ", target);
    this.props.history.push(target);
  };

  redirectToNextSlide = () => {
    console.log("...REDIRIGIR A SIGUIENTE SLIDE");
    const el = document.querySelector(".slider");
    const l = M.Slider.getInstance(el);
    console.log("l : ", l);
    console.log("l.el.childElementCount : ", l.el.childElementCount);
    l.next();
    const ix = l.activeIndex;
    console.log("ix: ", ix);
    if (ix === l.el.childElementCount) {
      this.setState({
        showNextSlide: false,
      });
    } else if (ix === 0) {
      this.setState({
        showNextSlide: true,
        showPrevSlide: false,
      });
    } else {
      this.setState({
        showNextSlide: true,
        showPrevSlide: true,
      });
    }
  };

  redirectToPrevSlide = () => {
    console.log("...REDIRIGIR A ANTERIOR SLIDE");
    const el = document.querySelector(".slider");
    const l = M.Slider.getInstance(el);
    console.log("l : ", l);
    console.log("l.el.childElementCount : ", l.el.childElementCount);
    l.prev();
    const ix = l.activeIndex;
    console.log("ix: ", ix);
    if (ix === l.el.childElementCount) {
      this.setState({
        showNextSlide: false,
      });
    } else if (ix === 0) {
      this.setState({
        showNextSlide: true,
        showPrevSlide: false,
      });
    } else {
      this.setState({
        showNextSlide: true,
        showPrevSlide: true,
      });
    }
  };

    /*******************************************************************************/

    renderMessagesCarousel() {
        console.log("...[OnBoarding.js] renderOffersCarousel",
            "\n>> this.state.messages", this.state.messages);
        return this.state.messages.map((message, index) => (
            <div className="carousel-item" href="#page!" key={index}>
                    <img src={message["image_url"]} alt="noimage" />
                    <div className="caption">
                        <h2 className="center">{message["title"]}</h2>
                        <p className="center">{message["message"]}</p>
                    </div>
            </div>
        ));
    }
    
    /*******************************************************************************/

    renderMessagesCarouselDefault() {
        console.log("%c...[OnBoarding.js] renderOffersCarouselDeafult", globales.consoleStyle);
        return (<div clasName="main-wrapper" style={{width : '100%', background : '#00F', paddingBottom : '10px'}}>
            <div clasName="slider-holder" style={{width : '100%', background : '#F00', paddingBottom : '10px'}}>
            <div ref={(Carousel) => {this.Carousel = Carousel;}} className="carousel carousel-slider center">

                <div className="carousel-item red white-text" href="#one!">
                    
                        <img src="https://images.freeimages.com/images/large-previews/4ba/healthy-food-1327899.jpg" alt="noimage"/>
                        <div className="caption">
                            <h2 className="center">TITULO</h2>
                            <p className="center">mensaje</p>
                        </div>
                    
                </div>
                <div className="carousel-item amber white-text" href="#two!">
                     
                        <img src="https://images.freeimages.com/images/large-previews/4ba/healthy-food-1327899.jpg" alt="noimage"/>
                        <div className="caption">
                            <h2 className="center">TITULO</h2>
                            <p className="center">mensaje</p>
                        </div>
                    
                </div>
                <div className="carousel-item green white-text" href="#three!">
                    
                        <img src="https://images.freeimages.com/images/large-previews/4ba/healthy-food-1327899.jpg" alt="noimage"/>
                        <div className="caption">
                            <h2 className="center">TITULO</h2>
                            <p className="center">mensaje</p>
                        </div>
                   
                </div>
                
            </div>
        </div></div>
        );
    }

    /*******************************************************************************/

    /*******************************************************************************/
    /*******************************************************************************/
    /* #endregion  METODOS IMPORTANTES *********************************************/

    /* #region  RENDER *************************************************************/
    /*******************************************************************************/
    /*******************************************************************************/

    render() {
        return (
                <div id="bienvenida">
                    <div className="navbar">
                        <nav>
                            <div className="nav-wrapper">
                            {this.state.showStartDash && (
                                <ul className="right" id="startdash">
                                <li>
                                    <Link
                                    to="#"
                                    className="accbtn"
                                    onClick={() => {
                                        this.redirectToTarget("/dashboard");
                                    }}
                                    >
                                    <i className="material-icons right">close</i>Saltar
                                    </Link>
                                </li>
                                </ul>
                            )}
                            </div>
                        </nav>
                    </div>
                    <div className="container-bienvenida" style={{overflow: 'auto'}}>
                        <div className="row">
                            {/* <div className="col s12 m12 l12"> */}
                            <div className="col s12 m6 offset-m3 l4 offset-l4">
                                <div className="main-wrapper" style={{width : '100%', paddingBottom : '10px'}}>
                                    <div className="slider-holder" style={{width : '100%', paddingBottom : '50px'}}>
                                        {this.state.showProgressBarOffers && <CircularSmall />}
                                        {!this.state.showProgressBarOffers && (
                                            <div ref={(Carousel) => {this.Carousel = Carousel;}}
                                            className="carouselish carousel carousel-slider">
                                                {this.renderMessagesCarousel()}
                                            </div>
                                        )}
                                        {/*!this.state.showProgressBarOffers && (this.renderMessagesCarouselDefault())*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    /*******************************************************************************/
    /*******************************************************************************/
    /* #endregion  RENDER **********************************************************/

}