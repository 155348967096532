import React, { Component } from "react";

export default class TestingFooter extends Component {
  redirectToTarget = (target) => {
    console.log("...REDIRIGIR A ", target);
    this.props.history.push(target);
  };

  render() {
    return (
      <footer className="page-footer footer-fixed" id="eatc-usr-fn">
        <div className="container-footer">
          <div className="row">
            <div className="col l3 s3 center-align">
              <button
                className="waves-effect waves-light btn accbtn"
                onClick={() => {
                  this.redirectToTarget("/dashboardsale");
                }}
              >
                <i className="material-icons top">home</i>
                <span>Inicio</span>
              </button>
            </div>
            <div className="col l2 s2 center-align">
              <button
                className="waves-effect waves-light btn accbtn"
                onClick={() => {
                  this.redirectToTarget("/listaproductos");
                }}
              >
                <i className="material-icons top">star</i>
                <span>Promociones</span>
              </button>
            </div>
            <div className="col l2 s2 center-align">
              <button
                className="waves-effect waves-light btn accbtn"
                onClick={() => {
                  this.redirectToTarget("/tuspedidos");
                }}
              >
                <i className="material-icons top">shopping_basket</i>
                <span>Tus Pedidos</span>
              </button>
            </div>
            <div className="col l2 s2 center-align">
              <button
                className="waves-effect waves-light btn accbtn"
                onClick={() => {
                  this.redirectToTarget("/tuperfil");
                }}
              >
                <i className="material-icons top">account_circle</i>
                <span>Tu Perfil</span>
              </button>
            </div>
            <div className="col l3 s3 center-align">
              {/* <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://m.me/eatcloudhelpdesk"}
                className="waves-effect waves-light btn accbtn"
              >
                <i className="material-icons top">help</i>
                <span>
                  Ayuda
                </span>
              </a> */}
              <button
                className="waves-effect waves-light btn accbtn"
                onClick={() => {
                  window.open("https://m.me/eatcloudhelpdesk", "_blank");
                }}
              >
                <i className="material-icons top">help</i>
                <span>Ayuda</span>
              </button>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
