import globales from "../config/config";
import API from "./config_productivo";

const LOCATION_ENDPOINTS = {
  API_EATCLOUD: "api/eatcloud/",
  API_DATA: "api/data/",
  GET_DATA: "get/data/",
  GET_PUNTOS: "getpuntos?",
  EATC_CO_MUNICIPALITIES: "eatc_co_municipalities?",
  EATC_SALE_QUERY_RADIUS: "eatc_sale_query_radius?",
  EATC_QUERY_TYPE: "eatc-query_type=",
  EATC_TABLE_TYPE: "table=",
  EATC_FIELDNAME: "fieldname=",
  EATC_FIELDVALUE: "fieldvalue=",
  EATC_SHOWFIELD: "showfield=",
  EATC_USER_ID: "eatc-user_id=",
  EATC_KM: "km=",
  ORDER: "order=",
  CAMPOS: "_campos",
  CONCAT: "&",
  EQUAL: "=",
  COMA: ",",
};

const LocationService = {
  getMunicipios: (table, fieldname, lat, lng, showfield, km) =>
    new Promise((resolve, reject) => {
      console.log("%c$...[LocationService.js] Entrando a LocationService.getMunicipios()",
        globales.consoleStyle);
      console.log("$...[LocationService.js] Entrando a LocationService.getMunicipios(",
        "\n>> table :", table,
        "\n>> fieldname :", fieldname,
        "\n>> lat :", lat,
        "\n>> lng :", lng,
        "\n>> showfield :", showfield,
        "\n>> km :", km, ")");

      console.log("%c$...[LocationService.js] Entrando a LocationService.getMunicipios()"+
        "\n>> url : https://beneficiarios.eatcloud.info/" + LOCATION_ENDPOINTS.GET_DATA + 
        LOCATION_ENDPOINTS.GET_PUNTOS +
        LOCATION_ENDPOINTS.EATC_TABLE_TYPE + table + LOCATION_ENDPOINTS.CONCAT +
        LOCATION_ENDPOINTS.EATC_FIELDNAME + fieldname + LOCATION_ENDPOINTS.CONCAT +
        LOCATION_ENDPOINTS.EATC_FIELDVALUE + lat.toString() + LOCATION_ENDPOINTS.COMA + 
        lng.toString() + LOCATION_ENDPOINTS.CONCAT +
        LOCATION_ENDPOINTS.EATC_SHOWFIELD + showfield + LOCATION_ENDPOINTS.CONCAT +
        LOCATION_ENDPOINTS.EATC_KM + km.toString(), globales.consoleStyle);

      API.get(LOCATION_ENDPOINTS.GET_DATA + LOCATION_ENDPOINTS.GET_PUNTOS +
        LOCATION_ENDPOINTS.EATC_TABLE_TYPE + table + LOCATION_ENDPOINTS.CONCAT +
        LOCATION_ENDPOINTS.EATC_FIELDNAME + fieldname + LOCATION_ENDPOINTS.CONCAT +
        LOCATION_ENDPOINTS.EATC_FIELDVALUE + lat.toString() + LOCATION_ENDPOINTS.COMA + 
        lng.toString() + LOCATION_ENDPOINTS.CONCAT +
        LOCATION_ENDPOINTS.EATC_SHOWFIELD + showfield + LOCATION_ENDPOINTS.CONCAT +
        LOCATION_ENDPOINTS.EATC_KM + km.toString())
        .then(
          res => {
            console.log("$...[LocationService.js] LocationService.getMunicipios() DONE",
            "\n>> res : ", res,
            "\n>> res.data : ", res.data,
            "\n>> res.data.res : ", res.data.res,
            "\n>> res.status : ", res.status,
            "\n>> res.statusText : ", res.statusText);

            return res.data.res

          }
        )
        .then(data => {
          console.log("%c$...[LocationService.js] LocationService.getMunicipios() SUCCESS",
            globales.consoleStyleSuccess);
          console.log("$...[LocationService.js] LocationService.getMunicipios() SUCCESS",
            "\n>> data : ", data,
            "\n>> data[0] : ", data[0]);

          resolve(data);

        },
        )
        .catch(err => {
          // Error 😨😨😨😨😨😨
          console.log("%c$...[LocationService.js] LocationService.getMunicipios() ERROR",globales.consoleStyleError);
          console.error("$...[LocationService.js]",
            "\n>> ERROR : ", err,
            "\n>> ERROR.RESPONSE : ", err.response);

          // Error 😨
          if (err.response) {
            /*
              * The request was made and the server responded with a
              * status code that falls out of the range of 2xx
              */
            console.error("$...[LocationService.js]",
              "\n>> ERROR.RESPONSE.DATA : ", err.response.data,
              "\n>> ERROR.RESPONSE.STATUS : ", err.response.status,
              "\n>> ERROR.RESPONSE.HEADERS : ", err.response.headers);

          } else if (err.request) {
            /*
              * The request was made but no response was received, `error.request`
              * is an instance of XMLHttpRequest in the browser and an instance
              * of http.ClientRequest in Node.js
              */
            console.error("$...[LocationService.js] \n>> ERROR.REQUEST : ", err.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.error("$...[LocationService.js] \n>> ERROR.MESSAGE : ", err.message);
          }

          console.error("$...[LocationService.js] \n>> ERROR.CONFIG : ", err.config);

          reject(err);

        });
    }),
  getCamposMunicipios: () =>
    new Promise((resolve, reject) => {
      console.log("%c$...[LocationService.js] Entrando a LocationService.getCamposMunicipios()",
        globales.consoleStyle);
      console.log("%c$...[LocationService.js] Entrando a LocationService.getCamposMunicipios()"+
        "\n>> url : https://beneficiarios.eatcloud.info/" + LOCATION_ENDPOINTS.API_DATA + 
        LOCATION_ENDPOINTS.EATC_CO_MUNICIPALITIES + LOCATION_ENDPOINTS.CAMPOS, globales.consoleStyle);
      API.get(LOCATION_ENDPOINTS.API_DATA + LOCATION_ENDPOINTS.EATC_CO_MUNICIPALITIES + 
        LOCATION_ENDPOINTS.CAMPOS)
        .then(
          res => {
             console.log("$...[LocationService.js] LocationService.getCamposMunicipios() DONE",
            "\n>> res : ", res,
            "\n>> res.data : ", res.data,
            "\n>> res.data.res : ", res.data.res,
            "\n>> res.status : ", res.status,
            "\n>> res.statusText : ", res.statusText);

            return res.data.res
          }
        )
        .then(data => {
          console.log("%c$...[LocationService.js] LocationService.getCamposMunicipios() SUCCESS",
            globales.consoleStyleSuccess);
          console.log("$...[LocationService.js] LocationService.getCamposMunicipios() SUCCESS",
            "\n>> data : ", data,
            "\n>> data[0] : ", data[0]);

          resolve(data);

        },
        )
        .catch(err => {
          // Error 😨😨😨😨😨😨
          console.log("%c$...[LocationService.js] LocationService.getCamposMunicipios() ERROR",
            globales.consoleStyleError);
          console.error("$...[LocationService.js]",
            "\n>> ERROR : ", err,
            "\n>> ERROR.RESPONSE : ", err.response);

          // Error 😨
          if (err.response) {
            /*
              * The request was made and the server responded with a
              * status code that falls out of the range of 2xx
              */
            console.error("$...[LocationService.js]",
              "\n>> ERROR.RESPONSE.DATA : ", err.response.data,
              "\n>> ERROR.RESPONSE.STATUS : ", err.response.status,
              "\n>> ERROR.RESPONSE.HEADERS : ", err.response.headers);

          } else if (err.request) {
            /*
              * The request was made but no response was received, `error.request`
              * is an instance of XMLHttpRequest in the browser and an instance
              * of http.ClientRequest in Node.js
              */
            console.error("$...[LocationService.js] \n>> ERROR.REQUEST : ", err.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.error("$...[LocationService.js] \n>> ERROR.MESSAGE : ", err.message);
          }

          console.error("$...[LocationService.js] \n>> ERROR.CONFIG : ", err.config);

          reject(err);

        });
    }),
};

export default LocationService;