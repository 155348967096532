import axios from 'axios';
import globales from '../config/config';

//Ambiente de pruebas:
//https://devbeneficiarios.eatcloud.info/
//Ambiente productivo:
//https://beneficiarios.eatcloud.info/
const BASE_URL_BENF = globales._benf;

const API  = axios.create({
    baseURL : BASE_URL_BENF,
})

export default API;