import React, { Component } from "react";
import CardInfoImageVer from "../cards/CardInfoImageVer";
import CircularSmall from "../preloader/CircularSmall";
import M from "materialize-css";
import { Link } from "react-router-dom";
import globales from "../../config/config";

export default class ListaProductos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latitud: "",
      longitud: "",
      actual_sale_radius_km: 0,
      showProgressBarOffers: true,
      showProgressBarMessages: true,
      showProgressBarListProducts: false,
      nearby_messages: [],
      nearby_offers: props.location.state.nearby_offers_search,
      nearby_offers_search: props.location.state.nearby_offers_search,
      search_text: "",
    };
  }

  componentDidMount() {
    // Auto initialize all the things!
    M.AutoInit();
    console.log("...ESTAS EN /ListaProductos");
    this.nameInput.focus();
  }

  filter = (event) => {
    console.log("event : ", event, "index : ");
    this.setState({
      //showProgressBarListProducts: !this.state.showProgressBarListProducts,
    });
    var search_text = event.target.value;
    console.log("%c search_text : " + search_text, globales.consoleStyle);
    //const data = this.state.nearby_offers;
    const data = this.state.nearby_offers;
    const newData = data.filter(function (item) {
      console.log("Item : ", item);
      console.log("Item : ", item["eatc-odd_name"]);
      console.log("Item : ", item["eatc-odd_description"]);
      console.log("Item : ", item["eatc-pod_name"]);
      const eatcOddName = item["eatc-odd_name"].toUpperCase();
      const eatcOddDescription = item["eatc-odd_description"].toUpperCase();
      const eatcPodName = item["eatc-pod_name"].toUpperCase();
      const campo = eatcOddName + " " + eatcOddDescription + " " + eatcPodName;
      const textData = search_text.toUpperCase();
      return campo.indexOf(textData) > -1;
    });
    console.log("newData : ", newData);
    this.setState({
      //showProgressBarListProducts: !this.state.showProgressBarListProducts,
      nearby_offers_search: newData,
      search_text: search_text,
    });
  };

  redirectToTargetBack = (e) => {
    //e.preventDefault();
    console.log("...REDIRIGIR ATRAS");
    this.props.history.goBack();
  };

  renderOffersListProducts() {
    return this.state.nearby_offers_search.map((nearbyOffer, index) => (
      <CardInfoImageVer
        key={index}
        offer={nearbyOffer}
        odd_image={nearbyOffer["eatc-odd_image"]}
        odd_max_discount={nearbyOffer["eatc-odd_max_discount"]}
        odd_name={nearbyOffer["eatc-odd_name"]}
        odd_unit_price={nearbyOffer["eatc-odd_unit_price"]}
        odd_min_sale_unit_price={nearbyOffer["eatc-odd_min_sale_unit_price"]}
        km={nearbyOffer["km"]}
        eatc_cua_origin={nearbyOffer["eatc_cua_origin"]}
        eatc_pod_id={nearbyOffer["eatc-pod_id"]}
        eatc_code={nearbyOffer["eatc-code"]}
      />
    ));
  }

  render() {
    const divStyleZ = { zIndex: "1000" };
    const divStyleMBn24 = { marginBottom: "-24px" };
    //const divStyleMTn24 = { marginTop: "-200px" };
    const divStyleMTn19 = { marginTop: "-19px", marginBottom: "-10px" };
    const divStyleMTp16 = { marginTop: "16px" };
    //const divStylePTpBn20 = { padding: "20px 0" };
    //const divStyleHeight200 = { height: "200px" };
    return (
      <div className="tinta-bg">
        <div className="blanco-bg shadow-8dp" style={divStyleZ}>
          <div className="row" style={divStyleMBn24}>
            <div className="col s2 m2 l2">
              <div style={divStyleMTp16}>
                <Link
                  to="#"
                  className=""
                  onClick={() => {
                    this.redirectToTargetBack();
                  }}
                >
                  <i className="material-icons">close</i>
                </Link>
              </div>
            </div>
            <div className="col s10 m10 l10">
              {/* style={divStyleMTn19} */}
              <div className="input-field" style={divStyleMTn19}>
                <input
                  id="search"
                  placeholder="Search..."
                  value={this.state.text}
                  ref={(input) => {
                    this.nameInput = input;
                  }}
                  onChange={(text) => this.filter(text)}
                  className=""
                />
                <i className="material-icons icn-in-input">search</i>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            {this.state.showProgressBarListProducts && <CircularSmall />}
            {!this.state.showProgressBarListProducts &&
              this.renderOffersListProducts()}
          </div>
        </div>
      </div>
    );
  }
}
