import axios from 'axios';
import globales from '../config/config';

//const BASE_URL = "https://geocode.xyz/6.2560936,-75.5901797?json=1";
const BASE_URL = "https://geocode.xyz/";
const API = axios.create({
  baseURL: BASE_URL,
})

const GEO_ENDPOINTS = {
  FINALLY: "?json=1", //URI para llamar a todos los campos de voluntarios.
  COMA: ","
};

const GeoService = {
  getState: (lat, lng) =>
    new Promise((resolve, reject) => {
      console.log("%c$...[GeoService.js] Entrando a GeoService.getState()",
        globales.consoleStyle);
      console.log("$...[GeoService.js] Entrando a GeoService.getState(",
        "\n>> lat :", lat,
        "\n>> lng :", lng, ")");
      console.log("%c$...[GeoService.js] Entrando a GeoService.getState()"+
        "\n>> url : " + BASE_URL + lat.toString() + GEO_ENDPOINTS.COMA + 
        lng.toString() + GEO_ENDPOINTS.FINALLY, globales.consoleStyle);
      API.get(lat.toString() + GEO_ENDPOINTS.COMA + lng.toString() + GEO_ENDPOINTS.FINALLY)
        .then(
          res => {
            console.log("$...[GeoService.js] GeoService.getState() DONE",
            "\n>> res : ", res,
            "\n>> res.data : ", res.data,
            "\n>> res.data.state: ", res.data.state,
            "\n>> res.status : ", res.status,
            "\n>> res.statusText : ", res.statusText);

            return res.data.state
          }
        )
        .then(data => {
          console.log("%c$...[GeoService.js] GeoService.getState() SUCCESS",
            globales.consoleStyleSuccess);
          console.log("$...[GeoService.js] GeoService.getState() SUCCESS",
            "\n>> data : ", data,
            "\n>> data[0] : ", data[0]);
          
          resolve(data);

        },
        )
        .catch((err) => {
            // Error 😨😨😨😨😨😨
            console.log("%c$...[GeoService.js] GeoService.getState() ERROR",globales.consoleStyleError);
            console.error("$...[GeoService.js]",
              "\n>> ERROR : ", err,
              "\n>> ERROR.RESPONSE : ", err.response);

            // Error 😨
            if (err.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.error("$...[GeoService.js]",
                "\n>> ERROR.RESPONSE.DATA : ", err.response.data,
                "\n>> ERROR.RESPONSE.STATUS : ", err.response.status,
                "\n>> ERROR.RESPONSE.HEADERS : ", err.response.headers);

            } else if (err.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.error("$...[GeoService.js] \n>> ERROR.REQUEST : ", err.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.error("$...[GeoService.js] \n>> ERROR.MESSAGE : ", err.message);
            }

            console.error("$...[GeoService.js] \n>> ERROR.CONFIG : ", err.config);

            reject(err);  

          });
    }),
  getCity: (lat, lng) =>
    new Promise((resolve, reject) => {
       console.log("%c$...[GeoService.js] Entrando a GeoService.getCity()",
        globales.consoleStyle);
      console.log("$...[GeoService.js] Entrando a GeoService.getCity(",
        "\n>> lat :", lat,
        "\n>> lng :", lng, ")");
      console.log("%c$...[GeoService.js] Entrando a GeoService.getCity()"+
        "\n>> url : " + BASE_URL + lat.toString() + GEO_ENDPOINTS.COMA + 
        lng.toString() + GEO_ENDPOINTS.FINALLY, globales.consoleStyle);
      API.get(lat.toString() + GEO_ENDPOINTS.COMA + lng.toString() + GEO_ENDPOINTS.FINALLY)
        .then(
          res => {
            console.log("$...[GeoService.js] GeoService.getCity() DONE",
            "\n>> res : ", res,
            "\n>> res.data : ", res.data,
            "\n>> res.data.city: ", res.data.city,
            "\n>> res.status : ", res.status,
            "\n>> res.statusText : ", res.statusText);

            return res.data.city

          }
        )
        .then(data => {
          console.log("%c$...[GeoService.js] GeoService.getCity() SUCCESS",
            globales.consoleStyleSuccess);
          console.log("$...[GeoService.js] GeoService.getCity() SUCCESS",
            "\n>> data : ", data,
            "\n>> data[0] : ", data[0]);

          resolve(data);

        },
        ).catch((err) => {
            // Error 😨😨😨😨😨😨
            console.log("%c$...[GeoService.js] GeoService.getCity() ERROR",globales.consoleStyleError);
            console.error("$...[GeoService.js]",
              "\n>> ERROR : ", err,
              "\n>> ERROR.RESPONSE : ", err.response);

            // Error 😨
            if (err.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.error("$...[GeoService.js]",
                "\n>> ERROR.RESPONSE.DATA : ", err.response.data,
                "\n>> ERROR.RESPONSE.STATUS : ", err.response.status,
                "\n>> ERROR.RESPONSE.HEADERS : ", err.response.headers);

            } else if (err.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.error("$...[GeoService.js] \n>> ERROR.REQUEST : ", err.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.error("$...[GeoService.js] \n>> ERROR.MESSAGE : ", err.message);
            }

            console.error("$...[GeoService.js] \n>> ERROR.CONFIG : ", err.config);

            reject(err);

          });
    })

};

export default GeoService;