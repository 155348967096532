import axios from 'axios';

//Ambiente de pruebas:
//https://devbeneficiarios.eatcloud.info/
//Ambiente productivo:
//https://beneficiarios.eatcloud.info/
const BASE_URL = "https://beneficiarios.eatcloud.info/";

const API  = axios.create({
    baseURL : BASE_URL,
})

export default API;