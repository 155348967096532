import React, { useState } from "react";
import { auth } from "../../config/firebase";
import { useDispatch } from 'react-redux';
import M from 'materialize-css';
import { Link } from "react-router-dom";
import { divStyleZ, divStyleMBn24, divStyleMTp19MBnN10, divStyleMTp16, divStyleTitle } from '../../config/config';

const PasswordReset = (props) => {
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

    React.useEffect(() => {
        let {user} = JSON.parse(localStorage.getItem('USER')) || {};
        if(user && user.uid){
            props.history.push('/checkout');
        }
        M.updateTextFields();
    }, [dispatch]);

  const goToSignIn = async() => {
    try {
        props.history.push('/signin');
    } catch (error) {
        console.error("Error redireccionando a signin");
    }
  };

  const onChangeHandler = event => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    }
  };
  const sendResetEmail = event => {
    event.preventDefault();
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setError(null);
        setEmailHasBeenSent(true);
        setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
      })
      .catch(() => {
        setError("Ha ocurrido un error recuperando la contraseña. Por favor intenta de nuevo!");
    });
  };
  return (
    <div className="wrapper passwordreset-wrapper">
      <div className="blanco-bg shadow-8dp" style={divStyleZ}>
          <div className="row" style={divStyleMBn24}>
              <div className="col s1 m1 l1">
                  <div style={divStyleMTp16}>
                      <Link
                          to="#"
                          className=""
                          onClick={() => {
                            goToSignIn();
                          }}
                      >
                          <i className="material-icons">close</i>
                      </Link>
                  </div>
              </div>
              <div className="col s11 m11 l11">
                  {/* style={divStyleMTn19} */}
                  <div className="input-field" style={divStyleMTp19MBnN10}>
                      <h4 className="brand-logo blue-text text-darken-2" style={divStyleTitle}>Recuperar contraseña</h4>
                  </div>
              </div>
          </div>
      </div>
      <div className="container esp-top-grande">
        <p>Ingresa tu correo electrónico y te enviaremos un link para que recuperes tu contraseña.</p>
        <div className="inner esp-top-base">
          <form action="passwordreset-form">
            {emailHasBeenSent && (
              <div className="txt-succes">
                Un email ya sido enviado a tu bandeja con las instrucciones de recuperación!
              </div>
            )}
            {error !== null && (
              <div className="txt-error">
                {error}
              </div>
            )}
            <div className="input-field">
              <input
                type="email"
                name="userEmail"
                id="userEmail"
                value={email}
                placeholder="usuario@correo.com"
                onChange={onChangeHandler}
                className="mb-3 w-full px-1 py-2"
              />
              <label htmlFor="userEmail">E-mail</label>
            </div>
            <button
              onClick={event => {
                  sendResetEmail(event);
              }}
              className="btn primario-btn shadow-2dp waves-effect waves-light width-100 esp-top-base"
            >
              Enviar link de recuperación
            </button>
          </form>
          <button className="btn secundario-btn shadow-2dp waves-effect waves-light width-100 esp-top-base" onClick={() => goToSignIn()}>&larr; Regresar</button>
        </div>
      </div>
    </div>
  );
};
export default PasswordReset;