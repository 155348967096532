const globales = {
    _master: "abaco/",
    _user: "eatcloud/",
    _dona: "https://devdonantes.eatcloud.info/",
    _benf: "https://devbeneficiarios.eatcloud.info/",    
    _gov: "https://datagov.eatcloud.info/",
    _payment: "https://dsi1eg6od5.execute-api.us-east-2.amazonaws.com/default/",
    _wompiEndpoint: "https://sandbox.wompi.co/v1/",
    _wompiPub: "pub_test_f3doGV6eDbHkQjzQyUgK1JKtl3YMtLk3",
    _wompiPriv: "",
    _lambdaKey: "ejxCREZ7UXQ4JiwuLVVxOyZWeERxVzU7a0ZFY0ZD",
    _api: "api/",
    _crd: "crd/",
    _get: "get/",
    _crypt: "crypt/",
    consoleStyle : "background: #222; color: #bada55",
    consoleStyleSuccess : "background: #222; color: #00ff00",
    consoleStyleError : "background: #ff0000; color: #ffffff",
    consoleStyleStorageSuccess : "background: #000000; color: #00ccff",
    consoleStyleStorageError : "background: #660000; color: #ffffff",
    consoleStyleStorageSave : "background: #000080; color: #99ccff",
    consoleStyleStorageDelete : "background: #660000; color: #ff9999",
    consoleStyleStorageIs : "background: #004d00; color: #80ff80",
    consoleStyleStorageGet : "background: #806000; color: #ffff80",
};

export default globales;
export const divStyleZ = { zIndex: "1000" };
export const divStyleMTpN24 = { marginTop: "-24px" };
export const divStyleColorBlack = { color: "#000000", marginLeft: "-5px" };
export const divStyleMBn24 = { marginBottom: "-24px" };
export const divStyleWidth50MarginCero = { width: "50%", margin: "0" };
export const divStyleMTp18 = { marginTop: "18px" };
export const divStyleMTp19MBnN10 = { marginTop: "-19px", marginBottom: "-10px" };
export const divStyleMTp16 = { marginTop: "16px" };
export const divStyleMaxWidth = { width: "20px", paddingLeft: "6px", paddingRight: "6px" };
export const divStyleTitle = {
    height: "3rem",
    lineHeight: "3rem",
    width: "90%",
    fontSize: "16px",
    margin: "24px 0 16px 0",
    padding: "0 16px",
    textAlign: "center"
};
