//https://beneficiarios.eatcloud.info/get/data/getpuntos?table=eatc_co_municipalities&fieldname=latitud,longitud&fieldvalue={{actual_lat}},{{actual_lon}}&showfield={{campos_del_object_store_a_mostrar}}&km={{radio_a_partir_de_fieldvalue_para_la_conuslta}}
//{{URL_entorno_beneficiarios}}/api/eatcloud/eatc_sale_query_radius?eatc-query_type=eatc_sale&{{parámetros_de_consulta}}
//{{URL_entorno_beneficiarios}}/api/eatcloud/eatc_sale_query_radius?eatc-query_type=eatc_sale&{{parámetros_de_consulta}}
//{{URL_entorno_beneficiarios}}/api/eatcloud/eatc_sale_query_radius?eatc-query_type=eatc_sale_messages&{{parámetros_de_consulta}}
///get/data/getpuntos?table=eatc_co_municipalities&fieldname=latitud,longitud&fieldvalue=6.2045697,-75.60157&showfield=nombre_municipio&km=2
//{{URL_entorno_donantes}}/get/eatcloud/getpuntos?table=eatc_sale&fieldname=eatc-pod_lat,eatc-pod_lon&fieldvalue=6.25177777,-75.59877777&showfield=eatc-code,eatc-pod_id,eatc-pod_name,eatc-pod_address,eatc-pod_lat,eatc-pod_lon,eatc-odd_name,eatc-odd_description,eatc-odd_unit_price,eatc-odd_min_sale_unit_price,eatc-odd_max_discount,eatc-odd_image,eatc-odd_state,eatc_cua_origin&km=5&filterfield_1=eatc-odd_state&filtervalue_1=sale,partially_ordered
import API from "./config";
import globales from "../config/config"

const consoleStyle = "background: #222; color: #bada55";
const consoleStyleSuccess = "background: #222; color: #00ff00";
const consoleStyleError = "background: #ff0000; color: #ffffff";

const DASHBOARD_ENDPOINTS = {
  API_EATCLOUD: "api/eatcloud/",
  GET_DATA: "get/data/",
  GET_EATCLOUD: "get/eatcloud/",
  GET_PUNTOS: "getpuntos?",
  EATC_SALE_QUERY_RADIUS: "eatc_sale_query_radius?",
  EATC_QUERY_TYPE: "eatc-query_type=",
  EATC_TABLE_TYPE: "table=",
  EATC_FIELDNAME: "fieldname=",
  EATC_FIELDVALUE: "fieldvalue=",
  EATC_SHOWFIELD: "showfield=",
  EATC_USER_ID: "eatc-user_id=",
  EATC_KM: "km=",
  ORDER: "order=",
  CONCAT: "&",
  EQUAL: "=",
  COMA: ",",
};

const DashboardService = {
  getPoints: (table, lat, lng, act_lat, act_lng, showfield, km) =>
    new Promise((resolve, reject) => {
      API.get(
        DASHBOARD_ENDPOINTS.GET_DATA +
          DASHBOARD_ENDPOINTS.GET_PUNTOS +
          DASHBOARD_ENDPOINTS.EATC_TABLE_TYPE +
          table +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDNAME +
          lat.toString() +
          DASHBOARD_ENDPOINTS.COMA +
          lng.toString() +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDVALUE +
          +act_lat.toString() +
          DASHBOARD_ENDPOINTS.COMA +
          act_lng.toString() +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_SHOWFIELD +
          showfield +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_KM +
          km
      )
        .then((res) => {
          console.log("res : ", res);
          console.log("res.data : ", res.data);
          console.log("res.status : ", res.status);
          console.log("res.statusText : ", res.statusText);
          return res.data.res;
        })
        .then((data) => {
          resolve(data);
          console.log("data : ", data);
          console.log("data[0] : ", data[0]);
        })
        .catch((err) => reject(err));
    }),
  getQueryRadius: (queryType, params) =>
    new Promise((resolve, reject) => {
      console.log(
        "%c Entrando a DashboardService.getQueryRadius()",
        consoleStyle
      );
      console.log(
        "DashboardService.getQueryRadius(queryType :",
        queryType,
        "params :",
        params
      );
      const PARAMS = JSON.parse(params);
      console.log("PARAMS :", PARAMS);
      var paramsConsulta = "";
      for (var x in PARAMS) {
        paramsConsulta +=
          DASHBOARD_ENDPOINTS.CONCAT +
          x +
          DASHBOARD_ENDPOINTS.EQUAL +
          PARAMS[x];
      }

      console.log("paramsConsulta : ", paramsConsulta);
      console.log(
        "%c Entrando a DashboardService.getQueryRadius()" +
          globales._benf +
          DASHBOARD_ENDPOINTS.API_EATCLOUD +
          DASHBOARD_ENDPOINTS.EATC_SALE_QUERY_RADIUS +
          DASHBOARD_ENDPOINTS.EATC_QUERY_TYPE +
          queryType +
          paramsConsulta,
        consoleStyle
      );

      API.get(
        DASHBOARD_ENDPOINTS.API_EATCLOUD +
          DASHBOARD_ENDPOINTS.EATC_SALE_QUERY_RADIUS +
          DASHBOARD_ENDPOINTS.EATC_QUERY_TYPE +
          queryType +
          paramsConsulta
      )
        .then((res) => {
          console.log("res : ", res);
          console.log("res.data : ", res.data);
          console.log("res.status : ", res.status);
          console.log("res.statusText : ", res.statusText);
          return res.data.res;
        })
        .then((data) => {
          console.log(
            "%c DashboardService.getQueryRadius() SUCCESS",
            consoleStyleSuccess
          );
          resolve(data[0]);
          console.log("data : ", data);
          console.log("data[0] : ", data[0]);
        })
        .catch((err) => {
          console.log(
            "%c DashboardService.getQueryRadius() ERROR",
            consoleStyleError
          );
          reject(err);
        });
    }),
};

export default DashboardService;
