import axios from 'axios';
import glb from '../../config/config';
//import M from 'materialize-css';
import { getLat, getLng } from "../../services/auth";
import { fn_reduce_list, fn_set_cart, fn_get_cart, fn_format_total_with_cent } from '../../misc/sc_misc';

// estado inicial
const checkout_dataini = {
    terms_link: null,
    cont_shipping_types: 0,
    shipping_types: [],
    showShippingTypeStep: true,
    showDeliveryStep: false,
    showPickerStep: false,
    showPaymentStep: false,
    showDeliveryButton: false,
    showPickerButton: false,
    showPaymentButton: false,
    cont_cities: 0,
    cities: [],
    reference: '',
    transaction: {}
};

// types
const SET_IN_CART = 'SET_IN_CART';
const GET_CURRENT_TRANSACTION = 'GET_CURRENT_TRANSACTION';
const GET_TRANSACTION_REDIRECT = 'GET_TRANSACTION_REDIRECT';
const RESET_TRANSACTION_STATUS = 'RESET_TRANSACTION_STATUS';
const GET_ACCEPTANCE_TOKEN = 'GET_ACCEPTANCE_TOKEN';
const GET_FINANCIAL_ENTITIES = 'GET_FINANCIAL_ENTITIES';
const GET_SHIPPING_TYPES = 'GET_SHIPPING_TYPES';
const SHOW_SHIPPING_NEXT_BUTTON = 'SHOW_SHIPPING_NEXT_BUTTON';
const SHOW_CHECKOUT_STEP = 'SHOW_CHECKOUT_STEP';
const GET_CITIES = 'GET_CITIES';
const CREATE_TRANSACTION = 'CREATE_TRANSACTION';

// reducer 
export default function checkoutReducer(state = checkout_dataini, action) {
    switch (action.type) {
        case SET_IN_CART:
        case GET_CURRENT_TRANSACTION:
        case GET_TRANSACTION_REDIRECT:
        case RESET_TRANSACTION_STATUS:
        case GET_ACCEPTANCE_TOKEN:
        case GET_FINANCIAL_ENTITIES:
        case GET_SHIPPING_TYPES:
        case SHOW_SHIPPING_NEXT_BUTTON:    
        case SHOW_CHECKOUT_STEP:    
        case GET_CITIES:
        case CREATE_TRANSACTION:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export const fn_setInCartInfo = (key, value) => async (dispatch, getState) => {    
    /*const { cont_shipping_types, shipping_types, showShippingTypeStep, showDeliveryStep, showPickerStep, showPaymentStep, showDeliveryButton, showPickerButton, showPaymentButton, cont_cities, cities } = getState().checkout_state;

    cart_info[key] = value;*/
    fn_set_cart(key, value);

    /*dispatch({
        type: SET_IN_CART,
        payload: {
            cont_shipping_types,
            shipping_types,
            showShippingTypeStep,
            showDeliveryStep,
            showPickerStep,
            showPaymentStep,
            showDeliveryButton,
            showPickerButton,
            showPaymentButton,
            cont_cities,
            cities
        }
    });*/
};

export const fn_getCurrentTransaction = () => async (dispatch) => { 
    let transactionData = {};  
    let id = fn_get_cart("_id");
    if(id){
        transactionData = await axios.get(`${glb._payment}wompi-get-transactions?lambdaKey=${glb._lambdaKey}&_id=${id}`); 
    }
    dispatch({
        type: GET_CURRENT_TRANSACTION,
        payload: {
            transaction: transactionData.data
        }
    });
};

export const fn_getTransactionRedirect = () => async (dispatch) => { 
    let id = fn_get_cart("transaction_id");
    if(id){
        const rst = await axios.get(`${glb._wompiEndpoint}transactions/${id}`);
        fn_set_cart("transaction_status", rst.data.data.status);
        if(rst.data.data.payment_method && rst.data.data.payment_method.extra){
            fn_set_cart("transaction_redirect", rst.data.data.payment_method.extra.async_payment_url);
        }
    }
    
    dispatch({
        type: GET_TRANSACTION_REDIRECT,
        payload: {}
    });
};

export const fn_resetCurrentTransactionStatus = () => async (dispatch, getState) => { 
    const { transaction } = getState().checkout_state;
    await axios.get(`${glb._payment}wompi-reset-transaction-status?lambdaKey=${glb._lambdaKey}&_id=${transaction._id}`); 
    dispatch({
        type: RESET_TRANSACTION_STATUS,
        payload: {}
    });
};

export const fn_getWompiFinancialEntities = () => async (dispatch, getState) => { 
    const { transaction } = getState().checkout_state;

    try {
        if(!localStorage.getItem('FINANCIAL_ENTITIES')){
            const rs = await axios.get(`${glb._wompiEndpoint}pse/financial_institutions`, {
                headers: {
                  'authorization': 'Bearer '+glb._wompiPub
                }
            });
            console.log(rs.data);
            localStorage.setItem('FINANCIAL_ENTITIES', JSON.stringify(rs.data.data));
        }
    } catch (error) {
        console.error("Error fn_getWompiFinancialEntities: ", error.response.data);
        localStorage.setItem('FINANCIAL_ENTITIES', '[]');
    }
    dispatch({
        type: GET_FINANCIAL_ENTITIES,
        payload: { 
            transaction
        }
    });
};

export const fn_getWompiAcceptanceToken = () => async (dispatch, getState) => { 
    const { transaction } = getState().checkout_state;

    try {
        const rs = await axios.get(`${glb._wompiEndpoint}merchants/${glb._wompiPub}`);
        console.log(rs.data.data);
        fn_set_cart('terms_link', rs.data.data.presigned_acceptance.permalink);
        fn_set_cart('acceptance_token', rs.data.data.presigned_acceptance.acceptance_token);
    } catch (error) {
        console.error("Error fn_getWompiAcceptanceToken: ", error.response.data);
        fn_set_cart('terms_link', null);
        fn_set_cart('acceptance_token', null);
    }
    dispatch({
        type: GET_ACCEPTANCE_TOKEN,
        payload: { 
            transaction
        }
    });
};

export const fn_getShippingTypes = () => async (dispatch, getState) => {    
    const { shipping_types, transaction } = getState().checkout_state;

    try {
        
        const rs = await axios.get(`${glb._benf}${glb._api}${glb._user}eatc_sale_delivery_methods?_id=_*`);
        console.log(rs.data);
        //rs.data.res[0]["eatc-require_delivery_address"] = "si"; //TODO: tmp flag for dev
        dispatch({
            type: GET_SHIPPING_TYPES,
            payload: {
                cont_shipping_types: rs.data.cont,
                shipping_types: rs.data.res,
                showShippingTypeStep: true,
                showDeliveryStep: false,
                showPickerStep: false,
                showPaymentStep: false,
                showDeliveryButton: false,
                showPickerButton: false,
                showPaymentButton: false,
                transaction
            }
        });
    } catch (error) {
        console.error("Error fn_getShippingTypes: ", error.response.data);
        dispatch({
            type: GET_SHIPPING_TYPES,
            payload: { 
                cont_shipping_types: 0,
                shipping_types,
                showShippingTypeStep: true,
                showDeliveryStep: false,
                showPickerStep: false,
                showPaymentStep: false,
                showDeliveryButton: false,
                showPickerButton: false,
                showPaymentButton: false,
                transaction
            }
        });
    }
};

export const fn_showShippingNextButton = (require_delivery_address, require_picker_data) => async (dispatch, getState) => {  
    let deliveryButton = false;
    let pickerButton = false;
    if(require_delivery_address=="si"){
        deliveryButton = true;
    } else if(require_picker_data=="si"){
        pickerButton = true;
    }
    dispatch({
        type: SHOW_SHIPPING_NEXT_BUTTON,
        payload: {
            showDeliveryButton: deliveryButton,
            showPickerButton: pickerButton,
            showPaymentButton: true
        }
    });
};

export const fn_showCheckoutStep = (step) => async (dispatch, getState) => {    
    const { showDeliveryButton, showPickerButton } = getState().checkout_state;

    dispatch({
        type: SHOW_CHECKOUT_STEP,
        payload: {
            showShippingTypeStep: step==="shipping_type",
            showDeliveryStep: step==="delivery_address",
            showPickerStep: step==="picker_data",
            showPaymentStep: step==="payment",
            showDeliveryButton,
            showPickerButton
        }
    });
};

export const fn_getCities = () => async (dispatch, getState) => {    
    const { showDeliveryButton, showPickerButton, cities } = getState().checkout_state;

    try {
        const rs = await axios.get(`${glb._gov}${glb._get}co/getpuntos?table=eatc_municipalities&fieldname=eatc-lat,eatc-lon&fieldvalue=${getLat()},${getLng()}&showfield=eatc-city&km=2`);
        console.log(rs.data);
        dispatch({
            type: GET_CITIES,
            payload: {
                showDeliveryButton,
                showPickerButton,
                cont_cities: rs.data.cont,
                cities: fn_reduce_list(rs.data.res, "eatc-city")
            }
        });
    } catch (error) {
        console.error("Error fn_getCities: ", error.response.data);
        dispatch({
            type: GET_SHIPPING_TYPES,
            payload: { 
                showDeliveryButton,
                showPickerButton,
                cont_cities: 0,
                cities
            }
        });
    }
};

export const fn_createTransaction = () => async (dispatch, getState) => {    
    const { cont_shipping_types, shipping_types, showShippingTypeStep, showDeliveryStep, showPickerStep, showPaymentStep, showDeliveryButton, showPickerButton, showPaymentButton, cont_cities, cities } = getState().checkout_state;

    try {
        let cart_info = fn_get_cart();
        let params = "lambdaKey="+glb._lambdaKey+"&_id="+cart_info['_id']+"&token="+localStorage.getItem('TOKEN');

        for (var [key, value] of Object.entries(cart_info)) {
            if(key=="detail"){
                params += "&n="+value.length;
                for(let i=0; i<value.length; i++){
                    let reference = value[i];
                    params += "&reference"+i+"_id="+reference['_id'];
                    params += "&reference"+i+"_name="+reference['eatc-odd_name'];
                    params += "&reference"+i+"_price="+reference['eatc-odd_sale_unit_price'];
                    params += "&reference"+i+"_quantity="+reference['eatc-odd_quantity'];
                    params += "&reference"+i+"_discount=0";
                    params += "&reference"+i+"_tax=0";
                }
            } else if(key=="user"){ 
                params += "&user_uid="+value.uid+"&user_name="+value.name+"&user_email="+value.email+"&user_avatar="+value.avatar;
            } else if(key!="_id"){
                params += "&"+key+"="+value;
            }
        }

        const rs = await axios.get(`${glb._payment}wompi-create-transaction?${params}`);
        console.log(rs);        
    } catch (error) {
        console.error("Error fn_createTransaction: ", error.response.data);
    }
    
    dispatch({
        type: CREATE_TRANSACTION,
        payload: {
            cont_shipping_types,
            shipping_types,
            showShippingTypeStep,
            showDeliveryStep,
            showPickerStep,
            showPaymentStep,
            showDeliveryButton,
            showPickerButton,
            showPaymentButton,
            cont_cities,
            cities/*,
            reference: _id*/
        }
    });
};

export const fn_processTransaction = (method_info) => async (dispatch, getState) => {    
    const { cont_shipping_types, shipping_types, showShippingTypeStep, showDeliveryStep, showPickerStep, showPaymentStep, showDeliveryButton, showPickerButton, showPaymentButton, cont_cities, cities } = getState().checkout_state;

    try {  
        let cart_info = fn_get_cart();

        let jsonparams = {}; 
        let performPayment = false;
        let methodparams = {};
        let card_token = '';
        if(cart_info['payment_method']=='tarjeta'){
            if(method_info.card_month<10){
                method_info.card_month = '0'+method_info.card_month;
            }
            jsonparams = {
                "number": method_info.card_number,
                "cvc": method_info.card_cvc,
                "exp_month": method_info.card_month,
                "exp_year": method_info.card_year,
                "card_holder": method_info.card_name
            };
            
            const rsc = await axios.post(`${glb._wompiEndpoint}tokens/cards`, jsonparams, {
                headers: {
                  'authorization': 'Bearer '+glb._wompiPub,
                  'Content-Type': 'application/json'
                }
            });
            console.log(rsc.data.data);
            if(rsc.data.status=='CREATED'){
                methodparams = {
                    "type": "CARD",
                    "installments": 1,
                    "token": rsc.data.data.id
                };
                card_token = rsc.data.data.id;
                performPayment = true;
            }
        } else if(cart_info['payment_method']=='transferencia'){
            methodparams = {
                "type": "BANCOLOMBIA_TRANSFER",
                "user_type": "PERSON",
                "payment_description": "No. "+cart_info['_id']
            };
            if(glb._wompiEndpoint.indexOf('sandbox')!=-1){
                methodparams["sandbox_status"] = "APPROVED";
            }
            performPayment = true;
        } else if(cart_info['payment_method']=='nequi'){
            methodparams = {
                "type": "NEQUI",
                "phone_number": method_info.mobile_number
            };
            performPayment = true;
        } else if(cart_info['payment_method']=='pse'){
            methodparams = {
                "type": "PSE",
                "user_type": parseInt(method_info.person_type),
                "user_legal_id_type": method_info.document_type,
                "user_legal_id": method_info.document_number,
                "financial_institution_code": method_info.bank || '1',
                "payment_description": "No. "+cart_info['_id']
            };
            performPayment = true;
        }

        if(performPayment){
            jsonparams = {
                "acceptance_token": cart_info["acceptance_token"],
                "amount_in_cents": parseInt(fn_format_total_with_cent(cart_info["total"])),
                "currency": "COP",
                "customer_email": cart_info["user"]["email"],
                "reference": cart_info["_id"],
                "payment_method": methodparams
            };

            const rst = await axios.post(`${glb._wompiEndpoint}transactions`, jsonparams, {
                headers: {
                  'authorization': 'Bearer '+glb._wompiPub,
                  'Content-Type': 'application/json'
                }
            });
            console.log(rst.data.data); 

            fn_set_cart('transaction_id', rst.data.data.id);
            fn_set_cart('transaction_status', rst.data.data.status);

            let params = "lambdaKey="+glb._lambdaKey+"&_id="+cart_info['_id']+"&transaction_id="+rst.data.data.id+"&card_token="+card_token+"&token="+localStorage.getItem('TOKEN');

            for (var [key, value] of Object.entries(cart_info)) {
                if(key=="detail"){
                    params += "&n="+value.length;
                    for(let i=0; i<value.length; i++){
                        let reference = value[i];
                        params += "&reference"+i+"_id="+reference['_id'];
                        params += "&reference"+i+"_name="+reference['eatc-odd_name'];
                        params += "&reference"+i+"_price="+reference['eatc-odd_sale_unit_price'];
                        params += "&reference"+i+"_quantity="+reference['eatc-odd_quantity'];
                        params += "&reference"+i+"_discount=0";
                        params += "&reference"+i+"_tax=0";
                    }
                } else if(key=="user"){ 
                    params += "&user_uid="+value.uid+"&user_name="+value.name+"&user_email="+value.email+"&user_avatar="+value.avatar;
                } else if(key!="_id"){
                    params += "&"+key+"="+value;
                }
            }

            const rs = await axios.get(`${glb._payment}wompi-create-transaction?${params}`);
            console.log(rs);                    
        }
    } catch (error) {
        console.error("Error fn_createTransaction: ", error);
    }
    
    dispatch({
        type: CREATE_TRANSACTION,
        payload: {
            cont_shipping_types,
            shipping_types,
            showShippingTypeStep,
            showDeliveryStep,
            showPickerStep,
            showPaymentStep,
            showDeliveryButton,
            showPickerButton,
            showPaymentButton,
            cont_cities,
            cities/*,
            reference: _id*/
        }
    });
};
