import React, { Component } from "react";
import { Link } from "react-router-dom";
import YourOrdersService from "../../services/YourOrdersService";
//import CardInfoImageVer from '../cards/CardInfoImageVer';
//import CardInfoOrderVer from '../cards/CardInfoOrderVer';
import CardOrderVer from "../cards/CardOrderVer";
import CardOrderVertical from "../cards/CardOrderVertical";
import CircularSmall from "../preloader/CircularSmall";
import globales from "../../config/config";

export default class YourOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgressBarOnProgress: true,
      showProgressBarOnDelivered: true,
      orders_on_progress: [],
      orders_on_delivered: [],
    };
    console.log("...ESTAS EN /YourOrders CONSTRUCTOR", props);
  }

  componentDidMount() {
    console.log("...ESTAS EN /YourOrders");
    let token = localStorage.getItem('TOKEN');
    this.getOrdersByCode(token);
  }

  getOrdersByCode = (code) => {
    YourOrdersService.getOrders(code)
      .then((orders) => {
        console.log("Ocurrió un exito -> orders : ", orders);
        var getOrdersByStateOnProgress = this.filterOrdersByStateOnProgress(
          orders
        );

        var getOrdersByStateOnProgressLength =
          getOrdersByStateOnProgress.length;
        //getOrdersByStateOnProgressLength = 0;

        console.log(
          "getOrdersByStateOnProgress : ",
          getOrdersByStateOnProgress,
          "getOrdersByStateOnProgressLength : ",
          getOrdersByStateOnProgressLength
        );

        if (getOrdersByStateOnProgressLength > 0) {
          if (getOrdersByStateOnProgressLength === 1) {
            console.log(
              "%c....EXISTE POR LO MENOS UNA ORDEN",
              globales.consoleStyleSuccess
            );
            this.setState({
              orders_on_progress: getOrdersByStateOnProgress,
              showProgressBarOnProgress: !this.state.showProgressBarOnProgress,
            });
            console.log("@ orders", orders);
            console.log(
              "@ this.state.orders_on_progress",
              this.state.orders_on_progress
            );
          } else {
            console.log(
              "%c....EXISTE MAS DE UNA ORDEN",
              globales.consoleStyleSuccess
            );
            var getOrdersByDateOnProgress = this.sortOrdersByDateAsc(
              getOrdersByStateOnProgress
            );
            console.log(
              "getOrdersByDateOnProgress : ",
              getOrdersByDateOnProgress
            );
            this.setState({
              orders_on_progress: getOrdersByDateOnProgress,
              showProgressBarOnProgress: !this.state.showProgressBarOnProgress,
            });

            console.log("@ orders", orders);
            console.log(
              "@ this.state.orders_on_progress",
              this.state.orders_on_progress
            );
          }
        } else {
          console.log(
            "%c....NO EXISTE NINGUNA ORDEN",
            globales.consoleStyleError
          );
          this.setState({
            orders_on_progress: [],
            showProgressBarOnProgress: !this.state.showProgressBarOnProgress,
          });
        }

        var getOrdersByStateDelivered = this.filterOrdersByStateDelivered(
          orders
        );

        var getOrdersByStateDeliveredLength = getOrdersByStateDelivered.length;
        //getOrdersByStateDeliveredLength = 0;

        console.log(
          "getOrdersByStateDelivered : ",
          getOrdersByStateDelivered,
          "getOrdersByStateDeliveredLength : ",
          getOrdersByStateDeliveredLength
        );

        if (getOrdersByStateDeliveredLength > 0) {
          if (getOrdersByStateDeliveredLength === 1) {
            console.log(
              "%c....EXISTE POR LO MENOS UNA ORDEN DELIVERED",
              globales.consoleStyleSuccess
            );
            this.setState({
              orders_on_delivered: getOrdersByStateDelivered,
              showProgressBarOnDelivered: !this.state
                .showProgressBarOnDelivered,
            });
            console.log("@ orders", orders);
            console.log(
              "@ this.state.orders_on_delivered",
              this.state.orders_on_delivered
            );
          } else {
            console.log(
              "%c....EXISTE MAS DE UNA ORDEN DELIVERED",
              globales.consoleStyleSuccess
            );
            var getOrdersByDateDelivered = this.sortOrdersByDateAsc(
              getOrdersByStateDelivered
            );
            console.log(
              "getOrdersByDateDelivered : ",
              getOrdersByDateDelivered
            );
            this.setState({
              orders_on_delivered: getOrdersByDateDelivered,
              showProgressBarOnDelivered: !this.state
                .showProgressBarOnDelivered,
            });
            console.log("@ orders", orders);
            console.log(
              "@ this.state.orders_on_delivered",
              this.state.orders_on_delivered
            );
          }
        } else {
          console.log(
            "%c....NO EXISTE NINGUNA ORDEN DELIVERED",
            globales.consoleStyleSuccess
          );
          this.setState({
            orders_on_delivered: [],
            showProgressBarOnDelivered: !this.state.showProgressBarOnDelivered,
          });
        }
      })
      .catch((err) => {
        console.log("Ocurrió un error -> ", err);
        this.setState({
            orders_on_progress: [],
            orders_on_delivered: [],
            showProgressBarOnProgress: !this.state.showProgressBarOnProgress,
            showProgressBarOnDelivered: !this.state.showProgressBarOnDelivered,
          });
      });
  };

  filterOrdersByStateOnProgress = (orders) => {
    // with return
    return orders.filter((order) => order["eatc-state"] === "paid_out"); // return implicito
  };

  filterOrdersByStateDelivered = (orders) => {
    // with return delivered,partially_refund,refund
    return orders.filter(
      (order) =>
        order["eatc-state"] === "delivered" ||
        order["eatc-state"] === "partially_refund" ||
        order["eatc-state"] === "refund"
    ); // return implicito
  };

  sortOrdersByDateDesc = (orders) => {
    // with return
    return orders.sort(
      (a, b) => new Date(b["eatc-datetime"]) - new Date(a["eatc-datetime"])
    ); // return implicito
  };

  sortOrdersByDateAsc = (orders) => {
    // with return
    return orders.sort(
      (a, b) => new Date(a["eatc-datetime"]) - new Date(b["eatc-datetime"])
    ); // return implicito
  };

  renderOrdersOnProgress() {
    return this.state.orders_on_progress.map((orders, index) => (
      <CardOrderVer
        //<CardInfoOrderVer
        key={index}
        order={orders}
        order_verification_code={orders["eatc-verification_code"]}
        eatc_pod_name={orders["eatc-pod_name"]}
        eatc_pod_address={orders["eatc-pod_address"]}
        km={orders["km"]}
        eatc_datetime={orders["eatc-datetime"]}
        horario={orders["horario"]}
        eatc_total_price={orders["eatc-total_price"]}
      />
    ));
  }

  renderOrdersVerticalOnProgress() {
    var ordersOnProgressExist = this.state.orders_on_progress.length;
    console.log("test", ordersOnProgressExist);
    if (ordersOnProgressExist > 0) {
      return this.state.orders_on_progress.map((orders, index) => (
        <CardOrderVertical
          //<CardInfoOrderVer
          key={index}
          order={orders}
          order_verification_code={orders["eatc-verification_code"]}
          eatc_pod_name={orders["eatc-pod_name"]}
          eatc_pod_address={orders["eatc-pod_address"]}
          km={orders["km"]}
          eatc_datetime={orders["eatc-datetime"]}
          horario={orders["horario"]}
          eatc_total_price={orders["eatc-total_price"]}
        />
      ));
    } else {
      return (
        <span>
          <strong>
            <b>No tienes pedidos registrados en la plataforma</b>
          </strong>
        </span>
      );
    }
  }

  renderOrdersVerticalOnDelivered() {
    var ordersOnDeliveredExist = this.state.orders_on_delivered.length;
    console.log("test", ordersOnDeliveredExist);
    if (ordersOnDeliveredExist > 0) {
      return this.state.orders_on_delivered.map((orders, index) => (
        <CardOrderVertical
          //<CardInfoOrderVer
          key={index}
          order={orders}
          order_verification_code={orders["eatc-verification_code"]}
          eatc_pod_name={orders["eatc-pod_name"]}
          eatc_pod_address={orders["eatc-pod_address"]}
          km={orders["km"]}
          eatc_datetime={orders["eatc-datetime"]}
          horario={orders["horario"]}
          eatc_total_price={orders["eatc-total_price"]}
        />
      ));
    } else {
      return (
        <span>
          <strong>
            <b>No tienes pedidos registrados en la plataforma</b>
          </strong>
        </span>
      );
    }
  }

  render() {
    //const color = "#00B3FE";
    //const colorWhite = "#FFFFFF";
    const divStyleZ = { zIndex: "1000" };
    //const divStyleMTpN24 = { marginTop: "-24px" };
    //const divStyleColorBlack = { color: "#000000", marginLeft: "-5px" };
    const divStyleMBn24 = { marginBottom: "-24px" };
    //const divStyleWidth50MarginCero = { width: "50%", margin: "0" };
    //const divStyleMTp18 = { marginTop: "18px" };
    const divStyleMTp19MBnN10 = { marginTop: "-19px", marginBottom: "-10px" };
    const divStyleMTp16 = { marginTop: "16px" };
    const divStyleTitle = {
      height: "3rem",
      lineHeight: "3rem",
      width: "90%",
      fontSize: "16px",
      margin: "24px 0 16px 0",
      padding: "0 16px",
      textAlign: "center",
    };
    /*const divStyleSubTitle = {
            height: "3rem",
            lineHeight: "3rem",
            width: "90%",
            fontSize: "16px",
            margin: "24px 0 16px 0",
            padding: "0 16px"
        }*/
    return (
      <div className="tinta-bg">
        <div className="blanco-bg shadow-8dp" style={divStyleZ}>
          <div className="row" style={divStyleMBn24}>
            <div className="col s1 m1 l1">
              <div style={divStyleMTp16}>
                <Link
                  to="#"
                  className=""
                  onClick={() => {
                    this.redirectToTargetBack();
                  }}
                >
                  <i className="material-icons">close</i>
                </Link>
              </div>
            </div>
            <div className="col s11 m11 l11">
              {/* style={divStyleMTn19} */}
              <div className="input-field" style={divStyleMTp19MBnN10}>
                <h4
                  className="brand-logo blue-text text-darken-2"
                  style={divStyleTitle}
                >
                  Tus pedidos
                </h4>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="blanco-bg shadow-8dp" style={divStyleZ}>
                    <div className="row" style={divStyleMBn24}>
                        <div className="col s12 m12 l12">
                            <div className="input-field" style={divStyleMTp19MBnN10}>
                                <h4 className="brand-logo blue-text text-darken-2" style={divStyleSubTitle}>EN CURSO</h4>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="container">
          <div className="row">
            <div className="col s12 m12 l12 div-perd-ped">
              {/* <!-- lista pedidos en curso --> */}
              <ul className="collection with-header">
                <li className="collection-header">
                  <h4>En curso</h4>
                  {this.state.showProgressBarOnProgress && <CircularSmall />}
                  {!this.state.showProgressBarOnProgress &&
                    this.renderOrdersVerticalOnProgress()}
                </li>
              </ul>
              <ul className="collection with-header">
                <li className="collection-header">
                  <h4>Entregados</h4>
                  {this.state.showProgressBarOnDelivered && <CircularSmall />}
                  {!this.state.showProgressBarOnProgress &&
                    this.renderOrdersVerticalOnDelivered()}
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="container">
                    <div className="row" style={divStyleMTpN24}>
                        {this.state.showProgressBarOnProgress && <CircularSmall />}
                        {!this.state.showProgressBarOnProgress && this.renderOrdersOnProgress()}
                    </div>
                </div> */}
        {/* <div className="blanco-bg shadow-8dp" style={divStyleZ}>
                    <div className="row" style={divStyleMBn24}>
                        <div className="col s12 m12 l12">
                            <div className="input-field" style={divStyleMTp19MBnN10}>
                                <h4 className="brand-logo blue-text text-darken-2" style={divStyleSubTitle}>ENTREGADOS</h4>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    );
  }
}
