import React from 'react';
import { withRouter } from "react-router-dom";
// hooks de react-redux
import { useDispatch, useSelector } from 'react-redux';
import { fn_traeProdsOrden, fn_traeProdStock, fn_cambiaInvPrd, fn_cambiaCantProdOrd } from './carrocompDuck';
import { fn_formato_numero } from '../../misc/sc_misc';
//import stycss from '../../css/idr.module.css';
// Materialize e imagen x defecto
//import imgDef from "../../img/icn_amabilidad.png";
import noimage from "../../img/noimage.jpg";
import { divStyleZ, divStyleMTpN24, divStyleColorBlack, divStyleMBn24, divStyleWidth50MarginCero, divStyleMTp18, divStyleMTp19MBnN10, divStyleMTp16, divStyleMaxWidth, divStyleTitle } from '../../config/config';
// import { startCountCartOut } from "../../services/TimerCartOut";

const Carrocompras = (props) => {   
    const dispatch = useDispatch();
    const cont = useSelector(store => store.carrocomp_state.cont);
    const lstprods = useSelector(store => store.carrocomp_state.lstprods);
    const totales = useSelector(store => store.carrocomp_state.totales);

    console.log(">>>> prods en lstprods: ", cont);

    // trayendo la lista de productos de la orden
    React.useEffect(() => {
        const fn_ProdsOrden = async () => {
            try {
                await dispatch(fn_traeProdsOrden());
            } catch (error) {
                console.error("Error trayendo los productos de la orden " + error);
            }
        }
        fn_ProdsOrden();
    }, [dispatch]);

    // funcion para salir y hacer checkout
    const fn_goToCheckout = async() => {
        console.log("Saliendo del detalle de producto");
        try {
            props.history.push('/checkout');
        } catch (error) {
            console.error("Error redireccionando a checkout");
        }
    };

    // funcion para salir del carro de compras al dashboard del Punto de venta o al dashboard general
    const fn_backToDashPod = () => {
        console.log(">>>> Prods length: " + cont);
        if (cont === 0) {
            props.history.push('/dashboard');
        } else {
            props.history.push({ 
                pathname: "/dashboardsale",   
                state: { 
                    km_actual: localStorage.getItem("pod_km_actual"), 
                    point: lstprods[0] 
                } 
            });
        }
    };

    // funcion para cambiar la cantidad de un producto en la orden actual
    const fn_cambiaCantOrd = async (idreg, tipo) => {
        console.log("cambiando la cantidad del producto " + idreg);
        try {
            // trae el stock actual.
            await dispatch(fn_traeProdStock(idreg));
            // Cambia la cantidad del stock del producto en eatc-sale.               
            await dispatch(fn_cambiaInvPrd(idreg, tipo));
            // si la cantidad actual despues de cambiar el inventario del producto es mayor a cero, se debe actualizar la cantidad en la orden, si es cero se debe borrar  
            await dispatch(fn_cambiaCantProdOrd(idreg));
            // Trae la lista de productos de la orden con la cantidad del producto actualizado. 
            await dispatch(fn_traeProdsOrden());
        } catch (error) {
            console.error("Error intentando cambiar la cantidad " + error);
        }
    };

    const color = "#00B3FE";
    //const colorWhite = "#FFFFFF";

    // renderizando la lista de productos de la orden.
    return (
        <div className="tinta-bg">
            <div className="blanco-bg shadow-8dp" style={divStyleZ}>
                <div className="row" style={divStyleMBn24}>
                    <div className="col s1 m1 l1">
                        <div style={divStyleMTp16}>
                            <i className="material-icons" onClick={() => fn_backToDashPod()}>close</i>                            
                        </div>
                    </div>
                    <div className="col s11 m11 l11">
                        {/* style={divStyleMTn19} */}
                        <div className="input-field" style={divStyleMTp19MBnN10}>
                            <h4 className="brand-logo blue-text text-darken-2" style={divStyleTitle}>Carrito de compras</h4>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- vista carro de compras app sale --> */}
            {/* <!-- card detalle pod --> */}
            <div className="container">
                <div className="row" style={divStyleMTpN24}>
                    <div className="col s12 m12 l12">
                        <div className="card horizontal full card-top">
                            <div className="card-stacked">
                                <div className="card-content">
                                    <span className="card-title-med esp-top-pequeno">Nombre del punto de venta</span>
                                    <span className="txt-meta">Dirección del restaurante</span>
                                    <span className="txt-meta txt-accent">a 10KM</span>
                                    <span className="txt-meta txt-primary esp-top-miniatura">LU 8:00 AM - 4:00 PM<br />MA 8:00 AM - 4:00 PM</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- list --> */}
            <div className="container-full">
                <div className="row">
                    <div className="col s12 m12 l12">
                        <table className="striped">
                            {cont > 0 && (
                                <tbody>
                                    { cont > 0 &&
                                        lstprods.map((prd, index) => (
                                            <tr key={prd["_id"]}>
                                                <td>
                                                    <img src={prd["eatc-odd_image"] === "" ? noimage : prd["eatc-odd_image"]} alt="" className="img-lst-item" />
                                                </td>
                                                <td>
                                                    <span className="list-title-med esp-top-pequeno">{prd["eatc-odd_name"]}</span>
                                                    <span className="list-precio-oficial">${prd["eatc-odd_sale_unit_price"]}</span>
                                                </td>
                                                <td>
                                                    <div className="btn-group-small" role="group">
                                                        <button className="btn primario-btn" onClick={() => fn_cambiaCantOrd(prd["_id"], 'resta')}><i className="small material-icons prefix">remove</i></button>
                                                        <input type="number" value={prd["eatc-odd_quantity"]} readOnly="readOnly" style={divStyleMaxWidth}/>
                                                        <button className="btn primario-btn" onClick={() => fn_cambiaCantOrd(prd["_id"], 'suma')}><i className="small material-icons">add</i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <td colSpan="2">
                                            <p className="left txt-meta">Subtotal</p>
                                        </td>
                                        <td>
                                            <h4 className="right">${fn_formato_numero(totales.subtotal, 0, '.')}</h4>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        <div className="div-alerta-codigo">
                            <ul className="collection">
                                <li className="collection-item avatar">
                                    <i className="material-icons circle white" style={divStyleColorBlack}>card_giftcard</i>
                                    <span className="title"><input style={divStyleWidth50MarginCero} type="text" defaultValue="Cupón Dcto" /></span>
                                    <a href="#!" className="secondary-content btn-small primario-btn shadow-2dp waves-effect waves-light"
                                        ref={(el) => {
                                            if (el) {
                                                el.style.setProperty(
                                                    "background-color",
                                                    color,
                                                    "important"
                                                );
                                            }
                                        }}>Aplicar</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="page-footer">
                <div className="container">
                    <div className="row">
                    {cont > 0 &&
                            <>
                        <div className="col s6 m6 l6">
                            <h4 className="left" style={divStyleMTp18}><span className="muted-color">Total: </span>${fn_formato_numero(totales.total, 0, '.')}</h4>
                        </div>
                        <div className="col s6 m6 l6">
                            <button className="btn primario-btn shadow-2dp waves-effect waves-light width-100" onClick={() => fn_goToCheckout()}>Pagar</button>
                        </div>
                        </>
                    }
                    </div>
                </div>
            </footer>
            {/* <!-- vista carro de compras app sale --> */}
        </div>
    );
};

export default withRouter(Carrocompras)
