import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class CarritoCompras extends Component {
    render() {
        const color = "#00B3FE";
        //const colorWhite = "#FFFFFF";
        const divStyleZ = { zIndex: "1000" };
        const divStyleMTpN24 = { marginTop: "-24px" };
        const divStyleColorBlack = { color: "#000000", marginLeft: "-5px" };
        const divStyleMBn24 = { marginBottom: "-24px" };
        const divStyleWidth50MarginCero = { width: "50%", margin: "0" };
        const divStyleMTp18 = { marginTop: "18px" };
        const divStyleMTp19MBnN10 = { marginTop: "-19px", marginBottom: "-10px" };
        const divStyleMTp16 = { marginTop: "16px" };
        const divStyleTitle = {
            height: "3rem",
            lineHeight: "3rem",
            width: "90%",
            fontSize: "16px",
            margin: "24px 0 16px 0",
            padding: "0 16px",
            textAlign: "center"}
        return (
            <div className="tinta-bg">
                <div className="blanco-bg shadow-8dp" style={divStyleZ}>
                    <div className="row" style={divStyleMBn24}>
                        <div className="col s1 m1 l1">
                            <div style={divStyleMTp16}>
                                <Link
                                    to="#"
                                    className=""
                                    onClick={() => {
                                        this.redirectToTargetBack();
                                    }}
                                >
                                    <i className="material-icons">close</i>
                                </Link>
                            </div>
                        </div>
                        <div className="col s11 m11 l11">
                            {/* style={divStyleMTn19} */}
                            <div className="input-field" style={divStyleMTp19MBnN10}>
                                <h4 className="brand-logo blue-text text-darken-2" style={divStyleTitle}>Carrito de compras</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- vista carro de compras app sale --> */}
                
                    {/* <!-- card detalle pod --> */}
                    <div className="container">
                        <div className="row" style={divStyleMTpN24}>
                            <div className="col s12 m12 l12">
                                <div className="card horizontal full card-top">
                                    <div className="card-stacked">
                                        <div className="card-content">
                                            <span className="card-title-med esp-top-pequeno">Nombre del punto de venta</span>
                                            <span className="txt-meta">Dirección del restaurante</span>
                                            <span className="txt-meta txt-accent">a 10KM</span>
                                            <span className="txt-meta txt-primary esp-top-miniatura">LU 8:00 AM - 4:00 PM<br />MA 8:00 AM - 4:00 PM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- list --> */}
                    <div className="container-full">
                        <div className="row">
                            <div className="col s12 m12 l12">
                                <table className="striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img src="img/noimage.jpg" alt="noimage" className="img-lst-item" />
                                            </td>
                                            <td>
                                                <span className="list-title-med esp-top-pequeno">Nombre del punto de venta</span>
                                                <span className="list-precio-oficial">$7.000</span>
                                            </td>
                                            <td>
                                                <div className="btn-group-small" role="group">
                                                    <button className="btn primario-btn"><i className="small material-icons prefix">remove</i></button>
                                                    <input type="number" value="1" readOnly="readOnly" />
                                                    <button className="btn primario-btn"><i className="small material-icons">add</i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="img/noimage.jpg" alt="noimage" className="img-lst-item" />
                                            </td>
                                            <td>
                                                <span className="list-title-med esp-top-pequeno">Nombre del punto de venta</span>
                                                <span className="list-precio-oficial">$7.000</span>
                                            </td>
                                            <td>
                                                <div className="btn-group-small" role="group">
                                                    <button className="btn primario-btn"><i className="small material-icons prefix">remove</i></button>
                                                    <input type="number" value="1" readOnly="readOnly" />
                                                    <button className="btn primario-btn"><i className="small material-icons">add</i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="img/noimage.jpg" alt="noimage" className="img-lst-item" />
                                            </td>
                                            <td>
                                                <span className="list-title-med esp-top-pequeno">Nombre del punto de venta</span>
                                                <span className="list-precio-oficial">$7.000</span>
                                            </td>
                                            <td>
                                                <div className="btn-group-small" role="group">
                                                    <button className="btn primario-btn"><i className="small material-icons prefix">remove</i></button>
                                                    <input type="number" value="1" readOnly="readOnly" />
                                                    <button className="btn primario-btn"><i className="small material-icons">add</i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <p className="left txt-meta">Subtotal</p>
                                            </td>
                                            <td>
                                                <h4 className="right">$7.000</h4>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="div-alerta-codigo">
                                    <ul className="collection">
                                        <li className="collection-item avatar">
                                            <i className="material-icons circle white" style={divStyleColorBlack}>card_giftcard</i>
                                            <span className="title"><input style={divStyleWidth50MarginCero} type="text" defaultValue="Cupón Dcto" /></span>
                                            <a href="#!" className="secondary-content btn-small primario-btn shadow-2dp waves-effect waves-light"
                                                ref={(el) => {
                                                    if (el) {
                                                        el.style.setProperty(
                                                            "background-color",
                                                            color,
                                                            "important"
                                                        );
                                                    }
                                                }}>Aplicar</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="page-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col s6 m6 l6">
                                    <h4 className="left" style={divStyleMTp18}><span className="muted-color">Total: </span>$10.000</h4>
                                </div>
                                <div className="col s6 m6 l6">
                                    <button className="btn primario-btn shadow-2dp waves-effect waves-light width-100">Pagar</button>
                                </div>
                            </div>
                        </div>
                    </footer>
                
                {/* <!-- vista carro de compras app sale --> */}
            </div>
        )
    }
}
