//{{URL_entorno_beneficiarios}}/crypt/eatcloud/decrypt?table=eatc_sale_users&fieldname=eatc-email,eatc-user_name,eatc-name,eatc-doc_id,eatc-address,eatc-phone&filterfield_1=eatc-code&filtervalue_1={{eatc_sale_users.eatc-code}}
import axios from "axios";
import globales from "../config/config";

const BASE_URL_BENF = globales._benf;
/* const BASE_URL_DONA = globales._dona;

const API_DONA = axios.create({
  baseURL: BASE_URL_DONA,
}); */

const API_BENF = axios.create({
  baseURL: BASE_URL_BENF,
});

const PROFILE_ENDPOINTS = {
  API: globales._api,
  USER: globales._user,
  EATC_SALE_DECRYPT: "decrypt?",
  EATC_SALE_CRYPT: "crypt",
  EATC_SALE_USERS: "eatc_sale_users",
  TABLE: "table",
  FIELDNAME: "fieldname",
  FILTERFIELD1: "filterfield_1",
  FILTERVALUE1: "filtervalue_1",
  SLASH: "/",
  CONCAT: "&",
  EQUAL: "=",
  COMA: ",",
};

const ProfileService = {
  getProfile: (fieldname, filterfield_1, filtervalue_1) =>
    new Promise((resolve, reject) => {
      console.log("%c$...[ProfileService.js] Entrando a ImportantService.getProfile()", globales.consoleStyle);
      console.log("$...[ProfileService.js] ImportantService.getProfile (",
        "\n>> filterfield_1 :", filterfield_1,
        "\n>> filtervalue_1 :", filtervalue_1, ")");

      console.log("%c$...[ProfileService.js] ImportantService.getProfile " +
        "\n>> URL : " +
        BASE_URL_BENF + 
        PROFILE_ENDPOINTS.EATC_SALE_CRYPT +
        PROFILE_ENDPOINTS.SLASH +
        PROFILE_ENDPOINTS.USER +
        PROFILE_ENDPOINTS.EATC_SALE_DECRYPT +
        PROFILE_ENDPOINTS.TABLE +
        PROFILE_ENDPOINTS.EQUAL +
        PROFILE_ENDPOINTS.EATC_SALE_USERS +
        PROFILE_ENDPOINTS.CONCAT +
        PROFILE_ENDPOINTS.FIELDNAME +
        PROFILE_ENDPOINTS.EQUAL +
        fieldname +
        PROFILE_ENDPOINTS.CONCAT +
        PROFILE_ENDPOINTS.FILTERFIELD1 +
        PROFILE_ENDPOINTS.EQUAL +
        filterfield_1 +
        PROFILE_ENDPOINTS.CONCAT +
        PROFILE_ENDPOINTS.FILTERVALUE1 +
        PROFILE_ENDPOINTS.EQUAL +
        filtervalue_1,
        globales.consoleStyle);

      API_BENF.get(
          PROFILE_ENDPOINTS.EATC_SALE_CRYPT +
          PROFILE_ENDPOINTS.SLASH +
          PROFILE_ENDPOINTS.USER +
          PROFILE_ENDPOINTS.EATC_SALE_DECRYPT +
          PROFILE_ENDPOINTS.TABLE +
          PROFILE_ENDPOINTS.EQUAL +
          PROFILE_ENDPOINTS.EATC_SALE_USERS +
          PROFILE_ENDPOINTS.CONCAT +
          PROFILE_ENDPOINTS.FIELDNAME +
          PROFILE_ENDPOINTS.EQUAL +
          fieldname +
          PROFILE_ENDPOINTS.CONCAT +
          PROFILE_ENDPOINTS.FILTERFIELD1 +
          PROFILE_ENDPOINTS.EQUAL +
          filterfield_1 +
          PROFILE_ENDPOINTS.CONCAT +
          PROFILE_ENDPOINTS.FILTERVALUE1 +
          PROFILE_ENDPOINTS.EQUAL +
          filtervalue_1
        )
        .then((res) => {
          console.log("$...[ProfileService.js] \n>> res : ", res);
          console.log("$...[ProfileService.js] \n>> res.data : ", res.data);
          console.log("$...[ProfileService.js] \n>> res.data.res : ", res.data.res);
          console.log("$...[ProfileService.js] \n>> res.data.res[0] : ", res.data.res[0]);
          console.log("$...[ProfileService.js] \n>> res.status : ", res.status);
          console.log("$...[ProfileService.js] \n>> res.statusText : ", res.statusText);
          return res.data.res;
        })
        .then((data) => {
          console.log("%c$...[ProfileService.js] ImportantService.getProfile() SUCCESS",
            globales.consoleStyleSuccess);
          console.log("$...[ProfileService.js] \n>> data : ", data);
          console.log("$...[ProfileService.js] \n>> data[0] : ", data[0]);
          resolve(data[0]);
        })
        .catch((err) => {
          // Error 😨😨😨😨😨😨
          console.log("%c$...[ProfileService.js] ImportantService.getProfile() ERROR",
            globales.consoleStyleError);
          console.error("$...[ProfileService.js] \n>> ERROR : ", err);
          console.error("$...[ProfileService.js] \n>> ERROR.RESPONSE : ", err.response);
          // Error 😨
          if (err.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.error("$...[ProfileService.js] \n>> ERROR.RESPONSE.DATA : ", err.response.data);
            console.error("$...[ProfileService.js] \n>> ERROR.RESPONSE.STATUS : ", err.response.status);
            console.error("$...[ProfileService.js] \n>> ERROR.RESPONSE.HEADERS : ", err.response.headers);
          } else if (err.request) {
            /*
             * The request was made but no response was received, `err.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.error("$...[ProfileService.js] \n>> ERROR.REQUEST : ", err.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.error("$...[ProfileService.js] \n>> ERROR.MESSAGE : ", err.message);
          }
          console.error("$...[ProfileService.js] \n>> ERROR.CONFIG : ", err.config);
          reject(err);
        });
    }),
};

export default ProfileService;