import React, { Component } from "react";
import logo from "../../logo.svg";
import "../../App.css";
// Import Materialize
import M from "materialize-css";
import LinearIndeterminate from "../preloader/LinearIndeterminate";
import {
  isCitied,
  setCitied,
  getCitied,
  remCitied,
  isToken,
  setToken,
  getToken,
  getLat,
  getLng,
  isGeoLocated,
  setLocation,
  remLocation,
  isStated,
  remStated,
  setStated,
  getStated,
  isActualRadius,
  remActualRadius,
  setActualRadius,
  getActualRadius,
  remAll,
  isNearbyOffers,
  getNearbyOffers,
  isNearbyMessages,
  getNearbyMessages,
} from "../../services/auth";
import GeoService from "../../services/GeoService";
import LocationService from "../../services/LocationService";
import DashboardService from "../../services/DashboardService";
import { fn_genera_token } from '../../misc/sc_misc';
import globales from "../../config/config";

const consoleStyle = "background: #222; color: #bada55";
const consoleStyleSuccess = "background: #222; color: #00ff00";
const consoleStyleError = "background: #ff0000; color: #ffffff";
export class Splashscreen extends Component {

/* #region  CONSTRUCTOR ********************************************************/
/*******************************************************************************/
/*******************************************************************************/

  constructor(props) {
    super(props);
    console.log("%c...[Splashscreen.js] ESTAS EN /Splashscreen - constructor", globales.consoleStyle);
    this.state = {
      latitude: "",
      longitude: "",
      cities: "",
      state: "",
      actual_sale_radius_km: 0,
      showProgressBar: true,
    };
  }

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  CONSTRUCTOR *****************************************************/

/* #region  COMPONENTDIDMOUNT **************************************************/
/*******************************************************************************/
/*******************************************************************************/

  componentDidMount() {
    // Auto initialize all the things!
    M.AutoInit();
    //remAll();
    console.log("%c...[Splashscreen.js] ESTAS EN /Splashscreen", globales.consoleStyle);
    if (!isToken()) {
      console.log("%c...[Splashscreen.js] IS NOT Token", globales.consoleStyleStorageError);
      //setToken(this.generateToken(36));
      setToken(fn_genera_token(36));
      this.getMyLocation();
    } else {
      console.log("%c...[Splashscreen.js] IS Token" + 
        "\n>> Token : " + getToken(), globales.consoleStyleStorageSuccess);
      if (isNearbyMessages()) {
        console.log(
          "...[Splashscreen.js] ACTUAL MESSAGES EN EL LOCALSTORAGE",
          "\n>> getNearbyMessages() : ",JSON.parse(getNearbyMessages()),
          "\n>> TIPO", typeof getNearbyMessages());
        if (isNearbyOffers()) {
            console.log("...[Splashscreen.js] ACTUAL OFFERS EN EL LOCALSTORAGE",
              "\n>> getNearbyOffers() : ",JSON.parse(getNearbyOffers()),
              "\n>> TIPO : ", typeof getNearbyOffers());
            // REDIRIGIR A DASHBOARD !!
            this.redirectToTarget("/dashboard"); //DESCOMENTAR
        }
      }
    }
    //this.getFieldsMunicipios();
    //this.getMyLocation();
  }

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  COMPONENTDIDMOUNT ***********************************************/

/* #region  METODOS IMPORTANTES ************************************************/
/*******************************************************************************/
/*******************************************************************************/

/*******************************************************************************/

  redirectToTarget = (target) => {
    console.log("%c...[Splashscreen.js] REDIRIGIR A " + target, consoleStyle);
    this.props.history.push(target);
  };

/*******************************************************************************/

  redirectToTargetLatLng = (country, target) => {
    console.log("...[Splashscreen.js] REDIRIGIR A ", target, "\n Country : ", country);
    this.props.history.push({
      pathname: target,
      state: {
        country: country,
      },
    });
  };

/*******************************************************************************/

  generateToken = (length) => {
    console.log("%c...[Splashscreen.js] generateToken ( "+length+" )", globales.consoleStyle);
    return fn_genera_token(length);
  };

/*******************************************************************************/

  getMyLocation = () => {
    console.log("%c...[Splashscreen.js] OBTENIENDO GEOLOCALIZACION (ASINCRONO)", consoleStyle);
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          console.log("%c...[Splashscreen.js] GEOLOCALIZACION OBTENIDA (EXITO)", consoleStyleSuccess);

          // LATITUD Y LONGITUD DESDE EL DISPOSITIVO
          //var latitude = position.coords.latitude;
          //var longitude = position.coords.longitude;
          // LATITUD Y LONGITUD QUEMADAS PARA MEDELLIN
          var latitude = Number(6.251364029148493);
          var longitude = Number(-75.6014912552293);

          console.log("...[Splashscreen.js] GEOLOCALIZACION OBTENIDA (EXITO)",
            "\n>> position : ", position,
            "\n>> latitude : ", latitude,
            "\n>> longitude : ", longitude);
          console.log("%c...[Splashscreen.js] GUARDO EN EL STATE LATITUD-LONGITUD", consoleStyle);

          this.setState({
            latitude: latitude,
            longitude: longitude,
          });

          this.getLocationState(latitude, longitude);
        },
        (error) => {
          console.log("%c...[Splashscreen.js] GEOLOCALIZACION NO OBTENIDA (ERROR)", consoleStyleError);
          console.log("...[Splashscreen.js] GEOLOCALIZACION NO OBTENIDA (ERROR) \n>> error : ", error);
          
          console.log("%c...[Splashscreen.js] GUARDO EN EL STATE LATITUD(ERROR)-LONGITUD(ERROR)", consoleStyleError);
          console.log("%c...[Splashscreen.js] PREGUNTO EN EL LOCALSTORAGE SI HAY LATITUD-LONGITUD", consoleStyle);

          if (isGeoLocated()) {
            console.log("%c...[Splashscreen.js] SI HAY LATITUD-LONGITUD EN EL LOCALSTORAGE BORRO !!", consoleStyle);
            remLocation();
            console.log("%c...[Splashscreen.js] AGREGO NUEVA LATITUD-LONGITUD(ERROR) EN EL LOCALSTORAGE !!", consoleStyle);
            setLocation("err-latitude", "err-longitude");
          } else {
            console.log("%c...[Splashscreen.js] SI NO HAY LATITUD-LONGITUD(ERROR) EN EL LOCALSTORAGE AGREGO !!", consoleStyle);
            setLocation("err-latitude", "err-longitude");
          }
          console.log("...[Splashscreen.js] LOCATION EN EL LOCALSTORAGE",
            "\n>> Lat : ", getLat(),
            "\n>> Lng : ", getLng());

          console.log("%c...[Splashscreen.js] PREGUNTO EN EL LOCALSTORAGE SI HAY CITY", consoleStyle);
          if (isStated()) {
            console.log("%c...[Splashscreen.js] SI HAY CITY EN EL LOCALSTORAGE BORRO", consoleStyle);
            remStated();
            console.log("%c...[Splashscreen.js] AGREGO NUEVA CITY EN EL LOCALSTORAGE", consoleStyle);
            setStated("err-state");
          } else {
            console.log("%c...[Splashscreen.js] SI NO HAY CITY EN EL LOCALSTORAGE AGREGO", consoleStyle);
            setStated("err-state");
          }
          console.log(
            "...[Splashscreen.js] CITY EN EL LOCALSTORAGE",
            "\n>> ESTADO : ",getStated(),
            "\n>> TIPO : ", typeof getStated());

          this.setState({
            latitude: "err-latitude",
            longitude: "err-longitude",
            state: "err-state",
            cities: "err-cities",
            showProgressBar: !this.state.showProgressBar,
          });
          // REDIRIGIR A ONBOARDING
          console.log("%c...[Splashscreen.js] GEOLOCALIZACION OBTENIDA (ERROR)", consoleStyleError);
          this.redirectToTarget("/onboarding");
          //Si quiero pasar la latitud y longitud por history
          //this.redirectToTargetLatLng(this.state.latitude, this.state.longitude);
        }
      );
    }
  };

/*******************************************************************************/

  getLocationState = (lat, lng) => {
    console.log("...[Splashscreen.js] getLocationState() GET ESTADO BY LATITUD (",lat,
      ") Y LONGITUD (",lng,") (ASINCRONO)");
    let that = this;
    GeoService.getState(lat, lng)
      .then((state) => {
        console.log("%c...[Splashscreen.js] getLocationState() ESTADO OBTENIDA (EXITO)", consoleStyleSuccess);
        let stateAccent = "";
        state === "COL" ? (stateAccent = "CO") : (stateAccent = state.toLowerCase());
        console.log("...[Splashscreen.js] getLocationState() ESTADO OBTENIDA (EXITO) ",
          "\n>> ESTADO : ", stateAccent,
          "\n>> TIPO : ",typeof stateAccent);

        console.log("%c...[Splashscreen.js] getLocationState() PREGUNTO EN EL LOCALSTORAGE SI HAY LATITUD-LONGITUD", consoleStyle);

        if (isGeoLocated()) {
          console.log("...[Splashscreen.js] getLocationState() SI HAY LATITUD-LONGITUD EN EL LOCALSTORAGE BORRO", consoleStyle);
          remLocation();
          console.log("...[Splashscreen.js] getLocationState() AGREGO NUEVA LATITUD-LONGITUD EN EL LOCALSTORAGE", consoleStyle);
          setLocation(lat, lng);
        } else {
          console.log("...[Splashscreen.js] getLocationState() SI NO HAY LATITUD-LONGITUD EN EL LOCALSTORAGE AGREGO", consoleStyle);
          setLocation(lat, lng);
        }
        console.log("...[Splashscreen.js] getLocationState() LOCATION EN EL LOCALSTORAGE",
          "\n>> LAT : ", getLat(), 
          "\n>> LAT TIPO : ", typeof getLat(),
          "\n>> LNG : ", getLng(),
          "\n>> LNG TIPO : ", typeof getLng());

        console.log("%c...[Splashscreen.js] getLocationState() PREGUNTO EN EL LOCALSTORAGE SI HAY CITY", consoleStyle);

        if (isStated()) {
          console.log("%c...[Splashscreen.js] getLocationState() SI HAY CITY EN EL LOCALSTORAGE BORRO", consoleStyle);
          remStated();
          console.log("%c...[Splashscreen.js] getLocationState() AGREGO NUEVA CITY EN EL LOCALSTORAGE", consoleStyle);
          setStated(stateAccent);
        } else {
          console.log("%c...[Splashscreen.js] getLocationState() SI NO HAY CITY EN EL LOCALSTORAGE AGREGO", consoleStyle);
          setStated(stateAccent);
        }
        console.log("...[Splashscreen.js] getLocationState() CITY EN EL LOCALSTORAGE",
          "\n>> ESTADO : ",getStated(),
          "\n>> TIPO : ",typeof getStated());

        console.log("%c...[Splashscreen.js] getLocationState() AGREGO LAT, LNG, STATE al STATE", consoleStyle);

        this.setState({
          latitude: lat,
          longitude: lng,
          state: stateAccent,
        });

        this.getLocationMunicipios(lat, lng, 2);
      })
      .catch((err) => {
        console.log("%c...[Splashscreen.js] getLocationState() CITY (ERROR)", consoleStyleError);
        console.log("...[Splashscreen.js] getLocationState() CITY (ERROR)",
          "\n>> error : ", err,
          "\n>> error.response : ", err.response,
          "\n>> error.response.status : ", err.response.status,
          "\n>> error.response.statusText : ", err.response.statusText);
        if (err.response.statusText.toLowerCase() === "timeout" || err.response.status === 403) {
          console.log("%c@...[Splashscreen.js] CITY ( TOAST )", consoleStyle);
          console.log("@...[Splashscreen.js] CITY ( TOAST )",
            "\n>> LAT : ", lat,
            "\n>> LNG : ", lng);
          M.toast({
            html:
              "Tiempo de respuesta agotado para obtener la localización, Reintentando ...",
            displayLength: 3000,
            completeCallback: function () {
              that.getLocationState(lat, lng);
            },
          });
        } else {
          console.log("%c...[Splashscreen.js] getLocationState() CITY (ERROR) AGREGO LAT, LNG, STATE, CITIES al STATE", consoleStyleError);
          this.setState({
            latitude: lat,
            longitude: lng,
            state: "err-state",
            cities: "err-cities",
          });
        }
      });
  };

/*******************************************************************************/

  getLocationMunicipios = (lat, lng, km) => {
    console.log("...[Splashscreen.js] getLocationMunicipios() GET MUNICIPIOS BY LOCATION en ",
      km, "KM y LOCATION(",lat,",",lng,") (ASINCRONO)");
    let that = this;
    //const showfield = JSON.stringify({'showfield': 'nombre_municipio'});
    //const location = JSON.stringify({'latitud,longitud': lat,lng});
    LocationService.getMunicipios("eatc_co_municipalities", "latitud,longitud",
      lat, lng, "nombre_municipio", km)
      .then((municipios) => {
        console.log("%c...[Splashscreen.js] getLocationMunicipios() Ocurrió un exito", globales.consoleStyleSuccess);
        console.log("...[Splashscreen.js] getLocationMunicipios() Ocurrió un exito \n>> municipios : ", municipios);
        var arrayMunicipios = [...new Set(municipios.map((x) => x.nombre_municipio)),];
        console.log("...[Splashscreen.js] getLocationMunicipios() Ocurrió un exito \n>> MUNICIPIOS : ", arrayMunicipios);

        console.log("%c...[Splashscreen.js] getLocationMunicipios() PREGUNTO EN EL LOCALSTORAGE SI HAY CITY", consoleStyle);

        if (isCitied()) {
          console.log("%c...[Splashscreen.js] getLocationMunicipios() SI HAY CITY EN EL LOCALSTORAGE BORRO", consoleStyle);
          remCitied();
          console.log("%c...[Splashscreen.js] getLocationMunicipios() AGREGO NUEVA CITY EN EL LOCALSTORAGE", consoleStyle);
          setCitied(arrayMunicipios);
        } else {
          console.log("%c...[Splashscreen.js] getLocationMunicipios() SI NO HAY CITY EN EL LOCALSTORAGE AGREGO", consoleStyle);
          setCitied(arrayMunicipios);
        }

        console.log("...[Splashscreen.js] getLocationMunicipios() CITY EN EL LOCALSTORAGE",
          "\n>> CITY : ",JSON.parse(getCitied()),
          "\n>> TIPO : ", typeof getCitied());

        console.log("%c...[Splashscreen.js] getLocationMunicipios() COLOCAR cities EN EL STATE", consoleStyle);

        this.setState({
          cities: arrayMunicipios.join(),
        });

        this.getQueryRadius(this.state.cities, this.state.state);
        //this.getQueryRadius('BUCARAMANGA', this.state.state);
        //[...new Set(municipios.map(x => x.nombre_municipio))]

        // REDIRIGIR A ONBOARDING
        // this.redirectToTarget('/onboarding');
      })
      .catch((err) => {
        console.log("%c...[Splashscreen.js] getLocationMunicipios() Ocurrió un error", globales.consoleStyleError);
        console.log("...[Splashscreen.js] getLocationMunicipios() Ocurrió un error \n>> err : ", err);

        if (err.response.statusText.toLowerCase() === "timeout" || err.response.status === 403) {
          console.log("%c...[Splashscreen.js] getLocationMunicipios() CITY ( TOAST )", consoleStyleError);
          console.log("...[Splashscreen.js] getLocationMunicipios() CITY ( TOAST )",
            "\n>> lat : ", lat, 
            "\n>> lng : ", lng,
            "\n>> km : ", km);
          M.toast({
            html:
              "Tiempo de respuesta agotado para obtener los municipios, Reintentando ...",
            displayLength: 3000,
            completeCallback: function () {
              that.getLocationMunicipios(lat, lng, km);
            },
          });
        } else if (err.response.data.cont === 0) {
          console.log("%c...[Splashscreen.js] getLocationMunicipios() CITY ( TOAST )", consoleStyleError);
          console.log("...[Splashscreen.js] getLocationMunicipios() CITY ( TOAST )",
            "\n>> lat : ", lat, 
            "\n>> lng : ", lng,
            "\n>> km : ", km * 2);
          M.toast({
            html:
              "Tiempo de respuesta agotado para obtener los municipios, " +
              err.response.data.err_msg +
              ", Reintentando ...",
            displayLength: 3000,
            completeCallback: function () {
              that.getLocationMunicipios(lat, lng, km * 2);
            },
          });
        }
      });
  };

/*******************************************************************************/

  getFieldsMunicipios = () => {
    LocationService.getCamposMunicipios()
      .then((municipios) => {
        console.log("Ocurrió un exito -> ", municipios);
      })
      .catch((err) => {
        console.log("Ocurrió un error -> ", err);
      });
  };

/*******************************************************************************/

  getQueryRadius = (cities, state) => {
    console.log("...[Splashscreen.js] GET RADIUS BY CITIES (", 
      cities, ") y ESTADO(",state,") !!");
    let that = this;
    const params = JSON.stringify({
      "eatc-user_id": "_default",
      "eatc-city": cities,
      "eatc-country": state,
    });
    DashboardService.getQueryRadius("eatc_sale", params)
      .then((query) => {
        console.log("%c...[Splashscreen.js] getQueryRadius() Ocurrió un exito", consoleStyleSuccess);
        console.log("...[Splashscreen.js] getQueryRadius() Ocurrió un exito",
          "\n>> QUERY : ", query,
          "\n>> QUERY.eatc-radius_km : ", query["eatc-radius_km"]);

        console.log("%c...[Splashscreen.js] getQueryRadius() PREGUNTO EN EL LOCALSTORAGE SI HAY ACTUAL RADIUS",
          consoleStyle);

        if (isActualRadius()) {
          console.log("%c...[Splashscreen.js] getQueryRadius() SI HAY ACTUAL RADIUS EN EL LOCALSTORAGE BORRO",
            consoleStyle);
          remActualRadius();
          console.log("%c...[Splashscreen.js] getQueryRadius() AGREGO NUEVA ACTUAL RADIUS EN EL LOCALSTORAGE",
            consoleStyle);
          setActualRadius(query["eatc-radius_km"]);
        } else {
          console.log("%c...[Splashscreen.js] getQueryRadius() SI NO HAY ACTUAL RADIUS EN EL LOCALSTORAGE AGREGO",
            consoleStyle);
          setActualRadius(query["eatc-radius_km"]);
        }

        console.log("...[Splashscreen.js] getQueryRadius() ACTUAL RADIUS EN EL LOCALSTORAGE",
          "\n>> ACTUAL RADIUS : ",getActualRadius(),
          "\n>> TIPO : ", typeof getActualRadius());

        console.log("%c...[Splashscreen.js] getQueryRadius() colocar actual_sale_radius_km en el STATE",
          consoleStyle);

        this.setState({
          actual_sale_radius_km: query["eatc-radius_km"],
        });

        // REDIRIGIR A ONBOARDING actual_sale_radius_km
        this.redirectToTarget("/onboarding"); //DESCOMENTAR

      })
      .catch((err) => {
        console.log("%c...[Splashscreen.js] getQueryRadius() Ocurrió un ERROR", globales.consoleStyleError);
        console.log("...[Splashscreen.js] getQueryRadius() Ocurrió un ERROR \n>> err : ", err);
        
        if (err.response.statusText.toLowerCase() === "timeout" || err.response.status === 403) {
          console.log("%c...[Splashscreen.js] getQueryRadius() RADIUS ( TOAST )", consoleStyle);
          console.log("...[Splashscreen.js] getQueryRadius() RADIUS ( TOAST )",
            "\n>> cities : ", cities,
            "\n>> state : ", state);
          M.toast({
            html:
              "Tiempo de respuesta agotado para obtener el query segun el radio, Reintentando ...",
            displayLength: 3000,
            completeCallback: function () {
              that.getQueryRadius(cities, state);
            },
          });
        } else if (err.response.data.cont === 0) {
          console.log("%c...[Splashscreen.js] getQueryRadius() RADIUS ( TOAST )", consoleStyle);
          console.log("...[Splashscreen.js] getQueryRadius() RADIUS ( TOAST )",
            "\n>> cities : ", cities,
            "\n>> state : ", state,
            "\n>> this.state.cities : ", this.state.cities,
            "\n>> this.state.state : ", this.state.state);
          M.toast({
            html:
              "Tiempo de respuesta agotado para obtener el radios de busqueda, " +
              err.response.data.err_msg +
              ", Reintentando ...",
            displayLength: 3000,
            completeCallback: function () {
              if (that.state.cities === "_default") {
                that.setState({
                  state: "_default",
                });
              } else {
                that.setState({
                  cities: "_default",
                });
              }
              that.getQueryRadius(that.state.cities, that.state.state);
            },
          });
        }
      });
  };

/*******************************************************************************/

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  METODOS IMPORTANTES *********************************************/

/* #region  RENDER *************************************************************/
/*******************************************************************************/
/*******************************************************************************/

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" style={{height: "30vmin"}}/>
          {this.state.showProgressBar && <LinearIndeterminate />}
        </header>
      </div>
    );
  }

/*******************************************************************************/
/*******************************************************************************/
/* #endregion  RENDER **********************************************************/

}

export default Splashscreen;
