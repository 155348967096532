
import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import detprodReducer from '../components/detalleprod/detprodDuck';
import carrocompReducer from '../components/carrocompras/carrocompDuck';
import checkoutReducer from '../components/checkout/checkoutDuck';

const rootReducer = combineReducers({
    detprod_state: detprodReducer,
    carrocomp_state: carrocompReducer,
    checkout_state: checkoutReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore( rootReducer, composeEnhancers( applyMiddleware(thunk) ) );
    return store;
}