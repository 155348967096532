import axios from 'axios';
import glb from '../../config/config';
import { fn_genera_token, fn_set_cart, fn_get_cart, fn_get_datetime } from '../../misc/sc_misc';

export const fn_storeUser = () => async () => {    
    try {
        let token = localStorage.getItem('TOKEN');
        const {user, additionalData} = JSON.parse(localStorage.getItem('USER')) || {};

        let params = `eatc-email=${user.email}&eatc-user_name=${user.displayName}&eatc-token=${token}&eatc-state=activo&eatc-name=${user.displayName}`;
        //let params = `eatc-email=${user.email}&eatc-user_name=${user.displayName}&eatc-token=${token}&eatc-state=activo&eatc-name=${user.displayName}&eatc-doc_id=${additionalData.document}&eatc-address=${additionalData.address}&eatc-phone=${additionalData.phone}`;

        try{
            const rsg = await axios.get(`${glb._benf}${glb._crypt}${glb._user}getcrypt?table=eatc_sale_users&fieldname=eatc-email&fieldvalue=${user.email}`);
            //const rsg = await axios.get(`${glb._benf}${glb._api}${glb._user}eatc_sale_users?eatc-code=${token}`);
            console.log(rsg.data);
            if(rsg.data.cont==0){
                throw "No records found";
            }

            let dbuser = rsg.data.res[0];
            if(dbuser['eatc-code']!=token){
                token = dbuser['eatc-code'];
                fn_refreshToken(token);
                /*let detail_ids = fn_getDetailIds(token);
                if(detail_ids!=''){
                    const rs = await axios.get(`${glb._benf}${glb._crd}${glb._user}?_tabla=eatc_sale_order&_operacion=update&eatc-user_code=${token}&WHERE_id=${detail_ids}`);
                    console.log(rs.data);
                }*/
            }

            const rsu = await axios.get(`${glb._benf}${glb._crd}${glb._user}?_tabla=eatc_sale_users&_operacion=update&${params}&WHEREeatc-code=${token}`);
            console.log(rsu.data);
        } catch(err){
            console.log(err);
            try{
                const rsg = await axios.get(`${glb._benf}${glb._api}${glb._user}eatc_sale_users?eatc-code=${token}`);
                console.log(rsg.data);
                if(rsg.data.cont==0){
                    throw "No records found";
                }
                token = fn_genera_token(36);
                fn_refreshToken(token);
                /*let detail_ids = fn_getDetailIds(token);
                if(detail_ids!=''){
                    const rs = await axios.get(`${glb._benf}${glb._crd}${glb._user}?_tabla=eatc_sale_order&_operacion=update&eatc-user_code=${token}&WHERE_id=${detail_ids}`);
                    console.log(rs.data);
                }*/
            } catch(err2){
                console.log(err2);
            }
            let dateinfo = fn_get_datetime();
            let insertParams = `eatc-code=${token}&eatc-creation_date=${dateinfo.date}&eatc-creation_datetime=${dateinfo.datetime}`;
            const rsi = await axios.get(`${glb._benf}${glb._crd}${glb._user}?_tabla=eatc_sale_users&_operacion=insert&${params}&${insertParams}`);
            console.log(rsi.data);
            const rsc = await axios.get(`${glb._benf}${glb._crypt}${glb._user}encrypt?table=eatc_sale_users&fieldname=eatc-email,eatc-user_name,eatc-name,eatc-password,eatc-doc_id,eatc-address,eatc-phone`);
            console.log(rsc.data);
        }
    } catch (error) {
        console.error("Error fn_storeUser: ", error);
        localStorage.setItem('USER', null);
    }
};

const fn_refreshToken = (token) => {
    localStorage.setItem('TOKEN', token);
    let detail = fn_get_cart('detail');
    let newdetail = [];
    let detail_ids = '';
    if(detail.length>0){
        detail.forEach(item => {
            /*if(detail_ids!=''){
                detail_ids += ',';
            }          
            detail_ids += item['_id'];*/
            item['eatc-user_code'] = token;
            newdetail.push(item);
            const rs = axios.get(`${glb._benf}${glb._crd}${glb._user}?_tabla=eatc_sale_order&_operacion=update&eatc-user_code=${token}&WHERE_id=${item['_id']}`);
            console.log(rs.data);
        });
    }
    fn_set_cart('detail', newdetail);

    //return detail_ids;
};