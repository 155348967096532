import React, { Component } from "react";
import nomap from "../../img/nomap.jpg";
import { Link } from "react-router-dom";
import globales from "../../config/config";
import ImportantService from "../../services/ImportantService";
import { fn_convert_am_pm } from "../../misc/sc_misc";
//import DashboardDonantesService from "../../services/DashboardDonantesService";
//import ImportantService from "../../services/ImportantService";

export default class CardInfoImageHor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      point: "",
    };
  }

  componentDidMount() {
    console.log(
      "%c CardInfoImageHor...Estas en puntos de venta",
      globales.consoleStyle
    );
    console.log("CardInfoImageHor...this.props.offer", this.props.offer);
    this.getSomeOfferSchedule(this.props.offer);
  }

  getSomeOfferSchedule(new_nearby_point) {
    console.log(
      "CardInfoImageHor... getSomeOfferSchedule : ",
      new_nearby_point
    );
    if (new_nearby_point["eatc_cua_origin"] !== "") {
      if (new_nearby_point["eatc-pod_id"] !== "") {
        ImportantService.getSchedule(
          new_nearby_point["eatc_cua_origin"],
          new_nearby_point["eatc-pod_id"]
        )
          .then((schedule) => {
            console.log(
              "CardInfoImageHor... getSomeOfferSchedule :  -> Ocurrió un exito->schedule",
              schedule
            );

            var weekday = new Array(7);
            weekday[0] = "do";
            weekday[1] = "lu";
            weekday[2] = "ma";
            weekday[3] = "mi";
            weekday[4] = "ju";
            weekday[5] = "vi";
            weekday[6] = "sa";

            var fecha_actual = new Date();
            var dia_actual = weekday[fecha_actual.getDay()];
            console.log("dia_actual ", dia_actual);
            console.log("dia_actual key", weekday.indexOf(dia_actual));
            var dia_siguiente = weekday[fecha_actual.getDay() + 1];
            console.log("dia_actual siguiente ", dia_siguiente);
            console.log(
              "dia_actual siguiente key",
              weekday.indexOf(dia_siguiente)
            );

            const ordenamiento = {
              do: 0,
              lu: 1,
              ma: 2,
              mi: 3,
              ju: 4,
              vi: 5,
              sa: 6,
            };

            schedule.sort(
              (a, b) =>
                ordenamiento[a["eatc-day"]] - ordenamiento[b["eatc-day"]]
            );

            console.table("dia_actual table", schedule);

            /* console.log(
              "objectsArrayExistValueReturn",
              this.objectsArrayExistValue(schedule, dia_uno)
            ); */

            /*  console.log(
              "CardInfoImageHor... getSomeOfferSchedule : -> Ocurrió un exito 1-> ",
              schedule[0]
            );
            console.log(
              "CardInfoImageHor... getSomeOfferSchedule : -> Ocurrió un exito 2-> ",
              schedule[1]
            );
 */
            //RECORRER EL SCHEDULE Y PREGUNTAR SI EXISTE UN eatc-day = dia_uno

            /*  schedule.forEach((day) => {
              console.log("dia_uno -", day["eatc-day"]);
              console.log("dia_uno --", schedule.indexOf(day));
              console.log("dia_uno --", day.indexOf(dia_actual));
              if (day.indexOf(dia_actual) !== -1) {
                console.log("dia_uno", "HAY UN MARTES");
              }
            }); */

            /*  if (schedule.indexOf(dia_uno)) {
              console.log("dia_uno ", "HAY UN MARTES");
            } else {
              console.log("dia_uno ", "NO HAY UN MARTES");
            }
 */

            /* new_nearby_point["eatc-day_initial"] = schedule[0]["eatc-day"];
            new_nearby_point["eatc-start_hour_initial"] =
              schedule[0]["eatc-start_hour"];
            new_nearby_point["eatc-start_hour_final"] =
              schedule[0]["eatc-final_hour"];
            new_nearby_point["eatc-day_final"] = schedule[1]["eatc-day"];
            new_nearby_point["eatc-final_hour_initial"] =
              schedule[1]["eatc-start_hour"];
            new_nearby_point["eatc-final_hour_final"] =
              schedule[1]["eatc-final_hour"];
            console.log(
              "CardInfoImageHor... getSomeOfferSchedule : -> Ocurrió un exito TOTAL -> new_nearby_point ",
              new_nearby_point
            ); */

            var diaActualFromSchedule = this.objectsArrayExistValue(
              schedule,
              dia_actual
            );

            if (diaActualFromSchedule !== -1) {
              // OBTENER EL KEY DEL DIA y SU DIA SIGUENTE
              console.log(
                "dia_actual diaActualFromSchedule",
                "EXISTE UNA COINCIDENCIA"
              );
              console.log(
                "dia_actual diaActualFromSchedule",
                diaActualFromSchedule
              );
              new_nearby_point["eatc-day_initial"] =
                schedule[diaActualFromSchedule]["eatc-day"];
              new_nearby_point["eatc-start_hour_initial"] =
                schedule[diaActualFromSchedule]["eatc-start_hour"];
              new_nearby_point["eatc-start_hour_final"] =
                schedule[diaActualFromSchedule]["eatc-final_hour"];

              console.log(
                "dia_actual diaActualFromSchedule CardInfoImageHor... getSomeOfferSchedule : -> Ocurrió un exito TOTAL -> new_nearby_point ",
                new_nearby_point
              );
            }

            var diaSiguienteFromSchedule = this.objectsArrayExistValue(
              schedule,
              dia_siguiente
            );

            if (diaSiguienteFromSchedule !== -1) {
              // OBTENER EL KEY DEL DIA y SU DIA SIGUENTE
              console.log(
                "dia_actual dia_siguiente diaSiguienteFromSchedule",
                "EXISTE UNA COINCIDENCIA"
              );
              console.log(
                "dia_actual dia_siguiente diaSiguienteFromSchedule",
                diaSiguienteFromSchedule
              );
              new_nearby_point["eatc-day_final"] =
                schedule[diaSiguienteFromSchedule]["eatc-day"];
              new_nearby_point["eatc-final_hour_initial"] =
                schedule[diaSiguienteFromSchedule]["eatc-start_hour"];
              new_nearby_point["eatc-final_hour_final"] =
                schedule[diaSiguienteFromSchedule]["eatc-final_hour"];
              console.log(
                "dia_actual dia_siguiente CardInfoImageHor... getSomeOfferSchedule : -> Ocurrió un exito TOTAL -> new_nearby_point ",
                new_nearby_point
              );
            }

            this.setState({ point: new_nearby_point });
          })
          .catch((err) => {
            console.log(
              "CardInfoImageHor... getSomeOfferSchedule : -> Ocurrió un error -> ",
              err
            );
          });
      }
    } else {
      console.log(
        "%c CardInfoImageHor... getSomeOfferSchedule : -> nearbyOffer con eatc_cua_origin vacia",
        globales.consoleStyleError
      );
    }
  }

  redirectToTargetOffers = (target) => {
    console.log("...REDIRIGIR A ", target);
    this.props.history.push({
      pathname: target,
      state: {
        nearby_offers_search: this.state.nearby_offers,
      },
    });
  };

  objectsArrayExistValue(arrobj, value) {
    var result = -1;
    arrobj.forEach((day) => {
      console.log("dia_actual obj", day);
      console.log("dia_actual obj val", day["eatc-day"]);
      console.log("dia_actual value", value);
      console.log("dia_actual key", arrobj.indexOf(day));
      if (day["eatc-day"].toLowerCase() === value.toLowerCase())
        result = arrobj.indexOf(day);
    });
    return result;
  }

  render() {
    return (
      <div
        className="carousel-item"
        href="#page"
        /*  onClick={() => {
          this.getNearbyOffersByLatLng(
            this.props.offer["eatc-pod_lat"],
            this.props.offer["eatc-pod_lon"]
          );
        }} */
      >
        <Link
          to={{
            pathname: "/dashboardsale",
            state: {
              point: this.props.offer,
              //latitud_actual: this.props.latitud_actual,
              //longitud_actual: this.props.longitud_actual,
              km_actual: this.props.km_actual,
            },
          }}
        >
          <div
            className="card horizontal"
            style={{ margin: "1.5rem 0 1rem 0" }}
          >
            <div className="card-image">
              <img
                src={this.props.src === "" ? nomap : this.props.src}
                alt="nomap"
              />
            </div>
            <div className="card-stacked">
              <div className="card-content">
                {this.props.max_discount === "0" ? (
                  <div></div>
                ) : (
                  <div className="circulo-dcto shadow-9dp">
                    - {this.props.max_discount}%
                  </div>
                )}
                <span className="card-title-med">{this.props.name}</span>
                <span className="txt-meta">{this.props.address}</span>
                <span className="txt-meta txt-accent">a {this.props.km}KM</span>
                <span className="txt-meta txt-primary">
                  {this.state.point === "" ? (<div>NO EXISTEN HORARIOS DEFINIDOS</div>) : (<div></div>)}
                  {this.state.point["eatc-day_initial"] === undefined ? (
                    <div></div>
                  ) : (
                    <div>
                      {this.state.point["eatc-day_initial"].toUpperCase()}{" "}
                      {fn_convert_am_pm(
                        this.state.point["eatc-start_hour_initial"]
                      )}
                      -{" "}
                      {fn_convert_am_pm(
                        this.state.point["eatc-start_hour_final"]
                      )}
                      {/* <br />

                      {this.state.point["eatc-day_final"].toUpperCase()}{" "}
                      {fn_convert_am_pm(
                        this.state.point["eatc-final_hour_initial"]
                      )}
                      -{" "}
                      {fn_convert_am_pm(
                        this.state.point["eatc-final_hour_final"]
                      )} */}
                    </div>
                  )}
                  {this.state.point["eatc-day_final"] === undefined ? (
                    <div></div>
                  ) : (
                    <div>
                      {this.state.point["eatc-day_final"].toUpperCase()}{" "}
                      {fn_convert_am_pm(
                        this.state.point["eatc-final_hour_initial"]
                      )}
                      -{" "}
                      {fn_convert_am_pm(
                        this.state.point["eatc-final_hour_final"]
                      )}
                    </div>
                  )}
                </span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
