import React, { Component } from "react";
import CardInfoImageVer from "../cards/CardInfoImageVer";
import CircularSmall from "../preloader/CircularSmall";
import M from "materialize-css";
import { Link } from "react-router-dom";
import globales from "../../config/config";
import nomap from "../../img/nomap.jpg";
import DashboardDonantesService from "../../services/DashboardDonantesService";
import { fn_convert_am_pm } from "../../misc/sc_misc";
// import stycss from '../../css/idr.module.css';

export default class DashboarSalePoint extends Component {

/* #region  CONSTRUCTOR ********************************************************/
/*******************************************************************************/
/*******************************************************************************/

  constructor(props) {
    super(props);
    console.log("...[DashboarSalePoint.js] CONSTRUCTOR ESTAS EN /dashboardsale \n>> props : ", props);
    this.state = {
      //latitud: props.location.state.latitud_actual,
      //longitud: props.location.state.longitud_actual,
      actual_sale_radius_km: props.location.state.km_actual,
      showProgressBarOffers: true,
      showProgressBarMessages: true,
      showProgressBarListProducts: true,
      nearby_messages: [],
      nearby_offers: [],
      nearby_offers_search: [],
      point: props.location.state.point,
      search_text: "",
      disabled: true,
    };
  }

/*******************************************************************************/
/*******************************************************************************/
/* #endregion ******************************************************************/

/* #region  COMPONENTDIDMOUNT **************************************************/
/*******************************************************************************/
/*******************************************************************************/

  componentDidMount() {
    // Auto initialize all the things!
    M.AutoInit();
    console.log("%c...[DashboarSalePoint.js] componentDidMount ESTAS EN /dashboardsale", globales.consoleStyle);
    this.nameInput.focus();

    /* this.getNearbyOffersByLatLng(
      this.state.point["eatc-pod_lat"],
      this.state.point["eatc-pod_lon"]
    ); */


    console.log("...[DashboarSalePoint.js] componentDidMount",
      "\n>> state.point : ", this.state.point,
      //"\n>> state.latitud : ", this.state.latitud,
      //"\n>> state.longitud : ", this.state.longitud,
      "\n>> state..point['eatc-pod_id'] : ", this.state.point["eatc-pod_id"],
      "\n>> state.actual_sale_radius_km : ", this.state.actual_sale_radius_km,
      "\n>> state.disabled : ", this.state.disabled);

    this.getNearbyOffersByCodePoint(this.state.point["eatc-pod_id"]);

    // se guardan los estados de point, latitud, longitud y radio para que, 
    // cuando se quiera devolverse desde el carro de compras, 
    // se devuelva al dashboard del punto de donacion.
    // if (localStorage.getItem("pod_fijado") === "No") {
    localStorage.setItem("pod_point", JSON.stringify(this.state.point));
    localStorage.setItem("pod_latitud_actual",  this.state.point["eatc-pod_lat"]);
    localStorage.setItem("pod_longitud_actual", this.state.point["eatc-pod_lon"]);
    localStorage.setItem("pod_km_actual", this.state.actual_sale_radius_km);
    // }
    var elems = document.querySelectorAll(".modal");
    M.Modal.init(elems);
  }

/*******************************************************************************/
/*******************************************************************************/
/* #endregion ******************************************************************/

  filter = (event) => {
    console.log("event : ", event, "index : ");
    var search_text = event.target.value;
    console.log("%c search_text : " + search_text, globales.consoleStyle);
    const data = this.state.nearby_offers;
    const newData = data.filter(function (item) {
      console.log("Item : ", item);
      console.log("Item : ", item["eatc-odd_name"]);
      console.log("Item : ", item["eatc-odd_description"]);
      console.log("Item : ", item["eatc-pod_name"]);
      const eatcOddName = item["eatc-odd_name"].toUpperCase();
      const eatcOddDescription = item["eatc-odd_description"].toUpperCase();
      const eatcPodName = item["eatc-pod_name"].toUpperCase();
      const campo = eatcOddName + " " + eatcOddDescription + " " + eatcPodName;
      const textData = search_text.toUpperCase();
      return campo.indexOf(textData) > -1;
    });
    console.log("newData : ", newData);
    this.setState({
      nearby_offers_search: newData,
      search_text: search_text,
    });
  };

  redirectToTargetBack = (e) => {
    //e.preventDefault();
    console.log("...REDIRIGIR ATRAS");
    this.props.history.goBack();
  };

  renderOffersListProducts() {
    this.state.nearby_offers_search.sort(
      (a, b) => b["eatc-odd_max_discount"] - a["eatc-odd_max_discount"]
    );
    return this.state.nearby_offers_search.map((nearbyOffer, index) => (
      <CardInfoImageVer
        key={index}
        offer={nearbyOffer}
        odd_image={nearbyOffer["eatc-odd_image"]}
        odd_max_discount={nearbyOffer["eatc-odd_max_discount"]}
        odd_name={nearbyOffer["eatc-odd_name"]}
        odd_unit_price={nearbyOffer["eatc-odd_unit_price"]}
        odd_min_sale_unit_price={nearbyOffer["eatc-odd_min_sale_unit_price"]}
        //km={nearbyOffer["km"]}
        eatc_cua_origin={nearbyOffer["eatc_cua_origin"]}
        eatc_pod_id={nearbyOffer["eatc-pod_id"]}
        eatc_code={nearbyOffer["eatc-code"]}
      />
    ));
  }

  getNearbyOffersByLatLng = (lat, lng) => {
    console.log(
      "...GET OFERTAS CERCANAS en " +
        this.state.actual_sale_radius_km +
        "KM y LOCATION PUNTO DE VENTA(",
      lat,
      ",",
      lng,
      ") (ASINCRONO)"
    );
    DashboardDonantesService.getNearbyOffersByLocation(
      "eatc_sale",
      "eatc-pod_lat,eatc-pod_lon",
      this.state.point["eatc-pod_lat"],
      this.state.point["eatc-pod_lon"],
      "_id,eatc-code,eatc-pod_id,eatc-pod_name,eatc-pod_address,eatc-pod_lat,eatc-pod_lon,eatc-odd_name,eatc-odd_description,eatc-odd_unit_price,eatc-odd_min_sale_unit_price,eatc-odd_max_discount,eatc-odd_image,eatc-odd_state,eatc_cua_origin,eatc-odd_quantity",
      this.state.actual_sale_radius_km,
      "eatc-odd_state",
      "sale,partially_ordered",
      "eatc-pod_lat",
      lat,
      "eatc-pod_lon",
      lng,
      null
    )
      .then((offers) => {
        console.log("Ocurrió un exito -> ", offers);
        const nearbyOffersbyQuantity = this.filterOffersbyQuantity(offers);
        console.log("nearbyOffersbyQuantity : ", nearbyOffersbyQuantity);
        this.setState({
          showProgressBarListProducts: !this.state.showProgressBarListProducts,
          nearby_offers: nearbyOffersbyQuantity,
          nearby_offers_search: nearbyOffersbyQuantity,
          disabled: !this.state.disabled,
        });

        this.nameInput.focus();

        console.log("@ offers", offers);
        console.log("@ this.state.nearby_offers", this.state.nearby_offers);
        console.log(
          "@ this.state.nearby_offers_search",
          this.state.nearby_offers_search
        );
      })
      .catch((err) => {
        console.log("Ocurrió un error -> ", err);
      });
  };

  getNearbyOffersByCodePoint = (id) => {
    console.log("...[DasboardSalePoint.js] getNearbyOffersByCodePoint GET OFERTAS CERCANAS en PUNTO DE DONACION ( " +
        "\n>> eatc-pod_id : "+ id + ") (ASINCRONO)", globales.consoleStyle);

    let that = this;

    DashboardDonantesService.getNearbyOffersByCodePoint("eatc_sale", id, 
        "partially_ordered,sale").then((offers) => {
        
        console.log("%c...[DasboardSalePoint.js] getNearbyOffersByCodePoint DONE OFERTAS PUNTO", globales.consoleStyleSuccess);
        console.log("...[DasboardSalePoint.js] getNearbyOffersByCodePoint DONE OFERTAS PUNTO",
          "\n>> offers : ", offers);

        const nearbyOffersbyQuantity = this.filterOffersbyQuantity(offers);
        console.log("...[DasboardSalePoint.js] getNearbyOffersByCodePoint DONE OFERTAS PUNTO",
          "\n>> nearbyOffersbyQuantity : ", nearbyOffersbyQuantity);

        console.log("%c...[DasboardSalePoint.js] getNearbyOffersByCodePoint DONE" + 
          "\n GUARDANDO MENSAJES ACTUALES nearby_offers y nearby_offers_search en STATE", globales.consoleStyle);

        this.setState({
          showProgressBarListProducts: !this.state.showProgressBarListProducts,
          nearby_offers: nearbyOffersbyQuantity,
          nearby_offers_search: nearbyOffersbyQuantity,
          disabled: !this.state.disabled,
        });

        this.nameInput.focus();

         console.log("...[DasboardSalePoint.js] getNearbyOffersByCodePoint DONE OFERTAS PUNTO",
          "\n>> offers : ", offers,
          "\n>> this.state.nearby_offers : ", this.state.nearby_offers,
          "\n>> this.state.nearby_offers_search : ", this.state.nearby_offers_search);

      })
      .catch((err) => {
        console.log("Ocurrió un error -> ", err);
        console.log("%c...[DasboardSalePoint.js] getNearbyOffersByCodePoint ERROR OFERTAS PUNTO", globales.consoleStyleError);
        console.log("...[DasboardSalePoint.js] getNearbyOffersByCodePoint ERROR OFERTAS PUNTO",
          "\n>> ERROR : ", err);

        if (err.response.statusText.toLowerCase() === "timeout" || err.response.status === 403) {
          console.log("%c...[DasboardSalePoint.js] getNearbyOffersByCodePoint ERROR TIMEOUT - OFERTAS PUNTO ( TOAST ) -> ", globales.consoleStyle);
          M.toast({
            html:
              "Tiempo de respuesta agotado para obtener las ofertas, Reintentando ...",
            displayLength: 3000,
            completeCallback: function () {
              that.getNearbyOffersByCodePoint(id);
            },
          });
        }else{
          console.log("%c...[DasboardSalePoint.js] getNearbyOffersByCodePoint ERROR IS NOT TIMEOUT - OFERTAS PUNTO ( TOAST ) -> ", globales.consoleStyle);
          //this.getNearbyMessagesError(8);
        }

      });
  };

  filterOffersbyQuantity = (offers) => {
    // with return
    return offers.filter((offer) => offer["eatc-odd_quantity"] !== "0"); // return implicito
  };

  render() {
    const color = "#00B3FE";
    const colorWhite = "#FFFFFF";
    const divStyleZ = { zIndex: "1000" };
    const divStyleMBn24 = { marginBottom: "-24px" };
    const divStyleMTp24 = { marginTop: "-24px" };
    //const divStyleMTn24 = { marginTop: "-200px" };
    //const divStyleMTn19 = { marginTop: "-19px"};
    const divStyleMTn19 = { marginTop: "-19px", marginBottom: "-10px" };
    const divStyleMTp16 = { marginTop: "16px" };
    //const divStylePTpBn20 = { padding: "20px 0" };
    //const divStyleHeight200 = { height: "200px" };
    return (
      <div className="tinta-bg">
        <div className="blanco-bg shadow-8dp" style={divStyleZ}>
          <div className="row" style={divStyleMBn24}>
            <div className="col s1 m1 l1">
              <div style={divStyleMTp16}>
                <Link
                  to="#"
                  className=""
                  onClick={() => {
                    this.redirectToTargetBack();
                  }}
                >
                  <i className="material-icons">close</i>
                </Link>
              </div>
            </div>
            <div className="col s10 m10 l10">
              {/* style={divStyleMTn19} */}
              <div className="input-field" style={divStyleMTn19}>
                <input
                  id="search"
                  placeholder="Search..."
                  value={this.state.text}
                  ref={(input) => {
                    this.nameInput = input;
                  }}
                  onChange={(text) => this.filter(text)}
                  disabled={this.state.disabled ? "disabled" : ""}
                  className=""
                />
                <i className="material-icons icn-in-input">search</i>
              </div>
            </div>
            <div className="col s1 m1 l1">
              <div style={divStyleMTp16}>
                <Link to="/carrocompras" className="right">
                  <i className="material-icons">shopping_basket</i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* card detalle pod */}
        <div className="container">
          <div className="row" style={divStyleMTp24}>
            <div className="col s12 m12 l12">
              <div className="card horizontal full card-top">
                <div className="card-image esp-top-pequeno">
                  <img
                    src={
                      this.state.point["eatc-odd_image"] === ""
                        ? nomap
                        : this.state.point["eatc-odd_image"]
                    }
                    alt="nomap"
                  />
                </div>
                <div className="card-stacked">
                  <div className="card-content">
                    <span className="card-title-med esp-top-pequeno">
                      {this.state.point["eatc-pod_name"]}
                    </span>
                    <span className="txt-meta">
                      {this.state.point["eatc-pod_address"]}
                    </span>
                    <span className="txt-meta txt-accent">
                      a {this.state.point["km"]}KM
                    </span>
                    {/* <div className="card-action"> */}
                    {/* <button
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          alert("Dirigir a Google Maps");
                        }}
                        className="btn-small primario-btn shadow-2dp waves-effect waves-light width-100 txt-accent"
                      >
                        ¿Cómo llegar?
                      </button> */}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      //href=`/detalleprod/${prd["eatc-code"]}`
                      href={
                        "http://maps.google.com/maps?q=loc:" +
                        this.state.point["eatc-pod_lat"] +
                        "," +
                        this.state.point["eatc-pod_lon"] +
                        "&navigate=yes"
                      }
                      //className="btn-small primario-btn shadow-2dp waves-effect waves-light width-100 txt-accent"
                      className="btn-small primario-btn shadow-2dp waves-effect waves-light"
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty(
                            "background-color",
                            color,
                            "important"
                          );
                        }
                      }}
                    >
                      ¿Cómo llegar?
                    </a>
                    {/* </div> */}
                    <span className="txt-meta txt-primary esp-top-miniatura">
                      {this.state.point["eatc-day_initial"] === undefined &&
                      this.state.point["eatc-day_final"] === undefined ? (
                        <div>NO EXISTEN HORARIOS DEFINIDOS</div>
                      ) : (
                        <div></div>
                      )}
                      {this.state.point["eatc-day_initial"] === undefined ? (
                        <div></div>
                      ) : (
                        <div>
                          {this.state.point["eatc-day_initial"].toUpperCase()}{" "}
                          {fn_convert_am_pm(
                            this.state.point["eatc-start_hour_initial"]
                          )}
                          -{" "}
                          {fn_convert_am_pm(
                            this.state.point["eatc-start_hour_final"]
                          )}
                        </div>
                      )}
                      {this.state.point["eatc-day_final"] === undefined ? (
                        <div></div>
                      ) : (
                        <div>
                          {this.state.point["eatc-day_final"].toUpperCase()}{" "}
                          {fn_convert_am_pm(
                            this.state.point["eatc-final_hour_initial"]
                          )}
                          -{" "}
                          {fn_convert_am_pm(
                            this.state.point["eatc-final_hour_final"]
                          )}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- list --> */}
        <div className="container">
          <div className="row">
            <div className="col s6 m6 l6">
              <h3>Menú</h3>
            </div>
            <div className="col s6 m6 l6">
              <button
                className="btn-small secundario-btn shadow-2dp waves-effect waves-light esp-top-base right modal-trigger"
                href="#modalbot"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "background-color",
                      colorWhite,
                      "important"
                    );
                  }
                }}
              >
                <i className="material-icons right">filter_list</i>Filtrar
              </button>
            </div>
          </div>
          <div className="row">
            {this.state.showProgressBarListProducts && <CircularSmall />}
            {!this.state.showProgressBarListProducts &&
              this.renderOffersListProducts()}
          </div>
        </div>
        {/* <!-- Modal Structure --> */}
        <div id="modalbot" className="modal bottom-sheet">
          <div className="modal-content">
            <h4 className="esp-bot-base">Filtrar</h4>
            <form action="#">
              <p>
                <label>
                  <input type="checkbox" className="filled-in" />
                  <span>Con descuento</span>
                </label>
              </p>
              <p>
                <label>
                  <input type="checkbox" className="filled-in" defaultChecked />
                  <span>A menos de un Kilometro</span>
                </label>
              </p>
            </form>
          </div>
          <div className="modal-footer">
            <button
              href="#!"
              className="modal-close btn primario-btn shadow-2dp waves-effect waves-light width-100"
            >
              Aplicar filtro
            </button>
          </div>
        </div>
        {/* <!-- vista detalle producto y pod app sale -->       */}
      </div>
    );
  }
}
