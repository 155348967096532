import React, { Component } from "react";

export default class CardNoImage extends Component {
  render() {
    return (
      <div className="carousel-item" href="#one!">
        <div className="card">
          <div className="card-content">
            <span className="card-title">
              {" "}
              {this.props.title === "" ? "...NO TITLE" : this.props.title}
            </span>
            <p>
              {this.props.message === "" ? "...NO MESSAGE" : this.props.message}
            </p>
          </div>
          <div className="card-action">
            <div className="row">
              <div className="col s12 m12 l12">
                {this.props.url === "" ? (
                  <div></div>
                ) : (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.props.url === "" ? "#" : this.props.url}
                    className="btn hero-cta-btn shadow-2dp waves-effect waves-light width-100"
                  >
                    {this.props.url_button_legend === ""
                      ? "Ver más"
                      : this.props.url_button_legend}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
