import React, { Component } from "react";
import { Link } from "react-router-dom";
//import nomap from "../../img/nomap.jpg";
//import noimage from "../../img/noimage.jpg";

import CircularSmall from "../preloader/CircularSmall";
import M from "materialize-css";
//import DashboardService from '../../services/DashboardService';
import DashboardDonantesService from "../../services/DashboardDonantesService";
import Mapa from "../mapa/Mapa";
import globales from "../../config/config";

import {
  isGeoLocated,
  getLat,
  getLng,
  isActualRadius,
  getActualRadius,
  isNearbyOffers,
  getNearbyOffers,
  remNearbyOffers,
  setNearbyOffers,
  isNearbyMessages,
  getNearbyMessages,
  remNearbyMessages,
  setNearbyMessages,
  remLocation,
  setLocation,
  isStated,
  remStated,
  setStated,
  getStated,
} from "../../services/auth";
import CardNoImage from "../cards/CardNoImage";
import CardInfoImageHor from "../cards/CardInfoImageHor";
import CardInfoImageVer from "../cards/CardInfoImageVer";
import ImportantService from "../../services/ImportantService";

//import "../../css/eatcloud-ui.css";
//import "../../css/custom.css";

var globlalThis;

export default class Dashboard extends Component {

/* #region  CONSTRUCTOR ********************************************************/
/*******************************************************************************/
/*******************************************************************************/

  constructor(props) {
    super(props);
    this.state = {
      latitud: "",
      longitud: "",
      actual_sale_radius_km: 0,
      showProgressBarOffers: true,
      showProgressBarMessages: true,
      showProgressBarLastMinute: true,
      showProgressBarMapa: true,
      nearby_points: [],
      nearby_offers: [],
      nearby_messages: [],
      nearby_messages_error: [],
      nearby_offers_search: [],
      search_text: "",
      disabled: true,
    };
    globlalThis = this;
  }

/*******************************************************************************/
/*******************************************************************************/
/* #endregion ******************************************************************/

/* #region  COMPONENTDIDMOUNT **************************************************/
/*******************************************************************************/
/*******************************************************************************/

  componentDidMount() {
    // Auto initialize all the things!
    M.AutoInit();
    console.log("%c...[Dashboard.js] ESTAS EN /Dashboard", globales.consoleStyle);
    //remNearbyMessages();
    /* var el = document.querySelector(".carousel");
    var l = M.Carousel.getInstance(el);
    l.next(); */

    // create map
    /* this.map = L.map("map-canvas", {
      center: [49.8419, 24.0315],
      zoom: 11,
      layers: [
        L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        })
      ]
    }); */

    //this.getScheduleInCard("colombia", "u4bdtz0ie85xBZPKp1gaY");

    console.log("%c...[Dashboard.js] PREGUNTO EN EL LOCALSTORAGE SI HAY GEOLOCALIZACION", globales.consoleStyle);
    
    if (isGeoLocated()) {
      console.log("%c...[Dashboard.js] TIENE GUARDADA LA GEOLOCALIZACION", globales.consoleStyleSuccess);
      console.log("...[Dashboard.js] TIENE GUARDADA LA GEOLOCALIZACION EN EL LOCALSTORAGE Y LA TOMO",
        "\n>> LAT : ", getLat(), 
        "\n>> LNG : ", getLng());

      console.log("%c...[Dashboard.js] GUARDANDO EN EL STATE LATITUD-LONGITUD", globales.consoleStyle);

      this.setState({
        latitud: getLat(),
        longitud: getLng(),
      });

      if (getLat() !== "err-latitude" && getLng() !== "err-longitude") {
        console.log("%c...[Dashboard.js] TIENE GUARDADA UNA GEOLOCALIZACION VALIDA", globales.consoleStyle);
        
        console.log("%c...[Dashboard.js] PREGUNTO EN EL LOCALSTORAGE SI HAY ACTUAL RADIUS", globales.consoleStyle);
        
        if (isActualRadius()) {
          console.log("%c...[Dashboard.js] TIENE GUARDADO EL RADIO ACTUAL", globales.consoleStyleSuccess);
          console.log("...[Dashboard.js] ACTUAL RADIUS EN EL LOCALSTORAGE",
            "\n>> getActualRadius : ", getActualRadius(),
            "\n>> getActualRadius TIPO : ",typeof getActualRadius());

          console.log("%c...[Dashboard.js] GUARDANDO EN EL STATE actual_sale_radius_km", globales.consoleStyle);
          
          this.setState({
            actual_sale_radius_km: Number(getActualRadius()),
          });

          console.log("%c...[Dashboard.js] PREGUNTO EN EL LOCALSTORAGE SI HAY ACTUAL OFFERS", 
            globales.consoleStyle);

          if (isNearbyOffers()) {
            console.log("%c...[Dashboard.js] TIENE GUARDADAS LAS OFERTAS", globales.consoleStyleSuccess);
            setTimeout(() => {
              console.log("...[Dashboard.js] ACTUAL OFFERS EN EL LOCALSTORAGE",
                "\n>> getNearbyOffers : ", JSON.parse(getNearbyOffers()),
                "\n>> TIPO : ", typeof getNearbyOffers());

              const nearbyOffers = JSON.parse(getNearbyOffers());
              const nearbyOffersbyQuantity = this.filterOffersbyQuantity(nearbyOffers);
              const nearbyPointsbyLatLng = this.filterOffersbyLatLngPodId(nearbyOffersbyQuantity);

              console.log("...[Dashboard.js]",
                "\n>> nearbyOffers : ", nearbyOffers,
                "\n>> nearbyOffersbyQuantity : ", nearbyOffersbyQuantity,
                "\n>> filterOffersbyLatLngPodId : ", nearbyPointsbyLatLng);

               console.log("%c...[Dashboard.js] GUARDANDO EN EL STATE nearby_offers y nearby_points", globales.consoleStyle);

              this.setState({
                nearby_offers: nearbyOffersbyQuantity,
                nearby_points: nearbyPointsbyLatLng,
                showProgressBarMapa: !this.state.showProgressBarMapa,
                showProgressBarOffers: !this.state.showProgressBarOffers,
                showProgressBarLastMinute: !this.state.showProgressBarLastMinute,
                disabled: !this.state.disabled,
              });

              console.log("...[Dashboard.js]",
                "\n>> JSON.parse(getNearbyOffers()) : ", nearbyOffers,
                "\n>> this.state.nearby_offers : ", this.state.nearby_offers);

              const options = {
                duration: 200,
                indicators: true,
                dist: 0,
                shift: 50,
                padding: 0,
                numVisible: 1,
                fullWidth: true,
                noWrap: false,
                onCycleTo: () => {
                  console.log("%c...[Dashboard.js] nearbyOffers New Slide", globales.consoleStyle);
                },
              };
              M.Carousel.init(this.Carousel, options);
            }, 1000);

            console.log("...[Dashboard.js] CHANGE showProgressBarOffers to ", 
              !this.state.showProgressBarOffers);

            //setInterval(this.getMyLocation, 30000); // ESTA FUNCION BUSCA LA LOCALIZACION CADA 30 SEGUNDOS

          } else { // FIN DEL IF isNearbyOffers
            console.log("%c...[Dashboard.js] NO TIENE GUARDADAS LAS OFERTAS", globales.consoleStyleError);

            this.getNearbyOffersByRadius(
              getLat(),
              getLng(),
              Number(getActualRadius())
            );

          }//FIN DEL ELSE/IF isNearbyOffers

          // PREGUNTAR SI EXISTEN MENSAJES CERCANOS EN EL LOCALSTORAGE
          console.log("%c...[Dashboard.js] PREGUNTO EN EL LOCALSTORAGE SI HAY MENSAJES CERCANOS", 
            globales.consoleStyle);

          if (isNearbyMessages()) {
            console.log("%c...[Dashboard.js] TIENE GUARDADAS LOS MENSAJES", globales.consoleStyleSuccess);
            setTimeout(() => {
              console.log("...[Dashboard.js] MENSAJES CERCANOS EN EL LOCALSTORAGE",
                "\n>> getNearbyMessages : ", JSON.parse(getNearbyMessages()),
                "\n>> TIPO", typeof getNearbyMessages());
              
              const nearbyMessages = JSON.parse(getNearbyMessages());

              console.log("...[Dashboard.js]",
                "\n>> nearbyMessages : ", nearbyMessages);

              console.log("%c...[Dashboard.js] GUARDANDO EN EL STATE nearby_messages", globales.consoleStyle);

              this.setState({
                nearby_messages: nearbyMessages,
                showProgressBarMessages: !this.state.showProgressBarMessages,
              });

              console.log("...[Dashboard.js]",
                "\n>> JSON.parse(getNearbyMessages()) : ", nearbyMessages,
                "\n>> this.state.nearby_messages", this.state.nearby_messages);

              const options = {
                duration: 200,
                indicators: true,
                dist: 0,
                shift: 50,
                padding: 0,
                numVisible: 1,
                fullWidth: true,
                noWrap: false,
                onCycleTo: () => {
                  console.log("%c...[Dashboard.js] nearbyMessages New Slide", globales.consoleStyle);
                },
              };
              M.Carousel.init(this.Carousel, options);
            }, 1000);

            console.log("...[Dashboard.js] CHANGE showProgressBarMessages to",
              !this.state.showProgressBarMessages);

          } else { //FIN DEL IF isNearbyMessages
            console.log("%c...[Dashboard.js] NO TIENE GUARDADAS LOS MENSAJES", globales.consoleStyleError);

            this.getNearbyMessagesByRadius(
              getLat(),
              getLng(),
              Number(getActualRadius())
            );

          } //FIN DEL IF/ELSE isNearbyMessages

        } else {
          console.log("%c...[Dashboard.js] NO TIENE GUARDADO EL RADIO ACTUAL", globales.consoleStyleError);
          if (isNearbyOffers()) {
            console.log("%c...[Dashboard.js] NO RADIUS - TIENE GUARDADAS LAS OFERTAS", globales.consoleStyleSuccess);
            setTimeout(() => {
              console.log("...[Dashboard.js] ACTUAL OFFERS EN EL LOCALSTORAGE",
                "\n>> getNearbyOffers : ", JSON.parse(getNearbyOffers()),
                "\n>> TIPO : ", typeof getNearbyOffers());

              const nearbyOffers = JSON.parse(getNearbyOffers());
              
              console.log("...[Dashboard.js]",
                "\n>> nearbyOffers : ", nearbyOffers);

              console.log("%c...[Dashboard.js] GUARDANDO EN EL STATE nearby_offers", globales.consoleStyle);

              this.setState({
                nearby_offers: nearbyOffers,
                showProgressBarOffers: !this.state.showProgressBarOffers,
                showProgressBarLastMinute: !this.state.showProgressBarLastMinute,
              });

              console.log("...[Dashboard.js]",
                "\n>> JSON.parse(getNearbyOffers()) : ", nearbyOffers,
                "\n>> this.state.nearby_offers : ", this.state.nearby_offers);

              const options = {
                duration: 200,
                indicators: false,
                dist: 0,
                shift: 50,
                padding: 0,
                numVisible: 1,
                fullWidth: false,
                noWrap: false,
                onCycleTo: () => {
                  console.log("%c...[Dashboard.js] NO RADIUS - nearbyOffers New Slide", globales.consoleStyle);
                },
              };
              M.Carousel.init(this.Carousel, options);
            }, 1000);

            console.log("...[Dashboard.js] CHANGE showProgressBarOffers to",
              !this.state.showProgressBarOffers);

          } else {
            console.log("%c...[Dashboard.js] NO RADIUS - NO TIENE GUARDADAS LAS OFERTAS", globales.consoleStyleError);
            this.getNearbyOffersByRadius(getLat(), getLng(), 4);
          }
        }
      } else {
        console.log("%c...[Dashboard.js] TIENE GUARDADA UNA GEOLOCALIZACION CON ERROR", globales.consoleStyleError);
      }
    } else {
      //LA DEBERIA PEDIR DE NUEVO?
      console.log("%c...[Dashboard.js] NO TIENE GUARDADA LA GEOLOCALIZACION", globales.consoleStyleError);
    }

    //&eatc-user_id=_default&eatc-city=_default&eatc-country=_default
    //const params = JSON.stringify({'eatc-user_id': '_default', 'eatc-city': '_default','eatc-country': '_default' });
    //const params = JSON.stringify({'eatc-user_id': '_default', 'eatc-city': 'MEDELLIN,ITAGUI','eatc-country': 'co' });

    /* DashboardService.getQueryRadius('eatc_sale', params)
        .then((messages) => {
            console.log("Ocurrió un exito -> ", messages);            
        }).catch((err) => {
            console.log("Ocurrió un error -> ", err);
        }); */

    /* DashboardService.getQueryRadius('eatc_sale_messages', params)
        .then((messages) => {
            console.log("Ocurrió un exito -> ", messages);            
        }).catch((err) => {
            console.log("Ocurrió un error -> ", err);
        }); */
  }

/*******************************************************************************/
/*******************************************************************************/
/* #endregion ******************************************************************/

/* #region  METODOS IMPORTANTES ************************************************/
/*******************************************************************************/
/*******************************************************************************/

  recolectInfoGeolocated() {
    console.log("...[Dashboard.js] PREGUNTO EN EL LOCALSTORAGE SI HAY GEOLOCALIZACION");
    if (isGeoLocated()) {
      console.log(
        "...[Dashboard.js] TIENE GUARDADA LA GEOLOCALIZACION EN EL LOCALSTORAGE Y LA TOMO \nLAT",
        getLat(),
        "\nLNG",
        getLng()
      );
      console.log("...[Dashboard.js] GUARDADO EN EL STATE LATITUD-LONGITUD");
      this.setState({
        latitud: getLat(),
        longitud: getLng(),
      });
      if (getLat() !== "err-latitude" && getLng() !== "err-longitude") {
        console.log("...[Dashboard.js] TIENE GUARDADA UNA GEOLOCALIZACION VALIDA");
        console.log("...[Dashboard.js] PREGUNTO EN EL LOCALSTORAGE SI HAY ACTUAL RADIUS");

        console.log("...[Dashboard.js] PREGUNTO EN EL LOCALSTORAGE SI HAY ACTUAL RADIUS");
        if (isActualRadius()) {
          console.log(
            "...[Dashboard.js] ACTUAL RADIUS EN EL LOCALSTORAGE",
            getActualRadius(),
            "\nTIPO",
            typeof getActualRadius()
          );
          console.log("...[Dashboard.js] GUARDADO EN EL STATE LATITUD-LONGITUD");
          this.setState({
            actual_sale_radius_km: Number(getActualRadius()),
          });
          this.getNearbyOffersByRadius(
            getLat(),
            getLng(),
            Number(getActualRadius())
          );
          this.getNearbyMessagesByRadius(
            getLat(),
            getLng(),
            Number(getActualRadius())
          );
        } else {
          console.log("...[Dashboard.js] SI NO HAY ACTUAL RADIUS EN EL LOCALSTORAGE AGREGO");
          this.getNearbyOffersByRadius(getLat(), getLng(), 4);
          this.getNearbyMessagesByRadius(getLat(), getLng(), 4);
        }
      } else {
        console.log("...[Dashboard.js] TIENE GUARDADA UNA GEOLOCALIZACION CON ERROR");
      }
    } else {
      //LA DEBERIA PEDIR DE NUEVO?
      console.log("...[Dashboard.js] NO TIENE GUARDADA LA GEOLOCALIZACION");
    }
  }

/*******************************************************************************/

  filterOffersbyQuantity = (offers) => {
    // with return
    return offers.filter((offer) => offer["eatc-odd_quantity"] !== "0"); // return implicito
  };

/*******************************************************************************/

  filterOffersbyLatLngPodId = (offers) => {
    //importante para mostrar las MEJORES OFERTAS EN EL CARRUSEL
    offers.sort(
      (a, b) => b["eatc-odd_max_discount"] - a["eatc-odd_max_discount"]
    );
    // with return
    return offers.filter(
      (v, i, a) =>
        a.findIndex(
          (t) =>
            t["eatc-pod_lat"] === v["eatc-pod_lat"] &&
            t["eatc-pod_lon"] === v["eatc-pod_lon"]
          //Number(t["eatc-odd_max_discount"]) > Number(v["eatc-odd_max_discount"])
        ) === i
    ); // return implicito
  };

/*******************************************************************************/

  getScheduleObjects = () => {
    console.log("%c getScheduleObjects : ", globales.consoleStyle);
    const new_nearby_points = this.state.nearby_points;
    new_nearby_points.map((nearbyOffer, index) => {
      console.log(
        "getScheduleObjects -> nearby_points ",
        this.state.nearby_points
      );
      console.log(
        "getScheduleObjects -> nearbyOffer ",
        nearbyOffer,
        "index : ",
        index
      );

      if (nearbyOffer["eatc_cua_origin"] !== "") {
        if (nearbyOffer["eatc-pod_id"] !== "") {
          ImportantService.getSchedule(
            nearbyOffer["eatc_cua_origin"],
            nearbyOffer["eatc-pod_id"]
          )
            .then((schedule) => {
              console.log(
                "getScheduleObjects -> Ocurrió un exito -> ",
                schedule
              );
              const ordenamiento = {
                lu: 1,
                ma: 2,
                mi: 3,
                ju: 4,
                vi: 5,
                sa: 6,
                do: 7,
              };
              console.table(
                schedule.sort(
                  (a, b) =>
                    ordenamiento[a["eatc-day"]] - ordenamiento[b["eatc-day"]]
                )
              );
              console.log(
                "getScheduleObjects -> Ocurrió un exito 1-> ",
                schedule[0]
              );
              console.log(
                "getScheduleObjects -> Ocurrió un exito 2-> ",
                schedule[1]
              );
              nearbyOffer["eatc-day_initial"] = schedule[0]["eatc-day"];
              nearbyOffer["eatc-start_hour_initial"] =
                schedule[0]["eatc-start_hour"];
              nearbyOffer["eatc-final_hour_initial"] =
                schedule[0]["eatc-final_hour"];
              nearbyOffer["eatc-day_final"] = schedule[1]["eatc-day"];
              nearbyOffer["eatc-start_hour_final"] =
                schedule[1]["eatc-start_hour"];
              nearbyOffer["eatc-final_hour_final"] =
                schedule[1]["eatc-final_hour"];
              console.log(
                "getScheduleObjects -> Ocurrió un exito TOTAL -> nearbyOffer : ",
                nearbyOffer
              );
              console.log(
                "getScheduleObjects -> Ocurrió un exito TOTAL -> this.state.nearby_points : ",
                this.state.nearby_points
              );
              console.log(
                "getScheduleObjects -> Ocurrió un exito TOTAL -> new_nearby_points : ",
                new_nearby_points
              );
            })
            .catch((err) => {
              console.log("getScheduleObjects -> Ocurrió un error -> ", err);
            });
        }
      } else {
        console.log(
          "%c getScheduleObjects -> nearbyOffer con eatc_cua_origin vacia",
          globales.consoleStyleError
        );
      }
      return true;
    });
    console.log("getScheduleObjects : ", new_nearby_points);
  };

/*******************************************************************************/

  goTolist = () => {
    console.log("nearby_offers_search : ", this.state.nearby_offers_search);
  };

/*******************************************************************************/

  redirectToTarget = () => {
    console.log("redirect");
    this.props.history.push({ pathname: "/listaproductos" });
  };

/*******************************************************************************/

  redirectToTarget = (target) => {
    console.log("...[Dashboard.js] REDIRIGIR A ", target);
    this.props.history.push(target);
  };

/*******************************************************************************/

  redirectToTargetOffers = (target) => {
    console.log("...[Dashboard.js] REDIRIGIR A ", target);
    this.props.history.push({
      pathname: target,
      state: {
        nearby_offers_search: this.state.nearby_offers,
      },
    });
  };

/*******************************************************************************/

  filter = (event) => {
    var search_text = event.target.value;
    console.log("%c search_text : " + search_text, globales.consoleStyle);
    const data = this.state.nearby_offers;
    const newData = data.filter(function (item) {
      console.log("Item : ", item);
      console.log("Item : ", item["eatc-odd_name"]);
      console.log("Item : ", item["eatc-odd_description"]);
      console.log("Item : ", item["eatc-pod_name"]);
      const eatcOddName = item["eatc-odd_name"].toUpperCase();
      const eatcOddDescription = item["eatc-odd_description"].toUpperCase();
      const eatcPodName = item["eatc-pod_name"].toUpperCase();
      const campo = eatcOddName + " " + eatcOddDescription + " " + eatcPodName;
      const textData = search_text.toUpperCase();
      return campo.indexOf(textData) > -1;
    });
    console.log("newData : ", newData);
    this.setState({
      nearby_offers_search: newData,
      search_text: search_text,
    });
  };

/*******************************************************************************/

  getNearbyOffersByRadius = (lat, lng, km) => {
    console.log(
      "...[Dashboard.js] GET OFERTAS CERCANAS en " + km + "KM y LOCATION(",
      lat,
      ",",
      lng,
      ") (ASINCRONO)"
    );
    let that = this;
    DashboardDonantesService.getNearbyOffers(
      "eatc_sale",
      "eatc-pod_lat,eatc-pod_lon",
      lat,
      lng,
      "_id,eatc-code,eatc-pod_id,eatc-pod_name,eatc-pod_address,eatc-pod_lat,eatc-pod_lon,eatc-odd_name,eatc-odd_description,eatc-odd_unit_price,eatc-odd_min_sale_unit_price,eatc-odd_max_discount,eatc-odd_image,eatc-odd_state,eatc_cua_origin,eatc-odd_quantity",
      km,
      "eatc-odd_state",
      "sale,partially_ordered",
      null
    ).then((offers) => {
        console.log("Ocurrió un exito -> ", offers);
        const nearbyOffersbyQuantity = this.filterOffersbyQuantity(offers);
        const nearbyPointsbyLatLng = this.filterOffersbyLatLngPodId(
          nearbyOffersbyQuantity
        );
        console.log("nearbyOffersbyQuantity : ", nearbyOffersbyQuantity);
        console.log("filterOffersbyLatLngPodId : ", nearbyPointsbyLatLng);
        this.setState({
          showProgressBarMapa: !this.state.showProgressBarMapa,
          nearby_offers: nearbyOffersbyQuantity,
          nearby_points: nearbyPointsbyLatLng,
          showProgressBarOffers: !this.state.showProgressBarOffers,
          showProgressBarLastMinute: !this.state.showProgressBarLastMinute,
          disabled: !this.state.disabled,
        });

        console.log("@ offers", offers);
        console.log("@ this.state.nearby_offers", this.state.nearby_offers);

        if (isNearbyOffers()) {
          console.log(
            "...[Dashboard.js] ACTUAL OFFERS EN EL LOCALSTORAGE",
            JSON.parse(getNearbyOffers()),
            "\nTIPO",
            typeof getNearbyOffers()
          );
          remNearbyOffers();
          setNearbyOffers(offers);
        } else {
          console.log("...[Dashboard.js] NO TIENE GUARDADAS LAS OFERTAS");
          setNearbyOffers(offers);
        }

        const options = {
          duration: 200,
          indicators: false,
          dist: 0,
          shift: 50,
          padding: 0,
          numVisible: 1,
          fullWidth: false,
          noWrap: false,
          onCycleTo: () => {
            console.log("New Slide");
          },
        };
        M.Carousel.init(this.Carousel, options);
      })
      .catch((err) => {
        console.log("...[Dashboard.js] Ocurrió un \n>> error -> ", err);
        if (err.response.statusText.toLowerCase() === "ok" && err.response.status === 400) {
          console.log("%c...[Dashboard.js] getNearbyOffers ERROR TIMEOUT - MENSAJES ACTUALES ( TOAST ) -> ", globales.consoleStyle);
          M.toast({
            html:
              "Tiempo de respuesta agotado para obtener las ofertas, Reintentando ...",
            displayLength: 3000,
            completeCallback: function () {
              that.getNearbyOffersByRadius(lat, lng, (km*=5));
            },
          });
        }
      });
  };

/*******************************************************************************/

  getNearbyMessagesByRadius = (lat, lng, km) => {
    console.log("%c...[Dashboard.js] GET MENSAJES CERCANOS en " + km + "KM y LOCATION(" +
      lat + "," + lng + ") (ASINCRONO)", globales.consoleStyle);

    let that = this;

    DashboardDonantesService.getNearbyMessages(
      "eatc_sale_messages",
      "eatc-lat,eatc-lon",
      lat,
      lng,
      "_id,code,date,title,url,url_button_legend,url_button_icon,image_url,order,display_conditions,display_time_sec,published_since,published_until,message",
      km
    ).then((messages) => {
        console.log("%c...[Dashboard.js] getNearbyMessages DONE MENSAJES ACTUALES", globales.consoleStyleSuccess);
        console.log("...[Dashboard.js] getNearbyMessages DONE MENSAJES ACTUALES",
          "\n>> messages : ", messages);
        console.log("%c...[Dashboard.js] getNearbyMessages DONE" + 
          "\n GUARDANDO MENSAJES ACTUALES nearby_messages en STATE", globales.consoleStyle);

        this.setState({
          nearby_messages: messages,
          showProgressBarMessages: !this.state.showProgressBarMessages,
        });

        if (isNearbyMessages()) {
          console.log("%c...[Dashboard.js] TIENE GUARDADOS MENSAJES VIEJOS EN LOCALSTORAGE", 
            globales.consoleStyleSuccess);
          console.log(
            "...[Dashboard.js] MENSAJES VIEJOS",
            "\n>> getNearbyMessages : ", JSON.parse(getNearbyMessages()),
            "\n>> TIPO : ", typeof getNearbyMessages());
          console.log("%c...[Dashboard.js] ELIMINAR LOS MENSAJES GUARDADOS EN LOCALSTORAGE"+
            "REEMPLAZAR CON LOS MENSAJES ACTUALES", globales.consoleStyleSuccess);
          remNearbyMessages();
          setNearbyMessages(messages);
        } else {
          console.log("%c...[Dashboard.js] NO TIENE GUARDADOS MENSAJES EN LOCALSTORAGE"+ 
          "ASI QUE GUARDAMOS LOS MENSAJES ACTUALES", globales.consoleStyleError);
          setNearbyMessages(messages);
        }

        console.log("...[Dashboard.js]",
          "\n>> messages : ", messages,
          "\n>> this.state.nearby_messages : ", this.state.nearbyMessages);

        const options = {
          duration: 200,
          indicators: true,
          dist: 0,
          shift: 50,
          padding: 0,
          numVisible: 1,
          fullWidth: true,
          noWrap: false,
          onCycleTo: () => {
            console.log("%c...[Dashboard.js] from getNearbyMessagesByRadius nearbyMessages New Slide", globales.consoleStyle);
          },
        };
        M.Carousel.init(this.Carousel, options);
      })
      .catch((err) => {
        console.log("%c...[Dashboard.js] getNearbyMessages ERROR MENSAJES ACTUALES", globales.consoleStyleError);
        console.log("...[Dashboard.js] getNearbyMessages ERROR MENSAJES ACTUALES",
          "\n>> ERROR : ", err);

        /*console.log("%c...[Dashboard.js] getNearbyMessages ERROR MENSAJES ACTUALES" + 
          "\n OCULTO EL SPINNER y CAMBIO LAS CONFIGURACIONES DEL CARRUSEL", globales.consoleStyleError);

        this.setState({
          showProgressBarMessages: !this.state.showProgressBarMessages,
        });*/

        if (err.response.statusText.toLowerCase() === "timeout" || err.response.status === 403) {
          console.log("%c...[Dashboard.js] getNearbyMessages ERROR TIMEOUT - MENSAJES ACTUALES ( TOAST ) -> ", globales.consoleStyle);
          M.toast({
            html:
              "Tiempo de respuesta agotado para obtener los mensajes, Reintentando ...",
            displayLength: 3000,
            completeCallback: function () {
              that.getNearbyMessagesByRadius(lat, lng, km);
            },
          });
        }else{
          this.getNearbyMessagesError(8);
        }
        
        /*const options = {
          duration: 200,
          indicators: true,
          dist: 0,
          shift: 50,
          padding: 0,
          numVisible: 1,
          fullWidth: true,
          noWrap: false,
          onCycleTo: () => {
            console.log("%c...[Dashboard.js] from getNearbyMessagesByRadius ERROR - nearbyMessages New Slide", globales.consoleStyle);
          },
        };
        M.Carousel.init(this.Carousel, options);*/

      });
  };

/*******************************************************************************/

  getNearbyMessagesError = (id) => {
    console.log("%c...[Dashboard.js] getNearbyMessagesError GET MENSAJES CERCANOS ERROR "+
      "\n>> id : "+id+" (ASINCRONO)", globales.consoleStyle);
    let that = this;
    DashboardDonantesService.getNearbyMessagesError(
      "eatc_sale_messages",
      id
    ).then((messages) => {
        console.log("%c...[Dashboard.js] getNearbyMessagesError DONE MENSAJES CERCANOS ERROR", globales.consoleStyleSuccess);
        console.log("...[Dashboard.js] getNearbyMessagesError DONE MENSAJES CERCANOS ERROR",
          "\n>> messages : ", messages);
        console.log("%c...[Dashboard.js] getNearbyMessagesError DONE" + 
          "\n GUARDANDO MENSAJES ACTUALES nearby_messages en STATE", globales.consoleStyle);

        this.setState({
          nearby_messages_error: messages,
          showProgressBarMessages: !this.state.showProgressBarMessages,
        });

    }).catch((err) => {
        console.log("%c...[Dashboard.js] getNearbyMessagesError ERROR MENSAJES  CERCANOS ERROR", globales.consoleStyleError);
        console.log("...[Dashboard.js] getNearbyMessagesError ERROR MENSAJES  CERCANOS ERROR",
          "\n>> ERROR : ", err);

        console.log("%c...[Dashboard.js] getNearbyMessagesError ERROR MENSAJES  CERCANOS ERROR" + 
          "\n OCULTO EL SPINNER y CAMBIO LAS CONFIGURACIONES DEL CARRUSEL", globales.consoleStyleError);

        if (err.response.statusText.toLowerCase() === "timeout" || err.response.status === 403) {
          console.log("%c...[Dashboard.js] getNearbyMessagesError ERROR TIMEOUT - MENSAJES CERCANOS ERROR ( TOAST ) -> ", globales.consoleStyle);
          M.toast({
            html:
              "Tiempo de respuesta agotado para obtener los mensajes, Reintentando ...",
            displayLength: 3000,
            completeCallback: function () {
              that.getNearbyMessagesError(id);
            },
          });
        } else {
           this.setState({
            showProgressBarMessages: !this.state.showProgressBarMessages,
          });
        }
    });
  }

/*******************************************************************************/

  getScheduleInCard = (eatc_cua_origin, eatc_pod_id) => {
    console.log(
      "...[Dashboard.js] GET HORARIO DE OFERTAS en eatc_cua_origin : " +
        eatc_cua_origin +
        " y eatc_pod_id : " +
        eatc_pod_id +
        " (ASINCRONO)",
      globales.consoleStyle
    );
    if (eatc_cua_origin !== "") {
      if (eatc_pod_id !== "") {
        ImportantService.getSchedule(eatc_cua_origin, eatc_pod_id)
          .then((offers) => {
            console.log("Ocurrió un exito -> ", offers);
          })
          .catch((err) => {
            console.log("Ocurrió un error -> ", err);
          });
      }
    }
  };

/*******************************************************************************/

  renderMessagesCarousel() {
    if(Object.entries(this.state.nearby_messages).length !== 0) {
      return this.state.nearby_messages.map((nearbyMessage, index) => (
        <CardNoImage
          key={index}
          title={nearbyMessage.title}
          message={nearbyMessage.message}
          url={nearbyMessage.url}
          url_button_legend={nearbyMessage.url_button_legend}/>
      ));
    }else if(Object.entries(this.state.nearby_messages_error).length !== 0) {
      return this.state.nearby_messages_error.map((nearbyMessageError, index) => (
        <CardNoImage
          key={index}
          title={nearbyMessageError.title}
          message={nearbyMessageError.message}
          url={nearbyMessageError.url}
          url_button_legend={nearbyMessageError.url_button_legend}/>
      ));
    }else{
      return (<CardNoImage
          key={0}
          title={"Información no disponible"}
          message={"Información no disponible"}
          url={""}
          url_button_legend={""}/>);
    }
    
  }

/*******************************************************************************/

  renderOffersCarousel() {
    //this.state.nearby_offers.sort((a, b) => a["km"] - b["km"]);
    this.state.nearby_points.sort((a, b) => a["km"] - b["km"]);

    //this.state.nearby_offers.map((nearbyOffer, index) => (
    return this.state.nearby_points.map((nearbyOffer, index) => (
      <CardInfoImageHor
        key={index}
        offer={nearbyOffer}
        src={nearbyOffer["eatc-odd_image"]}
        max_discount={nearbyOffer["eatc-odd_max_discount"]}
        name={nearbyOffer["eatc-pod_name"]}
        address={nearbyOffer["eatc-pod_address"]}
        km={nearbyOffer["km"]}
        eatc_cua_origin={nearbyOffer["eatc_cua_origin"]}
        eatc_pod_id={nearbyOffer["eatc-pod_id"]}
        eatc_code={nearbyOffer["eatc-code"]}
        latitud_actual={this.state.latitud}
        longitud_actual={this.state.longitud}
        km_actual={this.state.actual_sale_radius_km}
      />
    ));
  }

/*******************************************************************************/

  renderOffersLastMinute() {
    return this.state.nearby_offers.map((nearbyOffer, index) => (
      <CardInfoImageVer
        key={index}
        offer={nearbyOffer}
        odd_image={nearbyOffer["eatc-odd_image"]}
        odd_max_discount={nearbyOffer["eatc-odd_max_discount"]}
        odd_name={nearbyOffer["eatc-odd_name"]}
        odd_unit_price={nearbyOffer["eatc-odd_unit_price"]}
        odd_min_sale_unit_price={nearbyOffer["eatc-odd_min_sale_unit_price"]}
        km={nearbyOffer["km"]}
        eatc_cua_origin={nearbyOffer["eatc_cua_origin"]}
        eatc_pod_id={nearbyOffer["eatc-pod_id"]}
        eatc_code={nearbyOffer["eatc-code"]}
      />
    ));
  }

/*******************************************************************************/

  getMyLocation() {
    console.log("%c...[Dashboard.js] OBTENIENDO GEOLOCALIZACION (ASINCRONO)",
      globales.consoleStyle
    );
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          console.log(
            "%c...[Dashboard.js] GEOLOCALIZACION OBTENIDA (EXITO)",
            globales.consoleStyleSuccess
          );
          console.log(
            "LATITUD-LONGITUD",
            position.coords.latitude,
            position.coords.longitude
          );

          console.log(
            "geolocalizacion " +
              getLat() +
              " " +
              getLng() +
              " en " +
              Number(getActualRadius()) +
              "KM"
          );

          console.log(
            "DISTANCIA EN KM ",
            globlalThis.getKilometros(
              position.coords.latitude,
              position.coords.longitude,
              Number(getLat()),
              Number(getLng())
            )
          );

          if (position.coords.latitude !== Number(getLat())) {
            if (position.coords.longitude !== Number(getLng())) {
              console.log(
                "%c...[Dashboard.js] GEOLOCALIZACION CAMBIO",
                globales.consoleStyleSuccess
              );
              var distance = parseInt(
                globlalThis.getKilometros(
                  position.coords.latitude,
                  position.coords.longitude,
                  Number(getLat()),
                  Number(getLng())
                )
              );
              console.log("DISTANCIA ENTRE LOS DOS PUNTOS", distance, "KM");
              if (distance > 1) {
                console.log(
                  "%c...[Dashboard.js] POR FAVOR ACTUALIZAR APIS",
                  globales.consoleStyleSuccess
                );
                globlalThis.getNearbyOffersByRadius(
                  position.coords.latitude,
                  position.coords.longitude,
                  Number(getActualRadius())
                );
              }
            }
          }

          /* this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });

          this.getLocationState(
            position.coords.latitude,
            position.coords.longitude
          ); */
        },
        (error) => {
          console.log(
            "%c...[Dashboard.js] GEOLOCALIZACION NO OBTENIDA (ERROR)",
            globales.consoleStyleError
          );
          console.log("(ERROR)", error);
          console.log(
            "%c...[Dashboard.js] GUARDO EN EL STATE LATITUD(ERROR)-LONGITUD(ERROR)",
            globales.consoleStyleError
          );

          console.log("...[Dashboard.js] PREGUNTO EN EL LOCALSTORAGE SI HAY LATITUD-LONGITUD");
          if (isGeoLocated()) {
            console.log("...[Dashboard.js] SI HAY LATITUD-LONGITUD EN EL LOCALSTORAGE BORRO");
            remLocation();
            console.log(
              "...[Dashboard.js] AGREGO NUEVA LATITUD-LONGITUD(ERROR) EN EL LOCALSTORAGE"
            );
            setLocation("err-latitude", "err-longitude");
          } else {
            console.log(
              "...[Dashboard.js] SI NO HAY LATITUD-LONGITUD(ERROR) EN EL LOCALSTORAGE AGREGO"
            );
            setLocation("err-latitude", "err-longitude");
          }
          console.log(
            "...[Dashboard.js] LOCATION EN EL LOCALSTORAGE",
            getLat(),
            " - ",
            getLng()
          );

          console.log("...[Dashboard.js] PREGUNTO EN EL LOCALSTORAGE SI HAY CITY");
          if (isStated()) {
            console.log("...[Dashboard.js] SI HAY CITY EN EL LOCALSTORAGE BORRO");
            remStated();
            console.log("...[Dashboard.js] AGREGO NUEVA CITY EN EL LOCALSTORAGE");
            setStated("err-state");
          } else {
            console.log("...[Dashboard.js] SI NO HAY CITY EN EL LOCALSTORAGE AGREGO");
            setStated("err-state");
          }
          console.log(
            "...[Dashboard.js] CITY EN EL LOCALSTORAGE",
            getStated(),
            "\nTIPO",
            typeof getStated()
          );

          /*this.setState({
            latitude: "err-latitude",
            longitude: "err-longitude",
            state: "err-state",
            cities: "err-cities",
          });*/

          // REDIRIGIR A ONBOARDING
          //this.redirectToTarget("/onboarding");
          //Si quiero pasar la latitud y longitud por history
          //this.redirectToTargetLatLng(this.state.latitude, this.state.longitude);
        }
      );
    }
  }

/*******************************************************************************/

  getKilometros = (lat1, lon1, lat2, lon2) => {
    var rad = (x) => {
      return (x * Math.PI) / 180;
    };
    var R = 6378.137; //Radio de la tierra en km
    var dLat = rad(lat2 - lat1);
    var dLong = rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(lat1)) *
        Math.cos(rad(lat2)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d.toFixed(2); //Retorna tres decimales
  };

/*******************************************************************************/

  intervalTest() {
    var fecha = new Date();
    var h = 0;
    var m = 0;
    var s = 0;
    h = fecha.getHours();
    m = fecha.getMinutes();
    s = fecha.getSeconds();
    if (h < 10) {
      h = "0" + h;
    }
    if (m < 10) {
      m = "0" + m;
    }
    if (s < 10) {
      s = "0" + s;
    }
    alert(h + ":" + m + ":" + s);
  }

/*******************************************************************************/

  intervalSetGeoLocation() {
    console.log(
      "geolocalizacion " +
        getLat() +
        " " +
        getLng() +
        " en " +
        Number(getActualRadius()) +
        "KM"
    );
    this.getMyLocation();
    //this.getNearbyOffersByRadius(getLat(), getLng(), Number(getActualRadius()));
  }

/*******************************************************************************/
/*******************************************************************************/
/* #endregion ******************************************************************/

/* #region  RENDER *************************************************************/
/*******************************************************************************/
/*******************************************************************************/

  render() {
    const divStyleZ = { zIndex: "1000" };
    const divStyleMBn24 = { marginBottom: "-24px" };
    //const divStyleMTn24 = { marginTop: "-200px" };
    const divStyleMTn19 = { marginTop: "-19px", marginBottom: "-10px" };
    //const divStyleMTp16 = { marginTop: "16px" };
    const divStyleHeight200 = { height: "200px", marginTop: "-200px" };
    const divStyleMinheight70 = { display: "block", minHeight: "70px" };
    /*const divStyleMap = {
      position: "absolute",
      top: "0",
      right: "0",
      zIndex: "2",
      backgroundImage: "none",
      backgroundColor: "transparent",
    };*/

    // const divStyleHeight200 = { height: "200px", minHeight: "200px" };
    // const divStyleHeight600 = { height: "600px" };

    console.log("...[Dashboard.js] \n>> this.state.nearby_offers", this.state.nearby_offers);

    return (
      <div className="tinta-bg">
        <div className="blanco-bg shadow-8dp" style={divStyleZ}>
          <div className="row" style={divStyleMBn24}>
            <div className="col s12 m12 l12">
              {/* <div className="col s11 m11 l11"> */}
              <div className="input-field" style={divStyleMTn19}>
                <input
                  id="search"
                  placeholder="Search..."
                  disabled={this.state.disabled ? "disabled" : ""}
                  /*  onClick={() => {
                    this.redirectToTarget("/listaproductos");
                  }} */
                  onClick={() => {
                    this.redirectToTargetOffers("/listaproductos");
                  }}
                />
                <i className="material-icons icn-in-input">search</i>
              </div>
            </div>
            {/* <div className="col s1 m1 l1">
              <div style={divStyleMTp16}>
                <Link to="#" className="right">
                  <i className="material-icons">shopping_basket</i>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
        {/* <!-- map --> */}
        {/* <div className="map" style={divStyleMap}></div> */}
        {this.state.showProgressBarMapa && <CircularSmall />}
        {!this.state.showProgressBarMapa && (
          <Mapa
            className="map"
            lat={this.state.latitud}
            lng={this.state.longitud}
            points={this.state.nearby_points}
          />
        )}

        <div className="container">
          <div className="row">
            <div className="col s12 m12 l12">
              {this.state.showProgressBarOffers && <CircularSmall />}
              {!this.state.showProgressBarOffers && (
                <div ref={(Carousel) => {this.Carousel = Carousel;}}
                  className="carouseled carousel carousel-slider"
                  style={divStyleHeight200}>
                  {this.renderOffersCarousel()}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <!-- list --> */}

        <div className="container">
          <div className="row esp-top-base">
            {/* <div className="col s12 m7"> */}
            <div className="col s12 m8 offset-m2 l6 offset-l3">
              {this.state.showProgressBarMessages && <CircularSmall />}
              {!this.state.showProgressBarMessages && (
                <div ref={(Carousel) => {this.Carousel = Carousel;}}
                  className="carousel carousel-slider carouseling">
                  {this.renderMessagesCarousel()}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col s12 center-align">
              <br />
              <Link to={{ pathname: `/carrocompras` }}>
                <i className="material-icons right">shopping_cart</i><br />
              </Link>						
            </div>
          </div>
          <div className="row margin-top-minus-20">
            <div className="col s7 m7 l7">
              <h3>Ultimo minuto</h3>
            </div>
            <div className="col s5 m5 l5">
              <Link to="#" className="btn link-btn esp-top-base right ver_todo">
                Ver todo
              </Link>
            </div>
          </div>

          <div className="row">
            {this.state.showProgressBarLastMinute && <CircularSmall />}
            {!this.state.showProgressBarLastMinute &&
              this.renderOffersLastMinute()}
          </div>

          <div className="row">
            <div style={divStyleMinheight70}></div>
          </div>
        </div>

        <div className="container hide-on-med-and-up main-footer">
          <footer className="page-footer footer-fixed">
            <div className="container-footer">
              <div className="row">
                <div className="col l2 m2 s2">
                  {/* <a className="waves-effect waves-light btn accbtn" data-accion="/segim_anuncios">
			                    <i className="material-icons material-icons-outlined top">home</i>
                              <span>Inicio</span>
			                </a> */}
                  <button
                    className="waves-effect waves-light btn accbtn"
                    onClick={() => {
                      this.redirectToTarget("/dashboardsale");
                    }}
                  >
                    <i className="material-icons material-icons-outlined top">
                      home
                    </i>
                    <span>Inicio</span>
                  </button>
                </div>
                <div className="col l2 m2 s2">
                  {/*  <a className="waves-effect waves-light btn accbtn" data-accion="/segim_anuncios">
			                    <i className="material-icons material-icons-outlined top">new_releases</i><span>Promos</span>
			                </a> */}
                  <button
                    className="waves-effect waves-light btn accbtn"
                    onClick={() => {
                      this.redirectToTarget("/listaproductos");
                    }}
                  >
                    <i className="material-icons material-icons-outlined top">
                      new_releases
                    </i>
                    <span>Promos</span>
                  </button>
                </div>
                <div className="col l3 m3 s3">
                  {/* <a className="waves-effect waves-light btn accbtn" data-accion="/segim_anuncios">
			                    <i className="material-icons material-icons-outlined top">shopping_basket</i><span>Tus pedidos</span>
			                </a> */}
                  <button
                    className="waves-effect waves-light btn accbtn"
                    onClick={() => {
                      this.redirectToTarget("/tuspedidos");
                    }}
                  >
                    <i className="material-icons  material-icons-outlined top">
                      shopping_basket
                    </i>
                    <span>Tus Pedidos</span>
                  </button>
                </div>
                <div className="col l3 m3 s3">
                  {/* <a className="waves-effect waves-light btn accbtn" data-accion="/segim_anuncios">
			                    <i className="material-icons material-icons-outlined top">person_outline</i><span>Tu perfil</span>
			                </a> */}

                  <button
                    className="waves-effect waves-light btn accbtn"
                    onClick={() => {
                      this.redirectToTarget("/tuperfil");
                    }}
                  >
                    <i className="material-icons material-icons-outlined top">
                      person_outline
                    </i>
                    <span>Tu Perfil</span>
                  </button>
                </div>
                <div className="col l2 m2 s2">
                  {/* <a className="waves-effect waves-light btn accbtn" data-accion="/segim_anuncios">
			                    <i className="material-icons material-icons-outlined top">help_outline</i><span>Ayuda</span>
			                </a> */}
                  <button
                    className="waves-effect waves-light btn accbtn"
                    onClick={() => {
                      window.open("https://m.me/eatcloudhelpdesk", "_blank");
                    }}
                  >
                    <i className="material-icons material-icons-outlined top">
                      help_outline
                    </i>
                    <span>Ayuda</span>
                  </button>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }

/*******************************************************************************/
/*******************************************************************************/
/* #endregion ******************************************************************/
}
