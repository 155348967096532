//{{URL_entorno_donantes}}/get/eatcloud/getpuntos?table=eatc_sale&fieldname=eatc-pod_lat,eatc-pod_lon&fieldvalue=6.25177777,-75.59877777&showfield=eatc-code,eatc-pod_id,eatc-pod_name,eatc-pod_address,eatc-pod_lat,eatc-pod_lon,eatc-odd_name,eatc-odd_description,eatc-odd_unit_price,eatc-odd_min_sale_unit_price,eatc-odd_max_discount,eatc-odd_image,eatc-odd_state,eatc_cua_origin&km=5&filterfield_1=eatc-odd_state&filtervalue_1=sale,partially_ordered
//{{URL_entorno_beneficiarios}}/get/eatcloud/getpuntos?table=eatc_sale_messages&fieldname=eatc-lat,eatc-lon&fieldvalue=6.2045697,-75.60157&showfield=_id,code,date,title,url,url_button_legend,url_button_icon,image_url,order,display_conditions,display_time_sec,published_since,published_until&km=50
import API_BENF from "./config";
import axios from "axios";
import globales from "../config/config";

const BASE_URL = globales._dona;
const API = axios.create({
  baseURL: BASE_URL,
});

const DASHBOARD_ENDPOINTS = {
  API_EATCLOUD: "api/eatcloud/",
  GET: "get/",
  GET_DATA: "get/data/",
  GET_EATCLOUD: "get/eatcloud/",
  GET_PUNTOS: "getpuntos?",
  EATC_SALE_QUERY_RADIUS: "eatc_sale_query_radius?",
  EATC_QUERY_TYPE: "eatc-query_type=",
  EATC_TABLE_TYPE: "table=",
  EATC_FIELDNAME: "fieldname=",
  EATC_FIELDVALUE: "fieldvalue=",
  EATC_SHOWFIELD: "showfield=",
  EATC_USER_ID: "eatc-user_id=",
  EATC_KM: "km=",
  ORDER: "order=",
  FILTERFIELD: "filterfield_1=",
  FILTERVALUE: "filtervalue_1=",
  FILTERFIELD2: "filterfield_2=",
  FILTERVALUE2: "filtervalue_2=",
  FILTERFIELD3: "filterfield_3=",
  FILTERVALUE3: "filtervalue_3=",
  FILTERFIELD4: "filterfield_4=",
  FILTERVALUE4: "filtervalue_4=",
  QUESTION: "?",
  CONCAT: "&",
  EQUAL: "=",
  COMA: ",",
};

const DashboardDonantesService = {
  getNearbyOffers: (table, fieldname, lat, lng, showfield, km, filterfield, filtervalue, params) =>
    new Promise((resolve, reject) => {
      console.log("%c$...[DashboardDonantesService.js] Entrando a DashboardService.getNearbyOffers()",
        globales.consoleStyle);
      console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyOffers(",
        "\n>> queryType :", table,
        "\n>> params :", params, ")");

      const PARAMS = JSON.parse(params);

      console.log("$...[DashboardDonantesService.js] \n>> PARAMS :", PARAMS);
      var paramsConsulta = "";
      for (var x in PARAMS) {
        paramsConsulta += DASHBOARD_ENDPOINTS.CONCAT +
          x + DASHBOARD_ENDPOINTS.EQUAL + PARAMS[x];
      }

      console.log("$...[DashboardDonantesService.js] \n>> paramsConsulta : ", paramsConsulta);

      console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyOffers()"+
          "\n>> URL : " +
          BASE_URL +
          DASHBOARD_ENDPOINTS.GET +
          globales._user +
          DASHBOARD_ENDPOINTS.GET_PUNTOS +
          DASHBOARD_ENDPOINTS.EATC_TABLE_TYPE +
          table +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDNAME +
          fieldname +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDVALUE +
          lat.toString() +
          DASHBOARD_ENDPOINTS.COMA +
          lng.toString() +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_SHOWFIELD +
          showfield +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_KM +
          km +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERFIELD +
          filterfield +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERVALUE +
          filtervalue,
        globales.consoleStyle);

      API.get(
        DASHBOARD_ENDPOINTS.GET +
          globales._user +
          DASHBOARD_ENDPOINTS.GET_PUNTOS +
          DASHBOARD_ENDPOINTS.EATC_TABLE_TYPE +
          table +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDNAME +
          fieldname +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDVALUE +
          lat.toString() +
          DASHBOARD_ENDPOINTS.COMA +
          lng.toString() +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_SHOWFIELD +
          showfield +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_KM +
          km +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERFIELD +
          filterfield +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERVALUE +
          filtervalue
      ).then((res) => {
          console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyOffers DONE",
            "\n>> res : ", res,
            "\n>> res.data : ", res.data,
            "\n>> res.data.res: ", res.data.res,
            "\n>> res.status : ", res.status,
            "\n>> res.statusText : ", res.statusText);

          return res.data.res;

      }).then((data) => {
          console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyOffers() SUCCESS",
            globales.consoleStyleSuccess);
          console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyOffers() SUCCESS",
            "\n>> data : ", data,
            "\n>> data[0] : ", data[0]);

          resolve(data);

      }).catch((err) => {
           // Error 😨😨😨😨😨😨
          console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyOffers() ERROR",
            globales.consoleStyleError);
          console.error("$...[DashboardDonantesService.js] \n>> ERROR : ", err);
          console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE : ", err.response);
          // Error 😨
          if (err.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.DATA : ", err.response.data);
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.STATUS : ", err.response.status);
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.HEADERS : ", err.response.headers);
          } else if (err.request) {
            /*
             * The request was made but no response was received, `err.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.REQUEST : ", err.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.MESSAGE : ", err.message);
          }

          console.error("$...[DashboardDonantesService.js] \n>> ERROR.CONFIG : ", err.config);

          reject(err);

      });
    }),
  getNearbyOffersByLocation: (table, fieldname, lat, lng, showfield, km, filterfield, filtervalue, filterfield2, filtervalue2, filterfield3, filtervalue3, params) =>
    new Promise((resolve, reject) => {
      console.log("%c$...[DashboardDonantesService.js] Entrando a DashboardService.getNearbyOffersByLocation()",
        globales.consoleStyle);

      console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyOffersByLocation(",
        "\n>> queryType :", table,
        "\n>> params :", params,")");

      const PARAMS = JSON.parse(params);

      console.log("$...[DashboardDonantesService.js] \n>> PARAMS :", PARAMS);

      var paramsConsulta = "";
      for (var x in PARAMS) {
        paramsConsulta += DASHBOARD_ENDPOINTS.CONCAT +
          x + DASHBOARD_ENDPOINTS.EQUAL + PARAMS[x];
      }

      console.log("$...[DashboardDonantesService.js] \n>> paramsConsulta : ", paramsConsulta);

      console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyOffersByLocation()"+
          "\n>> URL : " +
          BASE_URL +
          DASHBOARD_ENDPOINTS.GET +
          globales._user +
          DASHBOARD_ENDPOINTS.GET_PUNTOS +
          DASHBOARD_ENDPOINTS.EATC_TABLE_TYPE +
          table +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDNAME +
          fieldname +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDVALUE +
          lat.toString() +
          DASHBOARD_ENDPOINTS.COMA +
          lng.toString() +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_SHOWFIELD +
          showfield +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_KM +
          km +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERFIELD +
          filterfield +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERVALUE +
          filtervalue +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERFIELD2 +
          filterfield2 +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERVALUE2 +
          filtervalue2 +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERFIELD3 +
          filterfield3 +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERVALUE3 +
          filtervalue3,
        globales.consoleStyle);

      API.get(
        DASHBOARD_ENDPOINTS.GET +
          globales._user +
          DASHBOARD_ENDPOINTS.GET_PUNTOS +
          DASHBOARD_ENDPOINTS.EATC_TABLE_TYPE +
          table +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDNAME +
          fieldname +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDVALUE +
          lat.toString() +
          DASHBOARD_ENDPOINTS.COMA +
          lng.toString() +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_SHOWFIELD +
          showfield +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_KM +
          km +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERFIELD +
          filterfield +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERVALUE +
          filtervalue+
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERFIELD2 +
          filterfield2 +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERVALUE2 +
          filtervalue2 +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERFIELD3 +
          filterfield3 +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.FILTERVALUE3 +
          filtervalue3
      ).then((res) => {
          console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyOffersByLocation() DONE",
          "\n>> res : ", res,
          "\n>> res.data : ", res.data,
          "\n>> res.data.res : ", res.data.res,
          "\n>> res.status : ", res.status,
          "\n>> res.statusText : ", res.statusText);

          return res.data.res;

      }).then((data) => {
          console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyOffersByLocation() SUCCESS",
            globales.consoleStyleSuccess);
          console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyOffersByLocation() SUCCESS",
            "\n>> data : ", data,
            "\n>> data[0] : ", data[0]);

          resolve(data);

      }).catch((err) => {
          // Error 😨😨😨😨😨😨
          console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyOffersByLocation() ERROR",
            globales.consoleStyleError);
          console.error("$...[DashboardDonantesService.js] \n>> ERROR : ", err);
          console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE : ", err.response);
          // Error 😨
          if (err.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.DATA : ", err.response.data);
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.STATUS : ", err.response.status);
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.HEADERS : ", err.response.headers);
          } else if (err.request) {
            /*
             * The request was made but no response was received, `err.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.REQUEST : ", err.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.MESSAGE : ", err.message);
          }

          console.error("$...[DashboardDonantesService.js] \n>> ERROR.CONFIG : ", err.config);

          reject(err);

      });
    }),
  getNearbyMessages: (table, fieldname, lat, lng, showfield, km) =>
    new Promise((resolve, reject) => {
      console.log("%c$...[DashboardDonantesService.js] Entrando a DashboardService.getNearbyMessages()",
        globales.consoleStyle);
      console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyMessages(",
        "\n>> queryType :", table, 
        "\n>> fieldname :", fieldname, 
        "\n>> lat : ", lat, 
        "\n>> lng : ", lng, 
        "\n>> showfield : ", showfield, 
        "\n>> km : ", km, ")");

      console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyMessages()"+
          "\n>> URL : " +
          globales._benf +
          DASHBOARD_ENDPOINTS.GET +
          globales._user +
          DASHBOARD_ENDPOINTS.GET_PUNTOS +
          DASHBOARD_ENDPOINTS.EATC_TABLE_TYPE +
          table +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDNAME +
          fieldname +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDVALUE +
          lat.toString() +
          DASHBOARD_ENDPOINTS.COMA +
          lng.toString() +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_SHOWFIELD +
          showfield +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_KM +
          km,
        globales.consoleStyle);

      API_BENF.get(
        DASHBOARD_ENDPOINTS.GET +
          globales._user +
          DASHBOARD_ENDPOINTS.GET_PUNTOS +
          DASHBOARD_ENDPOINTS.EATC_TABLE_TYPE +
          table +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDNAME +
          fieldname +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_FIELDVALUE +
          lat.toString() +
          DASHBOARD_ENDPOINTS.COMA +
          lng.toString() +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_SHOWFIELD +
          showfield +
          DASHBOARD_ENDPOINTS.CONCAT +
          DASHBOARD_ENDPOINTS.EATC_KM +
          km
      ).then((res) => {
          console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyMessages() DONE ",
          "res : ", res,
          "res.data : ", res.data,
          "res.data.res : ", res.data.res,
          "res.status : ", res.status,
          "res.statusText : ", res.statusText);

          return res.data.res;

      }).then((data) => {
          console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyMessages() SUCCESS",
            globales.consoleStyleSuccess);
          console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyMessages() SUCCESS",
          "data : ", data,
          "data[0] : ", data[0]);

          resolve(data);

      }).catch((err) => {
          // Error 😨😨😨😨😨😨
          console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyMessages() ERROR",
            globales.consoleStyleError);
          console.error("$...[DashboardDonantesService.js] \n>> ERROR : ", err);
          console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE : ", err.response);
          // Error 😨
          if (err.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.DATA : ", err.response.data);
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.STATUS : ", err.response.status);
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.HEADERS : ", err.response.headers);
          } else if (err.request) {
            /*
             * The request was made but no response was received, `err.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.REQUEST : ", err.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.MESSAGE : ", err.message);
          }

          console.error("$...[DashboardDonantesService.js] \n>> ERROR.CONFIG : ", err.config);

          reject(err);

      });
    }),
  getNearbyMessagesError: (table, id) =>
    new Promise((resolve, reject) => {
      console.log("%c$...[DashboardDonantesService.js] Entrando a DashboardService.getNearbyMessagesError()",
        globales.consoleStyle);
      console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyMessagesError(",
        "\n>> queryType :", table, 
        "\n>> id :", id, ")");
      console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyMessagesError()"+
          "\n>> URL : " +
          globales._benf +
          DASHBOARD_ENDPOINTS.API_EATCLOUD + 
          table + 
          DASHBOARD_ENDPOINTS.QUESTION + 
          "_id"+
          DASHBOARD_ENDPOINTS.EQUAL +
          id, globales.consoleStyle);
      API_BENF.get(
          DASHBOARD_ENDPOINTS.API_EATCLOUD + 
          table + 
          DASHBOARD_ENDPOINTS.QUESTION + 
          "_id"+
          DASHBOARD_ENDPOINTS.EQUAL +
          id).then((res) => {
          console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyMessagesError() DONE ",
          "\n>> res : ", res,
          "\n>> res.data : ", res.data,
          "\n>> res.data.res : ", res.data.res,
          "\n>> res.status : ", res.status,
          "\n>> res.statusText : ", res.statusText);

          return res.data.res;

      }).then((data) => {
          console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyMessagesError() SUCCESS",
            globales.consoleStyleSuccess);
          console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyMessagesError() SUCCESS",
          "\n>> data : ", data,
          "\n>> data[0] : ", data[0]);

          resolve(data);

      }).catch((err) => {
          // Error 😨😨😨😨😨😨
          console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyMessagesError() ERROR",
            globales.consoleStyleError);
          console.error("$...[DashboardDonantesService.js] \n>> ERROR : ", err);
          console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE : ", err.response);
          // Error 😨
          if (err.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.DATA : ", err.response.data);
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.STATUS : ", err.response.status);
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.HEADERS : ", err.response.headers);
          } else if (err.request) {
            /*
             * The request was made but no response was received, `err.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.REQUEST : ", err.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.MESSAGE : ", err.message);
          }

          console.error("$...[DashboardDonantesService.js] \n>> ERROR.CONFIG : ", err.config);

          reject(err);

      });
    }),
  getNearbyOffersByCodePoint:(table, id, states) =>
    new Promise((resolve, reject) => {
      console.log("%c$...[DashboardDonantesService.js] Entrando a DashboardService.getNearbyOffersByCodePoint()",
        globales.consoleStyle);
      console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyOffersByCodePoint(",
        "\n>> queryType :", table,
        "\n>> states :", states,
        "\n>> id :", id, ")");
      console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyOffersByCodePoint()"+
          "\n>> URL : " +
          globales._dona +
          DASHBOARD_ENDPOINTS.API_EATCLOUD + 
          table + 
          DASHBOARD_ENDPOINTS.QUESTION + 
          "eatc-pod_id"+
          DASHBOARD_ENDPOINTS.EQUAL +
          id +
          DASHBOARD_ENDPOINTS.CONCAT +
          "eatc-odd_state"+
          DASHBOARD_ENDPOINTS.EQUAL +
          states, globales.consoleStyle); //FALTARIA EL COMPRESS "&_commpress"

    API.get(DASHBOARD_ENDPOINTS.API_EATCLOUD + 
          table + 
          DASHBOARD_ENDPOINTS.QUESTION + 
          "eatc-pod_id"+
          DASHBOARD_ENDPOINTS.EQUAL +
          id +
          DASHBOARD_ENDPOINTS.CONCAT +
          "eatc-odd_state"+
          DASHBOARD_ENDPOINTS.EQUAL +
          states).then((res) => {
            console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyOffersByCodePoint() DONE ",
            "\n>> res : ", res,
            "\n>> res.data : ", res.data,
            "\n>> res.data.res : ", res.data.res,
            "\n>> res.status : ", res.status,
            "\n>> res.statusText : ", res.statusText);

            return res.data.res;

          }).then((data) => {
            console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyOffersByCodePoint() SUCCESS",
              globales.consoleStyleSuccess);
            console.log("$...[DashboardDonantesService.js] DashboardService.getNearbyOffersByCodePoint() SUCCESS",
            "\n>> data : ", data,
            "\n>> data[0] : ", data[0]);

            resolve(data);

          }).catch((err) => {
            // Error 😨😨😨😨😨😨
            console.log("%c$...[DashboardDonantesService.js] DashboardService.getNearbyOffersByCodePoint() ERROR",
              globales.consoleStyleError);
            console.error("$...[DashboardDonantesService.js] \n>> ERROR : ", err);
            console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE : ", err.response);
            // Error 😨
            if (err.response) {
              /*
              * The request was made and the server responded with a
              * status code that falls out of the range of 2xx
              */
              console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.DATA : ", err.response.data);
              console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.STATUS : ", err.response.status);
              console.error("$...[DashboardDonantesService.js] \n>> ERROR.RESPONSE.HEADERS : ", err.response.headers);
            } else if (err.request) {
              /*
              * The request was made but no response was received, `err.request`
              * is an instance of XMLHttpRequest in the browser and an instance
              * of http.ClientRequest in Node.js
              */
              console.error("$...[DashboardDonantesService.js] \n>> ERROR.REQUEST : ", err.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.error("$...[DashboardDonantesService.js] \n>> ERROR.MESSAGE : ", err.message);
            }

            console.error("$...[DashboardDonantesService.js] \n>> ERROR.CONFIG : ", err.config);

            reject(err);

         });

    }),
};

export default DashboardDonantesService;
