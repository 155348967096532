import React from "react";
import { useParams, withRouter } from "react-router-dom";
// hooks de react-redux
import { useDispatch, useSelector } from 'react-redux';
import { fn_traeCarroProds, fn_traeDatosProd, fn_traeDatosOrden, fn_traeDatoStock, fn_cambiaVerBtnAgr, fn_cambiaOddPrdVal, fn_insertRegOrden, fn_updateRegOrden, fn_deleteRegOrden } from './detprodDuck';
import { getTimerCartOut, stopTimerCartOut } from '../../services/TimerCartOut';
// Materialize e imagen x defecto
// import M from "materialize-css";
import imgDef from "../../img/icn_amabilidad.png";
// import M from 'materialize-css';

const Detalleprod = (props) => {
    // Recolectando el id del producto e iniciando los states y el dispatch desde el store redux 
    const { idprod } = useParams();
    const dispatch = useDispatch();
    const accion = useSelector(store => store.detprod_state.accion);
    const verbtnagr = useSelector(store => store.detprod_state.verbtnagr);
    const id_order = useSelector(store => store.detprod_state.id_order);
    const cantadded = useSelector(store => store.detprod_state.cantadded);
    const cant = useSelector(store => store.detprod_state.cant);
    const stock = useSelector(store => store.detprod_state.stock);
    const prod = useSelector(store => store.detprod_state.prod);
    const carro_pod_id = useSelector(store => store.detprod_state.carro_pod_id);

    console.log({ carro_pod_id });
    localStorage.setItem("carro_pod_id", carro_pod_id);

    // llamando el useEffect
    React.useEffect(() => {        
        const fetchInitStates = async() => {
            try {                
                await dispatch(fn_traeDatosProd(idprod));
                await dispatch(fn_traeCarroProds());
                await dispatch(fn_traeDatosOrden(idprod));
                await dispatch(fn_traeDatoStock(idprod)); 
                // stopTimerCartOut(getTimerCartOut());               
            } catch (error) {
                console.error("Error en fetchIniStates: ", error);
            }            
        } 
        fetchInitStates();        
    }, [dispatch, idprod]);

    // funcion para agregar al carro por primera vez
    const fn_nuevoAgrCant = async() => {
        console.log("Agregando al carrito x primera vez");
        try {
            await dispatch(fn_cambiaVerBtnAgr());
            await dispatch(fn_traeDatoStock(idprod));
            await dispatch(fn_cambiaOddPrdVal(idprod, 'nuevo'));
            await dispatch(fn_traeDatoStock(idprod));
        } catch (error) {
            console.error("Error en fn_nuevoAgrCant: ", error);
        }        
    };

    // funcion para realizar el cambio de cantidad
    const fn_cambiaCantidad = async(tipo) => {
        console.log("Haciendo click en cambiar cantidad");
        try {
            await dispatch(fn_traeDatoStock(idprod));
            await dispatch(fn_cambiaOddPrdVal(idprod, tipo));
            await dispatch(fn_traeDatoStock(idprod));
        } catch (error) {
            console.error("Error intentando cambiar la cantidad");
        }
    };

    // funcion que se ejecuta cuando se selecciona el boton de X (salir del detalle del producto al dashboard de usuario)
    const fn_salirDetProd = async(destino) => {
        console.log("Saliendo del detalle de producto");
        try {
            // primero se pregunta si ya existia un registro de orden para este producto y si se puso cantidad en ceros para elimiinarlo de la orden.
            if (id_order !== null && cant === 0) {
                await dispatch(fn_deleteRegOrden());
            }
            // si no es el caso anterior entonces ahora hay que preguntar si la cantidad a agregar es diferente a la cantidad agregada a la orden.
            else if (cantadded !== cant) {
                (accion === 'insert') ? ((id_order === null && cant > 0) && await dispatch(fn_insertRegOrden(idprod))) : await dispatch(fn_updateRegOrden());   
            }    
            // se actualiza el actual valor del carro de compras
            await dispatch(fn_traeCarroProds());        
            // ahora preguntamos si se pasa al carro de compras o si se debe ir al dashboard del POV
            if (destino === "/carrocompras") {
                console.log(">>>> carrocompras");
                props.history.push(destino);             // yendo a la pantalla del carro de compras
            } else {            
                console.log(">>>> supuestamente al dashboard POV, carro_pod_id: " + carro_pod_id);
                fn_backFromDetprod();
            }            
        } catch (error) {
            console.error("Error saliendo del detalle de producto");
        }
    };
    
    // funcion que administra la salida del detalle del producto dependiendo si hay carro de compras activo o no
    const fn_backFromDetprod = () => {                       
        console.log(">>>> carro_pod_id: " + localStorage.getItem("carro_pod_id"));
        console.log(">>>> prod[eatc-pod_id]: " + prod["eatc-pod_id"]);
        if (localStorage.getItem("carro_pod_id") === prod["eatc-pod_id"]) {
            // si hay carro de compras ya activo me debo salir al dashboard de punto de venta
            props.history.push({ 
                pathname: "/dashboardsale",   
                state: { 
                    km_actual: localStorage.getItem("pod_km_actual"), 
                    point: prod 
                } 
            });
        } else {
            // si no hay carro activo me devuelvo a la pagina anterior.
            props.history.goBack();
        }              
    };

    // evaluando si hay alergenos o no
    const fn_escribe_alergenos = (vlr_alerg) => {
        let txt_alerg = "";
        switch (vlr_alerg) {
            case null:
            case "":
            case "0":
            case 0:
                txt_alerg = "No tiene";
                break;
            case "1":
            case 1:
                txt_alerg = "Alergeno no especificado";
                break;
            default:
                txt_alerg = vlr_alerg;
        }
        return txt_alerg;
    };

    /* componente para administrar la cantidad a agregar en la orden */
	const CmpCantAgrOrd = () => {
		console.log(">>>>> Renderizando el campo de cantidades");
		// luego renderizamos el campo de entrada de cantidad
		return (
            <>
            { (cant !== null) && (
                <div className="row">
                    <div className="col s12 m6 center">
                        <div className="row center">
                            <div className="col s3">
                                {
                                    cant > 0 ? (
                                        <i className="medium material-icons txt-azul" onClick={() => fn_cambiaCantidad('resta')}>remove_circle</i>
                                    ) : ('')
                                }
                            </div>
                            <div className="col s1">&nbsp;</div>
                            <div className="col s4"><input type="number" className="center" value={cant} readOnly="readOnly" /></div>
                            <div className="col s1">&nbsp;</div>
                            <div className="col s3">
                                {
                                    stock > 0 ? (
                                        <i className="medium material-icons txt-azul" onClick={() => fn_cambiaCantidad('suma')}>add_circle</i>
                                    ) : ('')
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m6 center">
                        <button className="btn btn-sm center" onClick={() => fn_salirDetProd('/carrocompras')}>Ver pedido</button>
                        {	/* <Link to="/listaprods" className="btn btn-sm">Volver</Link> */}
                    </div>
                </div>
            )}
			</>
		)
	};    

    // Renderizando el componente principal
    if ((carro_pod_id !== null) && (carro_pod_id !== prod["eatc-pod_id"])) {
        console.log(">>>> carro_pod_id: " + carro_pod_id + " en producto no es del carro de compras");
        // si el valor del pod_id en el carro de compras no es nulo y ademas es diferente al pod_id del producto seleccoionado, se debe devolver a la pagina anterior
        console.log(">>> El producto no hace parte del punto de venta asociado al actual carro de compras");
        return (
            <div className="container">
                <br />
                <h3 float="right">El producto no hace parte del punto de venta asociado al actual carro de compras</h3>
                <br /><br />
                <div className="center-align">
                    <button className="btn btn-sm center" onClick={() => props.history.goBack()}>Regresar</button>
                </div>                
            </div>
        );        
    } else {
        console.log(">>>> carro_pod_id: " + carro_pod_id + " en producto si es del carro de compras o no hay carro de compras");
        return ( 
            <div className="container">
            { prod && (
                <>
                <div className="row">
                    <div className="col s12 m6 l6 center-align">
                        <br />
                        <i className="material-icons left" onClick={() => fn_salirDetProd('')}>close</i>
                        <i className="material-icons right" onClick={() => fn_salirDetProd('/carrocompras')}>shopping_cart</i>
                        <img src={prod["eatc-odd_image"] === "" ? imgDef : prod["eatc-odd_image"]} alt="imagen" width="100%" />
                    </div>
                    <div className="col s12 m6 l6">
                        <h4 className="txt-azul">
                            {prod["eatc-odd_name"]}
                        </h4>
                        <span>
                            <b>
                                Alergenos:&nbsp;&nbsp;{fn_escribe_alergenos(prod["eatc-contains_alergens"])}
                            </b>
                        </span>
                        <br />
                        <span>Horario de atencion: 10:00am - 4:30pm</span>
                        <br />
                        <span>Dirección: {prod["eatc-pod_address"]}</span>
                        <br /><br />
                        <div className="right-align">
                            <span><del>${prod["eatc-odd_unit_price"]}</del></span>
                            <br />
                            <span className="flow-text txt-fucsia">${prod["eatc-odd_min_sale_unit_price"]}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="divider"></div>
                    <div className="section">
                        <h5>Descripción del producto:</h5>
                        <p>{prod["eatc-odd_description"]}</p>
                    </div>
                </div>
                { console.log("stock: " + stock + " >> cant: " + cant + " >> verbtnagr: " + verbtnagr) }
                <footer className="footer-fixed">
                    <div className="center">
                    {
                        (cant > 0) ? 
                            <CmpCantAgrOrd /> : (
                            (stock === 0) ? 
                                <button className="btn disabled">AGOTADO</button> :
                                <button className="btn btn-sm center" onClick={() => fn_nuevoAgrCant()}>Agregar al carrito</button>
                            )                        
                    }                                         
                    </div>
                </footer>
            </>
            ) }                
        </div>
        );
    }    
};

export default withRouter(Detalleprod);

