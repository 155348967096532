import React, {useState} from "react";
import { auth, signInWithGoogle, signInWithFacebook, signInWithTwitter, generateUserDocument } from "../../config/firebase";
import { useDispatch } from 'react-redux';
import { fn_storeUser } from './loginDuck';
import M from 'materialize-css';
import { Link } from "react-router-dom";
import { divStyleZ, divStyleMBn24, divStyleMTp19MBnN10, divStyleMTp16, divStyleTitle } from '../../config/config';

const SignIn = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  React.useEffect(() => {
      let {user} = JSON.parse(localStorage.getItem('USER')) || {};
      if(user && user.uid){
          console.log(user);
          props.history.push('/checkout');
      }
      M.updateTextFields();
  }, [dispatch]);

  const signInWithEmailAndPasswordHandler = async (event, email, password) => {
      event.preventDefault();
      auth.signInWithEmailAndPassword(email, password).then(result => {
        generateUserDocument(result.user, {})
      }).catch(error => {
          setError("No fue posible ingresar con los datos ingresados, por favor revísalos!");
          console.error("Error signing in with password and email", error);
          return;
      });
      if(localStorage.getItem('USER') && localStorage.getItem('USER')!='null'){
        await dispatch(fn_storeUser());
        props.history.push('/checkout');
      }
  };

  const onChangeHandler = (event) => {
      const {name, value} = event.currentTarget;

      if(name === 'userEmail') {
          setEmail(value);
      }
      else if(name === 'userPassword'){
          setPassword(value);
      }
  };

  const goToSignUp = async() => {
      try {
          props.history.push('/signup');
      } catch (error) {
          console.error("Error redireccionando a signup");
      }
  };

  const openTerms = async() => {
      try {
          props.history.push('/terms');
      } catch (error) {
          console.error("Error redireccionando a terminos y condiciones");
      }
  };

  const goToPasswordReset = async() => {
      try {
          props.history.push('/passwordreset');
      } catch (error) {
          console.error("Error redireccionando a reset-password");
      }
  };

  const googleSignIn = async () => {
    await signInWithGoogle().then(result => {
        generateUserDocument(result.user, {})
    });
    if(localStorage.getItem('USER') && localStorage.getItem('USER')!='null'){
      await dispatch(fn_storeUser());
      props.history.push('/checkout');
    }
  };

  const facebookSignIn = async () => {
    await signInWithFacebook().then(result => {
        generateUserDocument(result.user, {})
    });
    if(localStorage.getItem('USER') && localStorage.getItem('USER')!='null'){
      await dispatch(fn_storeUser());
      props.history.push('/checkout');
    }
  };

  const twitterSignIn = async () => {
    await signInWithTwitter().then(result => {
        generateUserDocument(result.user, {})
    });
    if(localStorage.getItem('USER') && localStorage.getItem('USER')!='null'){
      await dispatch(fn_storeUser());
      props.history.push('/checkout');
    }
  };

  const goToCart = async() => {
    try {
        props.history.push('/carrocompras');
    } catch (error) {
        console.error("Error redireccionando al carrito");
    }
  };

  return (
    <div className="wrapper signin-wrapper">
      <div className="blanco-bg shadow-8dp" style={divStyleZ}>
          <div className="row" style={divStyleMBn24}>
              <div className="col s1 m1 l1">
                  <div style={divStyleMTp16}>
                      <Link
                          to="#"
                          className=""
                          onClick={() => {
                            goToCart();
                          }}
                      >
                          <i className="material-icons">close</i>
                      </Link>
                  </div>
              </div>
              <div className="col s11 m11 l11">
                  {/* style={divStyleMTn19} */}
                  <div className="input-field" style={divStyleMTp19MBnN10}>
                      <h4 className="brand-logo blue-text text-darken-2" style={divStyleTitle}>Bienvenido a EatCloud</h4>
                  </div>
              </div>
          </div>
      </div>
      <div className="container esp-top-grande">
        <p>Por favor ingresa tus datos para acceder a tu cuenta de usuario.</p>
        <div className="inner esp-top-base">
          {error !== null && <div className = "txt-error">{error}</div>}
          <form className="signin-form">
            <div className="input-field">
              <input
                type="email"
                name="userEmail"
                value = {email}
                placeholder="usuario@correo.com"
                id="userEmail"
                onChange = {(event) => onChangeHandler(event)}
              />
              <label htmlFor="userEmail">E-mail</label>
            </div>
            <div className="input-field">
              <input
                type="password"
                name="userPassword"
                value = {password}
                placeholder="********"
                id="userPassword"
                onChange = {(event) => onChangeHandler(event)}
              />
              <label htmlFor="userPassword">Contraseña</label>
            </div>
            <button className="btn primario-btn shadow-2dp waves-effect waves-light width-100 esp-top-base" onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
              Ingresa ahora
            </button>
          </form>
          <div className="center esp-top-base">ó ingresa con:</div>
          <div className="social-login center esp-top-pequeno">
            <button onClick={event => {googleSignIn(event);}} className="btn secundario-btn shadow-2dp waves-effect waves-light">Google</button>
            <button onClick={event => {facebookSignIn(event);}} className="btn secundario-btn shadow-2dp waves-effect waves-light esp-hor-miniatura">Facebook</button>
            <button onClick={event => {twitterSignIn(event);}} className="btn secundario-btn shadow-2dp waves-effect waves-light">Twitter</button>
          </div>
          <div className="center">
            <button className="btn link-btn width-100 esp-top-base" onClick={() => goToPasswordReset()}>¿Olvidaste tu contraseña?</button>
            <button className="btn secundario-btn shadow-2dp waves-effect waves-light width-100 esp-top-base" onClick={() => goToSignUp()}>Registrate</button>
            <button className="btn link-btn width-100 esp-top-grande" onClick={() => openTerms()}>Conoce los términos y condiciones</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignIn;