import React from "react";
import { Link, withRouter } from "react-router-dom";
import imgDef from "../../img/icn_amabilidad.png";
import axios from 'axios';
import glb from "../../config/config";
import { fn_formato_numero } from '../../misc/sc_misc';
import { setTimerCartOut, startCountCartOut } from '../../services/TimerCartOut';

const Listaprods = (props) => {
	localStorage.removeItem("datoprod");
	// hook para guardar el listado de los productos
	const [prods, set_Prods] = React.useState([]);	
	// hook para traer el listado de productos
	React.useEffect(() => {
		fn_get_products();
		// setTimerCartOut(startTimerCartOut());
		setTimerCartOut(startCountCartOut(10));
	}, []);
	// funcion para traer los datos
	const fn_get_products = async () => {
		const productos = await axios.get(glb._dona + glb._api + glb._user + "eatc_sale?eatc-code=_*");
		console.log("Total productos: ", productos.data.cont);
		set_Prods(productos.data.res);
	};	
	// renderizando
	return (
		<div className="container">
			<div className="row">
                <div className="col s12 center-align">
					<br />
					<Link to={{ pathname: `/carrocompras` }}>
						<i className="material-icons left">shopping_cart</i><br />
					</Link>						
				</div>
			</div>			
			{prods.length === 0 ? "cargando..." : ""}
			{ prods.map((prd) => (
				<Link to={{ pathname: `/detalleprod/${prd["eatc-code"]}`, state: prd }} key={prd["_id"]}>
					<div className="row esp-top-base">
						<div className="col s12">
							<div className="card horizontal">
								<div className="card-image">
									<img src={ prd["eatc-odd_image"] === "" ? imgDef : prd["eatc-odd_image"] } alt="img" width="95%" />
								</div>
								<div className="card-stacked">
									<div className="card-content">
										<div className="circulo-dcto shadow-9dp">- 10%</div>
										<span className="card-title-med">{prd["eatc-odd_name"]}</span>
										<span className="txt-meta">Dirección del restaurante</span>
										<span className="txt-meta txt-accent"><del>${fn_formato_numero(prd["eatc-odd_unit_price"], 0, '.')}</del></span>
										<span className="txt-meta txt-primary">${fn_formato_numero(prd["eatc-odd_min_sale_unit_price"], 0, '.')}</span>
									</div>															
								</div>																						
							</div>
						</div>
					</div>
				</Link>
			))}		
		</div>
	);
};

export default withRouter(Listaprods);
